/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect, useMemo } from 'react';

// @mui material components

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import _ from "lodash"

// Material Dashboard 2 React examples

// Billing page components
import { useTranslation } from "react-i18next";
import { useMaterialUIController } from "context";

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Chip, TextField } from '@mui/material';

import ConstructionIcon from '@mui/icons-material/Construction';
import PsychologyIcon from '@mui/icons-material/Psychology';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import CircleIcon from '@mui/icons-material/Circle';
import { MessageLeft, MessageRight } from 'wellisco/components/ChatUI/Message';
import { getToolComp, toolComps, getInsightComp } from './chat_comp_factory';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ChatModel } from 'flows/FlowUtils';

const msgIcons = {
  "on action": ConstructionIcon,
  "final_result": TipsAndUpdatesIcon,
}

// const chatUrl = "ws://127.0.0.1:8000/fin-api/echo"
const chatUrl = "ws://127.0.0.1:8000/fin-api/agent_stream"
// const defaultChatMessage = "Is the momentum of the energy sector higher than the consum sector over the last 30 days?"
// const defaultChatMessage = "What is the current price of Google stock?"
const defaultChatMessage = ""

export default function FinChat(props) {
  const { t } = useTranslation()
  const { model } = props
  const [controller, dispatch] = useMaterialUIController();
  const [websocket, setWebSocket] = useState()
  const [message, setMessage] = useState(defaultChatMessage)
  // const [chatLog, setChatLog] = useState(props.chatLog || [])
  const [showAll, setShowAll] = useState(false)
  const [showRaw, setShowRaw] = useState(false)
  const [showOnAction, setShowOnAction] = useState(false)
  const [showTools, setShoeTools] = useState(props.showTools == true)

  const next = model.getNext()

  const handleNext = item => {
    if (item.topic) {
      model.push({ type: "user", text: item.question })
      model.setTopic(item.topic)
      return
    }
    if (item.question) {
      // setMessage(item.question)
      wsSend(item.question)
    }
  }

  // useEffect(() => {
  //   setChatLog(props.chatLog || [])
  // }, [props])
  const addMessage = msg => {
    // const log = [...chatLog,m]
    const jMsg = _.isObject(msg) ? msg : JSON.parse(msg)
    // setChatLog(prevLog => [...prevLog, jMsg])
    model.push(jMsg)
    console.log(jMsg);

  }
  const wsConnect = () => {

    const websocket = new WebSocket(chatUrl);
    websocket.onopen = (event) => {
      setWebSocket(websocket)
    }
    websocket.onerror = (event) => {
      setWebSocket(null)
    }
    websocket.onclose = (event) => {
      setWebSocket(null)
    }
    websocket.onmessage = (event) => {
      addMessage(event.data)
      // const log = [...chatLog,event.data]
      // setChatLog(log)
      console.log(event.data);
    };
  }
  const wsSend = (question) => {
    // if (!messageRef?.current) return
    // const txt = messageRef.current.value
    // messageRef.current.value = ""
    question ||= message
    if (_.isEmpty(question))
      return
    console.log(question)
    addMessage({ type: "user", text: question })
    websocket.send(question);
    // setMessage("")
  }

  useEffect(() => {
    wsConnect()
  }, [chatUrl])

  const msgIcons = {
    "agent-message": QuestionAnswerIcon,
    "on action": PsychologyIcon,
    "final_result": TipsAndUpdatesIcon,
    "default": CircleIcon,
  }

  const msgComponents = {
    "user": ({ msg }) => <div >
      {msg.text}
    </div>,
    "agent": ({ msg }) => <div >
      {msg.text}
    </div>,
    "agent-sale": ({ msg }) => <div style={{ color: "#ae6e14", textAlign: "start" }}>
      {/* {JSON.stringify(msg)} */}
      {msg.callToAction?.message || msg.text}
      <MDButton variant="text" color="error">
        {msg.callToAction?.button || "Learn More"}`
      </MDButton>
      <MDBox sx={{ fontSize: "smaller" }}>
        {msg.callToAction?.disclaimer}
      </MDBox>
    </div>,
    "agent-title": ({ msg }) => <div >
      <h4>{msg.title}</h4>
      {msg.text}
    </div>,
    "agent-select": ({ msg }) => <div >
      {msg.prompt}
      <MDBox ml={3}>
        <ul>
          {_.map(msg.items, item => <li>{item.title || item.short_title}</li>)}
        </ul>
      </MDBox>
    </div>,
    // "fin-tool": ({ msg }) => getToolComp(msg["tool-type"], msg),
    "insight": ({ msg }) => getInsightComp(msg["insightType"], msg),
    "agent-message": ({ msg }) => <div>
      {msg.result}
    </div>,
    "on action": ({ msg }) => <div>s
      {msg.log}
    </div>,
    // "final_result": ({ msg }) => <MDBox sx={{ display: "flex", alignItems: "center" }}>
    //   {msg.result}
    // </MDBox>,
    "_get_sector_stock_symbol": ({ msg }) => <MDBox sx={{ display: "flex", alignItems: "center" }}>
      <img src={`images/sectors/${_.lowerCase(msg.sector)}.png`} width={40} style={{ marginRight: 10 }} />
      <div>Sector: {msg.sector} - Symbol: {msg.symbol}</div>
    </MDBox>,
    "get_rate_of_change": ({ msg }) => <div>
      {/* {JSON.stringify(msg)} */}
    </div>,
    "planning": ({ msg }) => <div>
      <div>Planning...</div>
      {_.map(msg.json, item => <li>{item.tool_name}</li>)}
    </div>,
    "default": ({ msg }) => <div>
      {/* {JSON.stringify(msg)} */}
      {JSON.stringify(msg.type)}
    </div>,
  }

  if (!showOnAction)
    delete msgComponents["on action"]

  const messages = model.getMessages?.()
  const chatLog = [...messages]
  if (_.last(chatLog)?.type == "ll_streaming_json") {
    const planning = _.clone(_.last(chatLog))
    planning.type = "planning"
    chatLog.push(planning)
  }
  const chatMessages = _.compact(_.map(chatLog, msg => {
    if (msg.comp)
      return msg.comp

    const IconComp = msgIcons[msg.type]
    var DataComp = msgComponents[msg.type]
    if (msg.type == "fin-tool")
      DataComp = toolComps[msg["tool-type"]]

    if (DataComp == undefined && !showAll)
      return

    const comp = {
      icon: IconComp,
      side: msg.type == "user" ? "right" : "left",
      comp: <div>
        {(DataComp && !showRaw) ? <DataComp msg={msg} /> : <div>{JSON.stringify(msg)}</div>}
      </div>
    }

    msg.comp = comp
    return comp
  }))

  const chatMessagesComp = useMemo(() => <ChatMessages messages={chatMessages} />, [messages])

  return (
    <MDBox id="fin-chat-root" m={0} sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}

    >
      <MDBox id="fin-chat-messages" position="relative" flexGrow={1} flexShrink={1}>
        {/* <ChatMessages messages={chatMessages} /> */}
        {chatMessagesComp}
      </MDBox>
      {showTools && <MDBox m={1} sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        Chat Tools
        <div>
          <Chip label="All" style={{ marginRight: 20 }} onClick={e => setShowAll(!showAll)} color={showAll ? "success" : "secondary"} />
          <Chip label="Raw" style={{ marginRight: 20 }} onClick={e => setShowRaw(!showRaw)} color={showRaw ? "success" : "secondary"} />
          <Chip label="Logic" style={{ marginRight: 20 }} onClick={e => setShowOnAction(!showOnAction)} color={showOnAction ? "success" : "secondary"} />
          <Chip label="Clear" style={{ marginRight: 20 }} onClick={e => model.resetChat()} color={"secondary"} />
          {websocket ?
            <Chip icon={<CheckCircleIcon fontSize="small" />} color="success" label="Connected" />
            :
            <Chip icon={<CancelIcon fontSize="small" />} color="error" label="Not Connected"
              onClick={e => wsConnect()}
            />
          }
        </div>
      </MDBox>}
      <MDBox m={1} sx={{ display: "flex", flexDirection: "row", justifyContent: "start" }}>
        {_.map(next, item => <MDButton
          variant="contained"
          sx={{ textTransform: "none", border: "1px solid #DDD", marginRight: 1 }}
          onClick={() => handleNext(item)}
        >
          {item.button}
        </MDButton>)}
      </MDBox>
      <MDBox m={1} sx={{ display: "flex", flexDirection: "row", justifyContent: "strech" }}>
        <TextField id="standard-basic" placeholder='Type your message' variant="outlined"
          value={message}
          onChange={e => setMessage(e.target.value)}
          onKeyDown={e => { console.log("code", e.code); if (e.code == "Enter") wsSend() }}
          sx={{
            flexGrow: 1, marginRight: 0,
            "& input": { fontSize: 18 }
          }}
          size="medium"
        />
        <MDBox sx={{ display: "flex", alignItems: "center", marginRight: 0 }}>
          <MoreVertIcon
            onClick={e => setShoeTools(!showTools)}
          />
        </MDBox>
        <MDButton variant="gradient" color="dark"
          onClick={wsSend}
        >Send</MDButton>
      </MDBox>
      {/* <MDButton variant="gradient" color="dark"
                  onClick={wsConnect}
                >Click to Connect</MDButton> */}
    </MDBox>
  );
}

function ChatMessages(props) {
  const { messages } = props
  console.log("Loading video Messages")
  return <div style={{
    // width: "calc( 100% - 20px )",
    // margin: 10,
    overflowY: "scroll",
    // height: "calc( 100% - 80px )",
    height: "100%",
    position: "absolute",
    top: 0, botton: 0, left: 0, right: 0,
    overflow: "auto"
  }}>
    {_.map(messages, (msg, i) =>
      <div key={i}>
        {msg.side == "left" ?
          <MessageLeft
            // message="あめんぼあかいなあいうえお"
            // timestamp="MM/DD 00:00"
            photoURL="https://lh3.googleusercontent.com/a-/AOh14Gi4vkKYlfrbJ0QLJTg_DLjcYyyK7fYoWRpz2r4s=s96-c"
            displayName=""
            avatarIcon={msg.icon}
            avatarDisp={_.isEmpty(msg.icon) == false}
          >{msg.comp}</MessageLeft>
          :
          <MessageRight
            message="messageRあめんぼあかいなあいうえおあめんぼあかいなあいうえおあめんぼあかいなあいうえお"
            // timestamp="MM/DD 00:00"
            photoURL="https://lh3.googleusercontent.com/a-/AOh14Gi4vkKYlfrbJ0QLJTg_DLjcYyyK7fYoWRpz2r4s=s96-c"
            displayName="まさりぶ"
            avatarDisp={false}
          >{msg.comp}</MessageRight>
        }
      </div>
    )}
  </div>
}
