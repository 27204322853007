import { choosePrompt } from "./FlowUtils"
import _ from "lodash"

export default {
    "flow_id": "events",
    "topics": [
        {
            "topic": "intro",
            "steps": [
                // { type: "agent", text: ctx => ctx.card?.intro },
                {
                    type: "agent-title",
                    title: ctx => _.first(ctx.card?.events)?.short_title,
                    text: ctx => _.first(ctx.card?.events)?.text
                },
                {
                    type: "agent-select",
                    prompt: ctx => choosePrompt(ctx.card?.select_prompt),
                    items: ctx => _.tail(ctx.card?.events,1),
                    onSelect: e => { e.ctx.push({ topic: "event", item: e.target }) },
                },
            ]
        },
        {
            "topic": "event",
            "steps": [
                { type: "say", text: ctx => ctx.card.intro },
                {
                    type: "show-select",
                    prompt: ctx => choosePrompt(ctx.card.select_prompt),
                    items: ctx => ctx.card.events,
                    onSelect: e => { },
                },
            ]
        }
    ]
}
