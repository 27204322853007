
import { useEffect, useRef, useState } from 'react';
import _ from "lodash"

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

// import "./TableSection.css"
// import SectionFrame from "./SectionFrame"
import MDBox from 'components/MDBox';
import WlDataTable from './WlDataTable'
import { useWorkbook } from '../useWorkbook';
import { Avatar, Chip, Grow, Typography } from '@mui/material';

import userImage from "assets/images/logo-ct-dark.png"
import aiImage from "assets/images/logo-ct-dark.png"
import MDButton from 'components/MDButton';
import { WlChatInput } from './WlChatInput';
import classNames from 'classnames';

import Editor from "@monaco-editor/react";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
// if you want to use the light theme
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';

export default function WlChatPanel({ section, title, workbook, isOpen, setIsOpen, onSectionSelected }) {
    // const [isOpen, setIsOpenState] = useState()
    // const { chat, startAction  } = useWorkbook()
    const { chat, roleChat, actions, startAction, selectedAction, selectAction, invitation } = workbook
    const messagesEndRef = useRef(null);

    const onTableClick = (path, row, col) => {
        setIsOpen(false)
        onSectionSelected?.(path, { row, col })
    }

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToBottom();
    }, [chat]); // This effect runs every time the 'messages' prop changes.

    function Row({ msg: info, children }) {
        if (!info) return <></>
        return info?.role == "user" ?
            <MDBox display="flex" flexDirection="row" className={`wl_chat_bubble wl_user_chat_bubble`} >
                <Avatar alt="Guy Kohen" src={userImage} sx={{ marginRight: 1 }} className="wl_chat_avatar" />
                <MDBox>{children}</MDBox>
            </MDBox>
            :
            <MDBox display="flex" flexDirection="row" className={`wl_chat_bubble`} >
                <Avatar alt="InsureTax" src={aiImage} sx={{ marginRight: 1 }} className="wl_chat_avatar" />
                <MDBox>{children}</MDBox>
            </MDBox>
    }

    function onSend(user_prompt) {
        startAction(selectedAction, user_prompt)
    }

    function onActionSelected(action) {
        setIsOpen(true)
        selectAction(action.action_id)
    }

    return (<MDBox id="action_panel" className={classNames({ "wl_action": true, "wl_action_open": isOpen })}
        p={1}
    >
        <MDBox sx={{ display: "flex" }}>
            <MDBox sx={{ flex: 1, cursor: "pointer" }} onClick={() => setIsOpen(!isOpen)}><h3>{title} AI Chat</h3></MDBox>
            {/* <MDButton onClick={() => setIsOpen(!isOpen)} size="small" color="light">{isOpen ? "Close" : "Open"}</MDButton> */}
        </MDBox>
        <MDBox className={classNames({
            "wl_action_content": true, "wl_dynamic_height": true, "wl_zero_height": !isOpen
        })}>
            {
                _.isEmpty(chat) ?
                    <MDBox sx={{ textAlign: "center", overflow: "hidden", margin: "auto", height: 0 }}>
                        Chat is Empty
                    </MDBox>
                    :
                    <MDBox p={0} sx={{ overflowY: "auto", overflowX: "hidden", flexShrink: 1 }}>
                        <MDBox >
                            {_.map(roleChat, role => <Row msg={role}>
                                {_.map(role.messages, msg =>
                                    getMsgComp(msg, onTableClick)
                                )}
                            </Row>)}
                        </MDBox>
                        <div ref={messagesEndRef} />
                    </MDBox>
            }

        </MDBox>
        <MDBox>
            <Row msg={invitation}>
                <MDBox sx={{ display: "flex", felxDirection: "row", gap: 2, }} m={1}>
                    {_.map(actions, action => {
                        return <Chip sx={{ color: "#EEE", cursor: "pointer" }}
                            key={action.action_id}
                            label={action.button_text}
                            onClick={() => onActionSelected(action)}
                            color="primary"
                            size="small"
                            // variant={action.action_id == selectedAction ? "" : "outlined"}
                            variant="outlined"
                            className={classNames({
                                wl_action_chip: true,
                                wl_selected_action: action.action_id == selectedAction 
                            })}
                        ></Chip>
                    })}
                </MDBox>
                {invitation && <MDBox>
                    {getMsgComp(invitation, onTableClick)}
                </MDBox>}
                {/* <div>{selectedAction} {JSON.stringify(actions)}</div> */}
            </Row>
            <WlChatInput needsText={invitation?.needs_text}
                onSend={prompt => onSend(prompt)}
                onFocus={e => setIsOpen(true)}
            />
        </MDBox>
    </MDBox >
    );
}

function getMsgComp(msg, onTableClick) {

    if (msg.type == "multi") {
        const child_comps = <MDBox display="flex" flexDirection="column">
            {_.map(msg.children, child => getMsgComp(child, onTableClick))}
        </MDBox>
        return child_comps
    }

    if (msg.type == "table")
        return <WlDataTable path={msg.path} table={msg.data} checkboxes={false}
            onTableClick={onTableClick}
        />

    if (msg.type == "text")
        return <MDBox color="#FFF">{msg.text}</MDBox>

    if (msg.type == "code")
        // return <Editor
        //     height="100px"
        //     language="python"
        //     theme="vs-dark"
        //     width={500}
        //     value={msg.code}
        // />
        return <SyntaxHighlighter language="python" style={vscDarkPlus}>
        {msg.code}
      </SyntaxHighlighter>
  

    if (msg.type == "error")
        return <MDBox color="#F88">Error: {msg.error}</MDBox>

    if (msg.type == "section-table")
        return msg.streaming == "update" ?
            <MDBox color="#FFF">Updating table {msg.name} ...</MDBox>
            :
            <MDBox color="#FFF">Table {msg.name} update done.</MDBox>

    return <MDBox>{JSON.stringify(msg)}</MDBox>

}