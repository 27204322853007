
import { ColorPickerOutlineDimensions } from "@emotion-icons/evaicons-outline/ColorPickerOutline"
import MDBox from "components/MDBox"
import { Card, CardMedia, CardContent, Typography } from "@mui/material";
import _ from "lodash"
import "./ProductsView.css"
import SectionFrame from "wellisco/workbooks/sections/SectionFrame"
import { split_to_records } from "wellisco/workbooks/utils/tableUtils";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';

import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';

export default function CodeView({ section, path, goTo }) {

    const table = section?.selected_data || section?.data

    return <SectionFrame className="wl_personas_view">
        <SyntaxHighlighter language="python" style={vscDarkPlus}>
            {section?.code}
        </SyntaxHighlighter>
    </SectionFrame>
}
