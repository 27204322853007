/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

import MDTypography from "components/MDTypography";

import _ from "lodash"
import { useMaterialUIController, doAsync, setAsyncValue } from "context";

import { useEffect, useState } from "react";
// import { getConfig, fetchSection, apiUrl } from "actions/insureTaxActions";

import WlChatPanel from "wellisco/workbooks/components/WlChatPanel";
import WlSideMenu from "wellisco/workbooks/components/WlSideMenu";

import RefreshIcon from '@mui/icons-material/Refresh';
import { Chip, IconButton } from "@mui/material";
import classNames from 'classnames';
import WlPersonaSelectionDialog from "./WlPersonaSelectionDialog";

function WlWorkbookPanel({ workbook, brand, title, viewComps }) {
    // const [controller, dispatch] = useMaterialUIController();
    // const { instureTaxConfig, section } = controller
    // const [selected, setSelected] = useState()
    // const [selectedParams, setSelectedParams] = useState()
    const [isChatOpen, setIsChatOpen] = useState()

    const { selectedPath } = workbook
    const { toc, crumbs, section, tabs, view } = workbook
    const { updateSelection } = workbook
    const { selectedTab, selectTab } = workbook
    const { isPersonaSelectorOpen, setIsPersonaSelectorOpen } = workbook

    function onSectionSelected(selectionUpdate) {
        updateSelection(selectionUpdate,true)
    }

    // function onActionDone() {
    //     // doAsync(dispatch, "section", fetchSection(customerId, selected, selectedParams))
    // }

    useEffect(() => {
        // if (!instureTaxConfig?.value)
        //     doAsync(dispatch, "instureTaxConfig", getConfig)
        if (_.isEmpty(toc))
            workbook.fetchTOC()
        document.title = title;
    }, [])

    // const pachedSecion = section
    // if (dataPatch) {
    //     pachedSecion["data"] = workbook.dataPatch
    //     pachedSecion["selected_data"] = workbook.dataPatch
    // }
    const SectionComp = workbook?.getSectionViewComp(section, view)

    // const customerName = toc?.customer?.name
    const crumbsView = _.map(crumbs, (crumb, i) => <>
        {i > 0 && <sup> / </sup>}
        <sup key={crumb}>{crumb}</sup>
    </>)

    const viewTabs = _.size(tabs) > 1 ? _.map(tabs, tab => {
        const tabId = tab.key
        return <MDBox className={classNames({ "wl_selected": tabId == selectedTab })}
            onClick={() => selectTab(tabId)}
        >
            {tab.name}
        </MDBox>
    }) : <></>

    const showChat = _.isUndefined(section?._chat) || section?._chat

    return (
        <>
            <WlPersonaSelectionDialog 
                workbook={workbook} 
                open={isPersonaSelectorOpen} 
                setOpen={setIsPersonaSelectorOpen}
            />
            <MDBox className="wl_workbook_panel">
                <MDBox spacing={0}
                    className="wl_side_menu_container"
                >
                    {brand || <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
                        {title}
                    </MDTypography>}
                    <MDBox>
                        <WlSideMenu
                            toc={toc}
                            selected={selectedPath}
                            onSelect={onSectionSelected}
                        />
                    </MDBox>
                </MDBox>
                <MDBox
                    sx={{
                        position: "relative",
                        display: "flex", flexDirection: "column",
                        flex: 1, overflow: "hidden",
                    }}>

                    {/* <div>{sectionPatch}</div> */}
                    {/* <div id="overlay"
                                className={classNames({ "wl_overlay": true, "wl_overlay_open": isChatOpen })}
                                onClick={() => { setIsChatOpen(false) }}
                            /> */}
                    <MDBox mt={2} mb={1}
                        // sx={{
                        //     display: "flex", alignItems: "center",
                        //     // "& > div": {
                        //     //     color: "red", padding: 0, marginTop: "auto", marginBottom: "auto",
                        //     //     lineHeight: 0,
                        //     //     verticalAlign: "middle"
                        //     // }
                        // }}
                        className="wl_panel_top"
                    >
                        <div className="wl_panel_crumbs">{crumbsView} </div>
                        {/* <div >
                            <RefreshIcon fontSize="medium"
                                sx={{
                                    fontSize: "20px", marginLeft: "8px", padding: 0,
                                    cursor: 'pointer', color: "var(--wl-highlight-color)", opacity: 0.5
                                }}
                                onClick={e => { onSectionSelected({ refresh: true }) }}
                            />
                        </div> */}
                        <MDBox className="wl_panel_top_tab">{viewTabs}</MDBox>
                    </MDBox>
                    <MDBox
                        // mb={1} overflow="auto" flexGrow={1} display="flex" flexDirection="column"
                        className="wl_section_container"
                    >
                        {SectionComp && <>
                            {/* <MDBox>{JSON.stringify(section)}</MDBox> */}
                            <SectionComp
                                workbook={workbook}
                                section={section}
                                path={selectedPath}
                                goTo={onSectionSelected}
                            />
                        </>}
                    </MDBox>
                    {showChat && <WlChatPanel title={title} section={section} workbook={workbook}
                        isOpen={isChatOpen} setIsOpen={setIsChatOpen}
                        onSectionSelected={onSectionSelected} />}
                </MDBox>
            </MDBox>
        </>
    )
}

// function WlSideMenu({ toc, selected, onSelect }) {
//     function walkSecsions(sec, parents = []) {
//         const secsToShow = _.filter(sec, sub => sub.show != false)
//         return _.map(secsToShow, (sub, key) => {
//             const nodes = _.concat(parents, key)
//             // const path = _.join(nodes, "/")
//             const color = sub.path == selected && "#817fff"
//             return <MDBox key={sub.path} m={0} p={0} ml={parents.length} color="white"
//                 onClick={e => {
//                     e.stopPropagation()
//                     onSelect?.(sub.path)
//                 }}
//             >
//                 <li style={{ color, cursor: "pointer" }}>{sub.name}</li>
//                 {(sub.show != false) &&
//                     <ul key={sub.path + "/"} style={{ listStyleType: "circle", listStylePosition: "inside" }}>
//                         {walkSecsions(sub?.children, nodes)}
//                     </ul>}
//             </MDBox>
//         })
//     }
//     return <>
//         <ul style={{ listStyleType: "none", listStylePosition: "inside" }}>
//             {walkSecsions(toc)}
//         </ul>
//     </>
// }

// function PDFSecion({section}) {
//     const filename = section?._file_name
//     const url = filename && `${apiUrl}/tax/flow/file/${filename}#toolbar=0&navpanes=0`
//     const width = 800
//     const height = width * (297.0/210.0)
//     return <div>
//         {url && <iframe src={url} style={{ width, height }} />}
//     </div>
// }


export default WlWorkbookPanel;
