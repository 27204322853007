import _ from "lodash"
import MDBox from 'components/MDBox';
import { Checkbox } from "@mui/material";

export default function WlDataTable({ path, table, onTableClick, checkboxes, className }) {
    // onClick ||= (path,row,col) => { alert(JSON.stringify({ path, id: row[0], col, val: row[col], row })) }
    // onClick ||= (row, col) => { alert(JSON.stringify({ id: row[0], col, val: row[col], row })) }
    // const headerStyle = {
    //     // border: "1px solid white",
    //     verticalAlign: "top", padding: 5, color: "#817fff"
    // }
    className ||= ""
    const rowStyle = {
        // border: "1px solid white", borderCollapse: "collapse",
        verticalAlign: "top", padding: 5,
        hyphens: "auto"
    }
    function cellContetnt(cell) {
        if (_.isBoolean(cell))
            return cell ? "✅" : "❌"

        return cell
    }

    return <MDBox mb={1} sx={{ "borderCollapse": "collapse" }} className={className}>
        <table cellPadding={5} cellSpacing={0} width="100%" className="wl_data_table">
            {/* <thead style={{ display: "table", width: "100%" }}> */}
            <thead>
                <tr>
                    {checkboxes && !_.isEmpty(table?.columns) && <th></th>}
                    {_.map(table?.columns, col => <th key={col}
                    >{col}</th>)}
                </tr>
            </thead>
            {/* <tbody style={{ display: "block", height: 200, overflow: "auto" }}> */}
            <tbody>
                {_.map(table?.data, (row, row_num) => {
                    const row_idx = table?.index?.[row_num]
                    return <tr key={row_num} >
                        {checkboxes && !_.isEmpty(row) && <td style={{ ...rowStyle, padding: 0 }}>
                            <Checkbox sx={{ '& .MuiSvgIcon-root': { width: "1rem", height: "1rem" } }} />
                        </td>}
                        {_.map(row, (cell, col_num) => <td key={row_num + "_" + col_num}
                            style={rowStyle}
                            onClick={e => onTableClick(path,row_idx,col_num)}
                        >
                            {cellContetnt(cell)}
                        </td>)}
                    </tr>
                })}
            </tbody>
        </table>
    </MDBox>
}