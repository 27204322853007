/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React examples
import WelliscoLayout from "wellisco/components/LayoutContainers/WelliscoLayout";
import PageLayout from "examples/LayoutContainers/PageLayout";
import DashboardNavbar from "wellisco/components/Navbars/DashboardNavbar";
import Footer from "wellisco/components/Footer";
import MasterCard from "examples/Cards/MasterCard";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Billing page components
import PaymentMethod from "layouts/billing/components/PaymentMethod";
import Invoices from "layouts/billing/components/Invoices";
import BillingInformation from "layouts/billing/components/BillingInformation";
import Transactions from "layouts/billing/components/Transactions";
import { useTranslation } from "react-i18next";
import MDTypography from "components/MDTypography";

import waitingLogo from "assets/images/wellisco-logo.png"
// import { useLocation, } from "react-router-dom";
import _ from "lodash"
import { Breadcrumbs, ButtonBase, Card, CardMedia, CardContent, CardHeader, Chip, IconButton, Link, List, ListItem, Tab, Tabs, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import SectionTitle from "wellisco/components/SectionTitle";

import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import IosShareIcon from '@mui/icons-material/IosShare';
import PaidIcon from '@mui/icons-material/Paid';
import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import { createElement, useEffect, useState } from "react";
import { CursorClick } from '@emotion-icons/heroicons-outline/CursorClick';
import { DollarCircle } from '@emotion-icons/boxicons-regular/DollarCircle';
import { Share } from '@emotion-icons/fa-solid/Share';
import routes from "routes";
import { useNavigate, useMatch, Routes, Route, useLocation } from 'react-router-dom';
import { useMaterialUIController, doAsync } from "context";
import { ProhibitedMultiple } from "emotion-icons/fluentui-system-filled";
import CachedIcon from '@mui/icons-material/Cached';
import { getTopPersonas, getProducts, getGoals, getChallanges, getEntities } from "actions/welliscoActions";
import { WLTitle } from "wellisco/components/WLComps";
import { Table, TableCell, TableRow } from "@mui/material"

export default function Personas() {
  const { t } = useTranslation()
  // const navigate = useNavigate();
  // const route = useLocation().pathname.split("/").slice(1);
  const theme = useTheme()
  const { palette, boxShadows, functions, transitions, breakpoints, borders } = theme;
  const [controller, dispatch] = useMaterialUIController();
  const { topPersonas, products, goals, challanges } = controller
  const logoSize = 60
  const [selectedPersona, setSelectedPersona] = useState()
  const [selectedTab, setSelectedTab] = useState(0);
  const { pathname } = useLocation()
  console.log("path", pathname)

  useEffect(() => {
    if (!topPersonas?.value) {
      doAsync(dispatch, "topPersonas", getTopPersonas)
      doAsync(dispatch, "products", getEntities("products"))
      doAsync(dispatch, "challanges", getChallanges)
      doAsync(dispatch, "goals", getGoals)
    }
  }, [])

  const personas = topPersonas?.value
  const personaId = personas && selectedPersona || _.first(personas)?.id
  const persona = _.find(personas, p => p.id == personaId)
  return (
    <WelliscoLayout>
      <DashboardNavbar isMini={false} />
      <MDBox sx={{
        display: "flex", flexDirection: "column",
        width: "100%",
        justifyItems: "strech",
        flexGrow: 1,
        // height: 1200,
        overflow: "hidden",
      }}
        id="layout-main"
        my={2}>
        <MDBox sx={{
          display: 'flex',
          alignItems: "center", justifyContent: "baseline",
          flexDirection: "column",
          flexGrow: 1,
          borderRadius: 4,
          // textAlign: "center",
          overflow: "hidden",
        }}
          bgColor="white"
          p={0}
          id="layout-white-frame"
        >

          <Grid container spacing={0} p={0} m={0} alignContent="space-between"
            sx={{ width: "100% " }}
          >
            <Grid item lg={3} p={1} display={"flex"} flexDirection={"column"}>
              <>
                {/* <h1>Insights</h1> */}
                <MDBox sx={{ display: "flex", flexWrap: "wrap" }}>
                  {_.map(persona && [persona], persona => <Card
                    sx={{ width: 260, margin: "5px" }}
                  >
                    <CardMedia
                      media="picture"
                      // alt="Contemplative Reptile"
                      image={`https://app.wellisco.com/images/${persona.image}`}
                      // title="Contemplative Reptile"
                      sx={{
                        // position: "absolute",
                        // top: 0,
                        // right: 0,
                        margin: 0,
                        marginLeft: "auto",
                        marginRight: "auto",
                        height: 180,
                        width: 180,
                        // margin: 0,
                        // filter: "grayscale(80%) blur(5px)",
                        // filter: "sepia(100%)",
                      }}
                    />
                    <CardContent sx={{ textAlign: "center" }}>
                      <Typography fontSize={22}>
                        {persona.name}
                      </Typography>
                      <Typography fontSize="small">
                        {persona.summary}
                      </Typography>
                    </CardContent>
                  </Card>)}
                </MDBox>
                {false && <table>
                  {_.map(topPersonas?.value, persona => <tr
                  >
                    {/* <td>{prod.id}</td> */}
                    <td>
                      <img src={`https://app.wellisco.com/images/${persona.image}`} style={{ width: 120 }}></img>
                    </td>
                    <td>{persona.name}
                      <div style={{ fontSize: "small" }}>{persona.summary}</div>
                    </td>
                  </tr>)}
                </table>}
              </>

            </Grid>
            <Grid container lg={9} display={"flex"} flexDirection={"column"} pr={2}>
              <Routes>
                <Route path={`/products`} element={<h2>Products</h2>} />
                <Route path={`/challanges`} element={<h2>Challanges</h2>} />
                <Route path="*" element={<MainInsgihts {...{ products, goals, challanges }} />} />
              </Routes>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer light />
    </WelliscoLayout >
  );
}

const limit = 5

function MainInsgihts({ products, challanges, goals }) {
  const navigate = useNavigate();

  return <><Grid item lg={6} display={"flex"} flexDirection={"column"} pr={1}>
    {/* <Tabs value={1} onChange={() => {}} aria-label="basic tabs example">
  <Tab label="Profile" sx={{ backgroundColor: "#333", color: "rgb(248, 177, 48)" }} />
  <Tab label="Insights"/>
  <Tab label="Item Three" />
</Tabs> */}
    <WLTitle title="Products"
      onClick={() => navigate("/insights/products")}
    />
    <ProductInsights products={products?.value} />
  </Grid>
    <Grid item lg={6} display={"flex"} flexDirection={"column"} pr={1}>
      <WLTitle title="Challanges"
        onClick={() => navigate("/insights/challanges")}
      />
      <ChallangesInsights challanges={challanges?.value} />
      <WLTitle title="Goals"
        onClick={() => navigate("/insights/goals")}
      />
      <GoalInsights goals={goals?.value} />
    </Grid>
  </>
}

const MoreButton = ({ onClick }) => <div sx={{ textAlign: "center" }}>
  <Typography textAlign="center" fontSize="small">Show more</Typography>
</div>

export function PersonaCard({ workbook, persona }) {
  return <MDBox sx={{ display: "flex", flexWrap: "wrap" }}>
    {_.map(persona && [persona], persona => <Card
      sx={{ width: 260, margin: "5px" }}
    >
      <CardMedia
        media="picture"
        // alt="Contemplative Reptile"
        image={`https://app.wellisco.com/images/${persona.image}`}
        // title="Contemplative Reptile"
        sx={{
          // position: "absolute",
          // top: 0,
          // right: 0,
          margin: 0,
          marginLeft: "auto",
          marginRight: "auto",
          height: 180,
          width: 180,
          // margin: 0,
          // filter: "grayscale(80%) blur(5px)",
          filter: "sepia(50%)",
        }}
        onClick={() => workbook?.setIsPersonaSelectorOpen(true) }
      />
      <CardContent sx={{ textAlign: "center" }}>
        <Typography fontSize={22}>
          {persona.name}
        </Typography>
        <Typography fontSize="small">
          {persona.summary}
        </Typography>
      </CardContent>
    </Card>)}
  </MDBox>
}

export function ProductInsights({ products, limit, onProductSelect }) {
  limit = limit || 8
  const hasMore = _.size(products) > limit
  products = _.take(products, limit)
  return <>
    <Table padding="none" sx={{ "& .MuiTableCell-root": { padding: "1px" } }}>
      {_.map(products, prod => <TableRow variant
      onClick={e => onProductSelect?.(prod)}
      >
        {/* <td>{prod.id}</td> */}
        <TableCell width={32} padding={0}>
          <img src={`/images/products1/${prod.id}_1.png`} style={{ width: 32 }}></img>
        </TableCell>
        <TableCell padding={0}>
          <Typography variant="body2" >{prod.name}</Typography>
          {/* <div style={{ fontSize: "small" }}>{prod.description}</div> */}
        </TableCell>
      </TableRow>)}
    </Table>
    {hasMore && <MoreButton />}
  </>
}

export function GoalInsights({ goals }) {
  const hasMore = goals?.length > limit
  goals = _.take(goals, limit)
  return <>
    <Table padding="none" sx={{ "& .MuiTableCell-root": { padding: "1px" } }}>
      {_.map(goals, goal => <TableRow
      // onClick={e => onProductSelect(prod)}
      >
        {/* <td>{prod.id}</td> */}
        <TableCell height={32} width={32} padding={0}>
          ◦
        </TableCell>
        <TableCell padding={0} sx={{ minHeight: 200 }}>
          <Typography variant="body2" >{goal.name}</Typography>
          {/* <div style={{ fontSize: "small" }}>{prod.description}</div> */}
        </TableCell>
      </TableRow>)}
    </Table>
    {hasMore && <MoreButton />}
  </>
}

export function ChallangesInsights({ challanges }) {
  const hasMore = challanges?.length > limit
  challanges = _.take(challanges, limit)
  return <>
    <Table padding="none" sx={{ "& .MuiTableCell-root": { padding: "1px" } }}>
      {_.map(challanges, challange => <TableRow
      // onClick={e => onProductSelect(prod)}
      >
        {/* <td>{prod.id}</td> */}
        <TableCell height={32} width={32} padding={0}>
          ◦
        </TableCell>
        <TableCell padding={0} sx={{ minHeight: 200 }}>
          <Typography variant="body2" >{challange.name}</Typography>
          {/* <div style={{ fontSize: "small" }}>{prod.description}</div> */}
        </TableCell>
      </TableRow>)}
    </Table>
    {hasMore && <MoreButton />}
  </>
}