export const cards = [
  // { insightType: "polar" },
  {
    upText: "Energy", downText: "Healthcare", insightType: "rotation",
    image: "http://localhost:3003/images/covers/sector_rotation_1.png",
    topics: ["Sector Rotation"],
  },
  {
    title: "Is Apple Changing Direction?",
    insightType: "gauge",
    flow: "indicator",
    min: 0, value: 77, max: 100, indicatorName: "Relative Strength Index",
    text: "AAPL Relative Strength Index is higher than 70, indicating possible change of direction and price declien.",
    info: "The relative strength index (RSI) is a momentum indicator used in technical analysis. RSI measures the speed and magnitude of a security's recent price changes to evaluate overvalued or undervalued conditions in the price of that security.",
    link: "https://www.investopedia.com/terms/r/rsi.asp",
    // image: "/images/covers/sector_rotation_1.png",
    image: "https://media.istockphoto.com/id/951426272/vector/abstract-financial-chart-with-line-graph-and-bar-chart-on-blue-color-background.jpg?s=612x612&w=0&k=20&c=-SSfAZO41JDa0iijwqucnZr7eAEdAj65sVJnJ6gcIOs=",
    topics: ["Trending Stocks"],
    message: "Act now with Exceleance minimum fees",
    callToAction: { 
      message: "Act now with Exceleance minimum fees",
      button: "Trade Now", 
      link: "https://www.xnes.co.il/OTPLogin/LoginOTP.aspx",
      disclaimer: "Please note that technical indicators are not trading recommendations and do not guarantee profits.",
    },
    next: [
      { button: "What is RSI", question: "What is the RSI technical indicator?", topic: "info" },
      { button: "Apple Price", question: "What is the current price of Apple stock?" },
      { button: "Apple Trend", question: "What is the current trend of Apple stock?" },
      { button: "Apple ETFs", question: "What ETFs include Apple?" },
    ]
  },
  // { title: "Financial 80s", insightType: "education", image: "/images/covers/history_repeating_80s_1.png" },
  {
    title: "5 Events That Shaped Inflation",
    topics: ["Kids Financial Education"],
    insightType: "education",
    flow: "events",
    subTitle: "",
    image: "http://localhost:3003/images/covers/history_of_inflation.png",
    bgImage: "http://localhost:3003/images/covers/history_of_inflation.png",
    intro: "Inflation has been a persistent challenge for economies throughout history. Key events that have shaped its history include ancient currency debasement, the creation of central banks, and the adoption of inflation targeting policies. These events have had significant impacts on global economies and policymaker strategies.",
    events: [
      {
        "short_title": "Debasement of currency by ancient civilizations",
        "date": null,
        "text": "Throughout history, ancient civilizations have often debased their currencies by reducing the precious metal content of coins. This practice was intended to increase the supply of coins, but it also led to inflation as the value of each coin decreased. Debasement eroded public trust in the currency, which further fueled inflation. Examples of ancient civilizations that debased their currencies include the Romans, Greeks, and Persians.",
        "google_search_link": "https://www.google.com/search?q=debasement+of+currency"
      },
      {
        "short_title": "Creation of central banks",
        "date": "20th century",
        "text": "In the 20th century, many countries established central banks as a way to control their monetary systems. Central banks have the power to influence interest rates, regulate the money supply, and manage inflation. However, some argue that the creation of central banks also contributed to the rise of inflation. Governments were able to manipulate monetary policy and print money more easily, which could lead to inflationary pressures.",
        "google_search_link": "https://www.google.com/search?q=central+banks+inflation"
      },
      {
        "short_title": "Two World Wars",
        "date": null,
        "text": "Both World War I and II had a significant impact on inflation rates. During wartime, governments often increase spending to support their military efforts. This spending can lead to inflationary pressures, as the money supply increases and the demand for goods and services rises. In addition, the disruption to global trade caused by the wars further fueled inflation. In the aftermath of the wars, many countries experienced high inflation rates and economic instability.",
        "google_search_link": "https://www.google.com/search?q=world+wars+inflation"
      },
      {
        "short_title": "Oil price shocks of the 1970s",
        "date": "1970s",
        "text": "In the 1970s, the global economy experienced two major oil price shocks. The first occurred in 1973 when the Organization of the Petroleum Exporting Countries (OPEC) imposed an oil embargo on countries that supported Israel in the Yom Kippur War. The second occurred in 1979 during the Iranian Revolution. The sudden increase in oil prices caused a rise in production costs for many goods and services, which led to inflationary pressures. The high oil prices also contributed to global economic instability.",
        "google_search_link": "https://www.google.com/search?q=oil+price+shocks+inflation"
      },
      {
        "short_title": "Adoption of inflation targeting",
        "date": "1970s and 1980s",
        "text": "In the 1970s and 1980s, many central banks began implementing inflation targeting policies to achieve price stability. Under inflation targeting, central banks set a specific inflation rate target and adjust monetary policy to maintain that target. The goal is to anchor inflation expectations and prevent high inflation rates. Many countries, including the United States, United Kingdom, and Canada, have adopted inflation targeting policies.",
        "google_search_link": "https://www.google.com/search?q=inflation+targeting"
      },
      {
        "short_title": "Global financial crisis of 2008",
        "date": "2008",
        "text": "The global financial crisis of 2008 had a significant impact on inflation rates. To stabilize the economy, governments around the world implemented monetary stimulus measures, such as lowering interest rates and increasing the money supply. While these measures helped to prevent a complete economic collapse, they also led to inflationary pressures. In addition, the crisis resulted in a slowdown in global trade and a decrease in demand for goods and services, which contributed to deflationary pressures in some countries. The impact of the crisis on inflation rates varied depending on the country and the specific monetary policy measures implemented.",
        "google_search_link": "https://www.google.com/search?q=global+financial+crisis+inflation"
      }
    ],
    "select_prompt": [
      "Which of these events do you think had the most significant impact on the history of inflation?",
      "Which of these events do you find the most interesting or surprising?",
      "If you could choose one of these events to learn more about, which would it be?",
      "Which of these events do you think had the biggest impact on your own country's economy?",
      "If you had to choose one of these events to explain to someone who knew nothing about inflation, which would you choose?"
    ]
  },
  {
    title: "Financial 80s", insightType: "education",
    flow: "events",
    image: "http://localhost:3003/images/covers/history_repeating_80s_1.png",
    bgImage: "http://localhost:3003/images/covers/history_repeating_80s_1.png",
    events: [
      {
        "short_title": "Black Monday (1987)",
        "date": "October 19, 1987",
        "text": "On October 19, 1987, global stock markets experienced a severe crash known as Black Monday. The Dow Jones Industrial Average plummeted by 22.6% in a single day, marking the largest one-day percentage decline in its history. The crash had significant implications for financial markets and led to increased scrutiny of market regulations and risk management practices."
      },
      {
        "short_title": "Volcker Shock and High-Interest Rates",
        "date": "Early 1980s",
        "text": "In the early 1980s, Paul Volcker, the Chairman of the U.S. Federal Reserve, implemented tight monetary policies to combat high inflation. These policies involved raising interest rates to exceptionally high levels. While effective in curbing inflation, they also contributed to a severe recession, impacting industries such as real estate and manufacturing."
      },
      {
        "short_title": "Savings and Loan Crisis",
        "date": null,
        "text": "The 1980s saw the collapse of the savings and loan (S&L) industry in the United States. Weak regulations, risky lending practices, and speculative investments led to the failure of many S&L institutions. The crisis resulted in a substantial government bailout and regulatory reforms to prevent a recurrence."
      },
      {
        "short_title": "Plaza Accord (1985)",
        "date": null,
        "text": "The Plaza Accord was an agreement among major economies, including the United States, Japan, Germany, France, and the United Kingdom, to depreciate the value of the U.S. dollar against the Japanese yen and the German Deutsche Mark. This agreement aimed to address the growing trade imbalances and support U.S. exports. The accord had significant implications for currency markets and international trade."
      },
      {
        "short_title": "Junk Bond Market and Leveraged Buyouts",
        "date": null,
        "text": "The 1980s witnessed a surge in leveraged buyouts (LBOs) facilitated by the availability of high-yield or \"junk\" bonds. Financiers like Michael Milken played a significant role in the development of the junk bond market, which fueled corporate takeovers and restructurings. While it contributed to economic growth and restructuring, the excessive use of leverage also led to a wave of bankruptcies and financial turmoil."
      }
    ],
    topics: ["Kids Financial Education"]
  },
  {
    title: "Wealth Tip #1",
    flow: "tips",
    insightType: "education", subTitle: "Holistic Wealth Management",
    image: "http://localhost:3003/images/covers/elite_finance_cover_1.png",
    "tips": [
      {
        "title": "Take a holistic approach to wealth management",
        "text": "High net worth individuals should consider their entire financial picture, including their assets, liabilities, income, and expenses, when developing a wealth management plan. This includes estate planning, tax planning, and risk management.",
        "teaser": "Holistic Wealth Management"
      },
      {
        "title": "Invest in alternative asset classes",
        "text": "High net worth individuals can benefit from investing in alternative asset classes such as private equity, real estate, and hedge funds. These investments can provide greater diversification, higher returns, and lower volatility than traditional asset classes.",
        "teaser": "Alternative Asset Investing"
      },
      {
        "title": "Incorporate tax-efficient investing strategies",
        "text": "Tax-efficient investing can help high net worth individuals minimize their tax liabilities and maximize their after-tax returns. Strategies such as tax-loss harvesting, charitable giving, and using tax-advantaged accounts can be effective ways to manage taxes.",
        "teaser": "Tax-Efficient Investing"
      },
      {
        "title": "Consider the benefits of global diversification",
        "text": "High net worth individuals should consider diversifying their investments globally to take advantage of opportunities in different regions and markets. This can help reduce risk and provide access to higher returns.",
        "teaser": "Global Diversification Benefits"
      },
      {
        "title": "Protect your assets with insurance",
        "text": "High net worth individuals should consider insurance as a way to protect their assets from unexpected events such as lawsuits, disability, or death. This includes liability insurance, disability insurance, life insurance, and long-term care insurance.",
        "teaser": "Asset Protection Insurance"
      }
    ],
    topics: ["IRS"]
  },
  {
    title: "AAPL Bollinger Band Crossing", image: "http://loclhost:3003/images/covers/dir_change.png",
    insightType: "indicator",
  },
  {
    insightType: "section",
    title: "אינפלציה – האם זה באמת מפחיד כמו שזה נשמע?",
    image: "https://www.xnes.co.il/media/5614/1900x300_%D7%90%D7%99%D7%A0%D7%A4%D7%9C% D7%A6%D7%99%D7%94.jpg?quality=80",
    actionText: "למד עוד",
    dir: "rtl",
  },
  // {
  //   insightType: "section",
  //   title: "Dealing with Inflation",
  //   body: "See how excellence can help in times of inflation.",
  //   actionText: "Learn More"
  // },
  // {
  //   insightType: "section",
  //   title: "Dealing with Inflation",
  //   body: "See how excellence can help in times of inflation.",
  //   actionText: "Learn More"
  // },
  {
    insightType: "quiz",
    question: "What sector performed the best over the last 30 days?",
    options: [
      { key: "a", name: "A", text: "Financials" },
      { key: "b", name: "B", text: "Consumer Staples" },
      { key: "c", name: "C", text: "Industrials" },
    ],
    correct: "Great work!",
    wrong: "Opps, not exactly",

  },

]

export const sampleCards = cards