
import supabase from "components/Supabase/supabaseClient";
const apiUrl = "/api"

export async function apiFetch(relativeUrl) {
    try {
        const url = apiUrl + relativeUrl
        const headers = await getAuthHeaders();

        const response = await fetch(url, {
            method: "GET",
            headers
        })
        if (!response.ok) {
            const message = `An error has occured: ${response.status}`;
            throw new Error(message);
        }
        const json = await response.json()
        return json
    }
    catch (e) {
        throw new Error(e);
    }
}

async function getAuthHeaders() {
    const session = await supabase.auth.getSession();
    const access_token = session?.data?.session?.accessToken;
    const provider_token = session?.data?.session?.provider_token;

    const headers = {};
    if (access_token) {
        headers["Authentication"] = `Bearer ${access_token}`;
        headers["accessToken"] = access_token;
    }
    if (provider_token)
        headers["provider_token"] = provider_token;
    return headers;
}

export function asyncWait(delay) {
    return new Promise(function (resolve, reject) {
        setTimeout(function () {
            resolve()
        }, delay);
    })
}
