/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// const TopicsPanel = React.lazy(() => import("./TopicsPanel"))

// Material Dashboard 2 React layouts
import { lazy } from 'react';
// import Dashboard from "layouts/dashboard";
// import Tables from "layouts/tables";
// import Billing from "layouts/billing";
// import RTL from "layouts/rtl";
// import Notifications from "layouts/notifications";
// import Profile from "layouts/profile";
// import SignIn from "layouts/authentication/sign-in";
// import SignUp from "layouts/authentication/sign-up";


// @mui icons
import Icon from "@mui/material/Icon";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import DevicesIcon from '@mui/icons-material/Devices';
import PeopleIcon from '@mui/icons-material/People';
import MessageIcon from '@mui/icons-material/Message';
import StorageIcon from '@mui/icons-material/Storage';
import PsychologyIcon from '@mui/icons-material/Psychology';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import NewspaperIcon from '@mui/icons-material/Newspaper';

const SupabaseAuth = lazy(() => import(/* webpackChunkName: "chunkSupabaseAuth" */ 'wellisco/layouts/auth'));;
const Placeholder = lazy(() => import(/* webpackChunkName: "Placeholder" */ 'wellisco/layouts/placeholder'));;
const Pulse = lazy(() => import(/* webpackChunkName: "Pulse" */ 'wellisco/layouts/pulse'));;
const News = lazy(() => import(/* webpackChunkName: "News" */ 'wellisco/layouts/news'));;
const Signals = lazy(() => import(/* webpackChunkName: "Signals" */ 'wellisco/layouts/signals'));;
const HomeDashboard = lazy(() => import(/* webpackChunkName: "HomeDashboard" */ 'wellisco/layouts/home-dashboard'));;
const HomeIntro = lazy(() => import(/* webpackChunkName: "HomeIntro" */ 'wellisco/layouts/home-intro'));;
const Calendar = lazy(() => import(/* webpackChunkName: "Calendar" */ 'wellisco/layouts/calendar'));;
const Playground = lazy(() => import(/* webpackChunkName: "Playground" */ 'wellisco/layouts/card-playground'));;
const StockPhysics = lazy(() => import(/* webpackChunkName: "StockPhysics" */ 'wellisco/blocks/StockPhysics'));;
const LinkedIn = lazy(() => import(/* webpackChunkName: "LinkedIn" */ 'wellisco/layouts/linkedin'));;
const FinChat = lazy(() => import(/* webpackChunkName: "FinChat" */ 'wellisco/layouts/fin-chat'));;
const Personas = lazy(() => import(/* webpackChunkName: "Personas" */ 'wellisco/layouts/personas'));;
const TopPersonas = lazy(() => import(/* webpackChunkName: "Personas" */ 'wellisco/layouts/personas/top_personas'));;
const Products = lazy(() => import(/* webpackChunkName: "Products" */ 'wellisco/layouts/products'));
const Insights = lazy(() => import(/* webpackChunkName: "Insights" */ 'wellisco/layouts/insights/Insights'));;
const FinWorkbook = lazy(() => import(/* webpackChunkName: "FinWorkbook" */ 'fin/FinWelliscoPanel'));;

const routes = [
  {
    type: "collapse",
    name: "Insights",
    longName: "Customer Insights",
    key: "insights",
    icon: <EmojiObjectsIcon />,
    route: "/insights",
    // component: <HomeDashboard />,
    component: <Insights />,
  },
  // {
  //   type: "collapse",
  //   name: "Campaigns",
  //   description: "Campaigns let you focus your marketing on mesurable business goals",
  //   key: "campaigns",
  //   icon: <PsychologyIcon />,
  //   route: "/campaigns",
  //   component: <Placeholder />,
  // },

  {
    type: "collapse",
    name: "Personas",
    longName: "Top Personas",
    description: "Address the needs of your target audiance using AI assisted customer profiles.",
    key: "top_personas",
    icon: <PeopleIcon />,
    route: "/top_personas",
    component: <TopPersonas />,
  },
  {
    type: "collapse",
    name: "Products",
    longName: "Products",
    key: "products",
    description: "Manage your financial products, thier benefits and applicability.",
    icon: <ShoppingCartIcon />,
    route: "/products",
    component: <Products />,
  },
  // {
  //   type: "collapse",
  //   name: "Wellness",
  //   longName: "Wellness",
  //   key: "wellness",
  //   description: "Manage your financial products, thier benefits and applicability.",
  //   icon: <ShoppingCartIcon />,
  //   route: "/wellness",
  //   component: <Products />,
  // },
  {
    type: "collapse",
    name: "Home",
    longName: "Wellisco Home",
    key: "home",
    icon: <Icon>home</Icon>,
    route: "/home",
    // component: <HomeDashboard />,
    component: <HomeIntro />,
  },
  {
    type: "collapse",
    name: "Persona Logic",
    longName: "Persona Logic",
    description: "Address the needs of your target audiance using AI assisted customer profiles.",
    key: "persona_logic",
    icon: <PeopleIcon />,
    route: "/persona_logic",
    component: <Personas />,
  },
  {
    type: "collapse",
    name: "Fin Chat",
    longName: "Fin Chat AI",
    description: "Chat with Financial Artificial Inteligence agents to analyse markets and create alerts",
    key: "fin-chat",
    icon: <FormatListBulletedIcon />,
    route: "/fin-chat",
    component: <FinChat />,
  },
  {
    type: "collapse",
    name: "Workbook",
    longName: "Wellisco Fin workbook",
    key: "workbook",
    icon: <MenuBookIcon />,
    route: "/workbook",
    // component: <HomeDashboard />,
    component: <FinWorkbook />,
  },
  {
    type: "collapse",
    name: "Topics",
    longName: "Topics for real-time Marketing",
    description: "Topics are the heart of real-time marketing., letting you monitor react to real world events.",
    key: "topics",
    icon: <FormatListBulletedIcon />,
    route: "/topics",
    component: <HomeDashboard />,
  },
  {
    type: "collapse",
    name: "Trends",
    longName: "Trending Content by Topic",
    description: "Trending news, videos and insights give you the hook to engage your customers",
    key: "trends",
    icon: <AccessAlarmIcon />,
    route: "/trends",
    component: <Pulse />,
  },
  {
    type: "collapse",
    name: "News",
    longName: "News",
    description: "News froum around the web",
    key: "news",
    icon: <NewspaperIcon />,
    route: "/news",
    component: <News />,
  },
  {
    type: "collapse",
    name: "Signals",
    longName: "Signals",
    description: "Signals and insights from the markets",
    key: "signals",
    icon: <NewspaperIcon />,
    route: "/signals",
    component: <Signals />,
  },

  // {
  //   type: "collapse",
  //   name: "Goals",
  //   key: "goals",
  //   icon: <EmojiEventsIcon />,
  //   route: "/goals",
  //   component: <Goals />,
  // },

  ////////////////////////////
  {
    type: "collapse",
    name: "Touchpoints",
    longName: "Customer Journey Touchpoints",
    description: "Add real-time marketing to enhance youe existing channels and customer touchpoints",
    key: "touchpoints",
    icon: <DevicesIcon />,
    route: "/touchpoints",
    component: <Placeholder />,
  },
  // {
  //   type: "collapse",
  //   name: "Sales",
  //   longName: "Customers & Personas",
  //   key: "customers",
  //   icon: <PeopleIcon />,
  //   route: "/customers",
  //   component: <Placeholder />,
  // },
  ///////////////////////////////

  // {
  //   type: "collapse",
  //   name: "Simulator",
  //   key: "simulator",
  //   icon: <Icon fontSize="small">visibility</Icon>,
  //   route: "/simulator",
  //   component: <Placeholder />,
  // },
  {
    type: "divider",
    key: "admin-section",
  },
  {
    type: "collapse",
    name: "Calendar",
    longName: "Financial Event Calendar",
    description: "Engage customers about past and future events using the financial calendar",
    key: "calendar",
    icon: <CalendarTodayIcon />,
    route: "/calendar",
    component: <Calendar />,
  },


  ///////////////////////////////////////
  // {
  //   type: "collapse",
  //   name: "Buying Guides",
  //   longName: "Product Buying Guides",
  //   description: "Take customers from concideration to conversion with interactive buying guides",
  //   key: "guides",
  //   icon: <ShoppingCartIcon />,
  //   route: "/guides",
  //   component: <Placeholder />,
  // },
  // {
  //   type: "collapse",
  //   name: "Customer Education",
  //   longName: "Financial Education Programs",
  //   key: "education",
  //   icon: <MenuBookIcon />,
  //   route: "/education",
  //   component: <Placeholder />,
  // },
  // {
  //   type: "collapse",
  //   name: "Data Sources",
  //   longName: "Data & Content Sources",
  //   key: "data",
  //   icon: <StorageIcon />,
  //   route: "/data",
  //   component: <Placeholder />,
  // },
  //////////////////////////////////

  {
    type: "divider",
    key: "divider-2"
  },


  // {
  //   type: "collapse",
  //   name: "Topics",
  //   key: "topics",
  //   icon: <MenuBookIcon />,
  //   route: "/topics",
  //   component: <Placeholder />,
  // },
  // {
  //   type: "collapse",
  //   name: "Content",
  //   key: "content",
  //   icon: <MessageIcon />,
  //   route: "/topics",
  //   component: <Placeholder />,
  // },
  {
    type: "collapse",
    name: "Playground",
    key: "playground",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/playground",
    component: <Playground />,
  },
  {
    type: "collapse",
    name: "LinkedIn",
    key: "linkedin",
    icon: <PeopleIcon />,
    route: "/linkedin",
    component: <LinkedIn />,
  },
  {
    type: "collapse",
    name: "Stock",
    key: "stock",
    icon: <StorageIcon />,
    route: "/stock",
    component: <StockPhysics />,
  },


  /////////////////////////////
  // {
  //   type: "collapse",
  //   name: "Dashboard",
  //   key: "dashboard",
  //   icon: <Icon fontSize="small">dashboard</Icon>,
  //   route: "/dashboard",
  //   component: <Dashboard />,
  // },
  // {
  //   type: "collapse",
  //   name: "Tables",
  //   key: "tables",
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   route: "/tables",
  //   component: <Tables />,
  // },
  // {
  //   type: "collapse",
  //   name: "Billing",
  //   key: "billing",
  //   icon: <Icon fontSize="small">receipt_long</Icon>,
  //   route: "/billing",
  //   component: <Billing />,
  // },
  // {
  //   type: "collapse",
  //   name: "RTL",
  //   key: "rtl",
  //   icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
  //   route: "/rtl",
  //   component: <RTL />,
  // },
  // {
  //   type: "collapse",
  //   name: "Notifications",
  //   key: "notifications",
  //   icon: <Icon fontSize="small">notifications</Icon>,
  //   route: "/notifications",
  //   component: <Notifications />,
  // },
  // {
  //   type: "collapse",
  //   name: "Profile",
  //   key: "profile",
  //   icon: <Icon fontSize="small">person</Icon>,
  //   route: "/profile",
  //   component: <Profile />,
  // },
  /////////////////////////////////////


  // {
  //   type: "collapse",
  //   name: "Sign In",
  //   key: "sign-in",
  //   icon: <Icon fontSize="small">login</Icon>,
  //   route: "/authentication/sign-in",
  //   component: <SignIn />,
  // },
  // {
  //   type: "collapse",
  //   name: "Sign Up",
  //   key: "sign-up",
  //   icon: <Icon fontSize="small">assignment</Icon>,
  //   route: "/authentication/sign-up",
  //   component: <SignUp />,
  // },
  {
    type: "collapse",
    name: "Authentication",
    key: "supabase-auth",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/auth",
    component: <SupabaseAuth />,
  },
];

export default routes;
