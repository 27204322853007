import _ from "lodash"
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import symbolLogos from 'assets/symbol-logos.js';
import StockAnalysisChart from 'wellisco/blocks/StockAnalysisChart';
import Story from "wellisco/widget/story";
// import VideoStory from "wellisco/widget/story/youtube";
import VideoStory from "wellisco/widget/story/video";
import MDTypography from "components/MDTypography";
import GaugeChart from "wellisco/blocks/GaugeChart";

function symbolLogo(symbol) {
  console.log(symbolLogos)
  if (symbolLogos.includes(symbol) == false)
    return
  return <img src={`images/symbol-logos/${_.upperCase(symbol)}.png`} width={40} style={{ marginRight: 10 }} />
}


export function getInsightComp(type, msg) {
  const story = msg?.story
  return <>
    {story?.insightType == "news" && <MDBox className={`lang_${story.language}`}>
      <h3>{story.title}</h3>
      <MDBox my={1} style={{ display: "flex" }}>
        <img width={250} src={story.image} style={{ borderRadius: 8 }} />
        <MDBox mx={1}>
          <MDBox width={350} dangerouslySetInnerHTML={{ __html: story.description }}></MDBox>
          <MDBox width={200}>{story.summary}</MDBox>
        </MDBox>
      </MDBox>
    </MDBox>}
    {story?.insightType == "youtube" && <MDBox className={`lang_${story.language}`}>
      <VideoStory {...story} />
    </MDBox>}
    {story?.insightType == "indicator" && <MDBox className={`lang_${story.language}`}
      sx={{ height: 400, width: 600 }}
    >
      <MDTypography variant="h4" fontWeight="light" color="text">
        {story.symbol} - {_.startCase(story.top_ta)}
      </MDTypography>

      <StockAnalysisChart
        records={story.data}
        indicator={{ field: story.top_ta, name: _.startCase(story.top_ta) }}
        showDetails={true}
        height={300}
        width={800}
        style={{ position: "absolute", top: 60 }}
      />
    </MDBox>}
  </>
}

// export function getToolComp(compType, msg) {
//   return <div>
//     {/* <StockAnalysisChart records={msg.prices} /> */}
//     {/* {_.map(msg.prices,price => <div>{price.close}</div>)} */}
//     {(compType == "_get_sector_stock_symbol") &&
//       <MDBox sx={{ display: "flex", alignItems: "center" }}>
//         {<img src={`images/sectors/${_.upperCase(msg.sector)}.png`} width={40} style={{ marginRight: 10 }} />}
//         <div>Sector: {msg.sector} - Symbol: {msg.symbol}</div>
//       </MDBox>}
//     {(compType == "stock_current_price") &&
//       <MDBox sx={{ display: "flex", alignItems: "center" }}>
//         {symbolLogo(msg.symbol)}
//         <div>{msg.symbol} current price is {msg.close}</div>
//       </MDBox>}

//     {(compType == "symbol_by_company_name") &&
//       <MDBox sx={{ display: "flex", alignItems: "center" }}>
//         {symbolLogo(msg.symbol)}
//         <div>The symbol of {msg.name} is {msg.symbol}</div>
//       </MDBox>}

//     {(compType == "get_rate_of_change") && <>
//       {msg.title && <h4 style={{ marginBottom: 5 }}>{msg.title}</h4>}
//       <div style={{ position: "relative" }}>
//         <StockAnalysisChart records={msg.prices} indicator={{ field: "roc", name: "Rate of Change" }}
//           height={300}
//           width={600}
//           showDetails={true} />
//       </div>

//     </>}

//     {(compType == "get_regression_line") && <>
//       {msg.title && <h4 style={{ marginBottom: 5 }}>{msg.title}</h4>}
//       <div style={{ position: "relative" }}>
//         <StockAnalysisChart
//           records={msg.prices}
//           lines={[
//             { slope: msg.slope, intercept: msg.intercept, alpha: msg.r_value }
//           ]}
//           height={300}
//           width={600}
//           showDetails={true} />
//       </div>
//     </>}
//     {(compType == "get_moving_average") && <>
//       {msg.title && <h4 style={{ marginBottom: 5 }}>{msg.title}</h4>}
//       <div style={{ position: "relative" }}>
//         <StockAnalysisChart
//           records={msg.prices}
//           lines={[
//             { slope: 0, intercept: _.last(msg.prices).sma, alpha: 1 }
//           ]}
//           height={300}
//           width={600}
//           showDetails={true} />
//       </div>
//     </>}

//     {(compType == "get_today") && <>
//       <div>Today is: {msg.today}</div>
//     </>}

//   </div>
// }

export const toolComps = {
  "_get_sector_stock_symbol": ({ msg }) =>
    <MDBox sx={{ display: "flex", alignItems: "center" }}>
      {<img src={`images/sectors/${_.upperCase(msg.sector)}.png`} width={40} style={{ marginRight: 10 }} />}
      <div>Sector: {msg.sector} - Symbol: {msg.symbol}</div>
    </MDBox>,

  "stock_current_price": ({ msg }) =>
    <MDBox sx={{ display: "flex", alignItems: "center" }}>
      {symbolLogo(msg.symbol)}
      <div>{msg.symbol} current price is {msg.close}</div>
    </MDBox>,

  "symbol_by_company_name": ({ msg }) =>
    <MDBox sx={{ display: "flex", alignItems: "center" }}>
      {symbolLogo(msg.symbol)}
      <div>The symbol of {msg.name} is {msg.symbol}</div>
    </MDBox>,

  "get_rate_of_change": ({ msg }) => <>
    {msg.title && <h4 style={{ marginBottom: 5 }}>{msg.title}</h4>}
    <div style={{ position: "relative" }}>
      <StockAnalysisChart records={msg.prices} indicator={{ field: "roc", name: "Rate of Change" }}
        height={300}
        width={600}
        showDetails={true} />
    </div>
  </>,

  "get_regression_line": ({ msg }) => <>
    {msg.title && <h4 style={{ marginBottom: 5 }}>{msg.title}</h4>}
    <div style={{ position: "relative" }}>
      <StockAnalysisChart
        records={msg.prices}
        lines={[
          { slope: msg.slope, intercept: msg.intercept, alpha: msg.r_value }
        ]}
        height={300}
        width={600}
        showDetails={true} />
    </div>
  </>,

  "get_moving_average": ({ msg }) => <>
    {msg.title && <h4 style={{ marginBottom: 5 }}>{msg.title}</h4>}
    <div style={{ position: "relative" }}>
      <StockAnalysisChart
        records={msg.prices}
        lines={[
          { slope: 0, intercept: _.last(msg.prices).sma, alpha: 1 }
        ]}
        height={300}
        width={600}
        showDetails={true} />
    </div>
  </>,

  "get_stock_ETFs": ({ msg }) => <>
    <div style={{ position: "relative" }}>
      <table width={400}>
        {_.map(msg.ETFs, row => <tr><td>{row["ETF"]}</td><td>{Math.round(row["% Holding"] * 100, 2)}%</td></tr>)}
      </table>
    </div>
  </>,

  "gauge": ({ msg }) => <>
    <GaugeChart {...msg.data} />
  </>,
  // "get_today": ({msg}) => <>
  //   <div>Today is: {msg.today}</div>
  // </>,
}
