const apiUrl = "http://127.0.0.1:1120/api"

async function apiFetch(relativeUrl) {
    try {
        const url = apiUrl + relativeUrl
        // const headers = await getAuthHeaders();

        const response = await fetch(url, {
            method: "GET",
            // headers
        })
        if (!response.ok) {
            const message = `An error has occured: ${response.status}`;
            throw new Error(message);
        }
        const json = await response.json()
        return json
    }
    catch (e) {
        throw new Error(e);
    }
}

export async function getConfig() {
    const result = await apiFetch(`/tax/config/load`)
    return result
}