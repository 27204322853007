import { getMessagesForTopics } from "./FlowUtils"

export default {
    "flow_id": "default",
    "init": ctx => {
        // ctx.salesMessage = "love"
        // const saleMessages = getMessagesForTopics(ctx?.card?.topics)
        // console.log("saleMessages",saleMessages)
    },
    "topics": [
        {
            "topic": "intro",
            "steps": [
                // { type: "agent", text: "Hi, we found this item you may find interesting" },
                { type: "insight", story: ctx => ctx.card },
                { type: "agent-sale", text: ctx => ctx.card?.message },
            ]
        },
    ]
}
