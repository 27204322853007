import { useTheme } from "@emotion/react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import _ from "lodash"

export default function SectionTitle({title,sx}) {
    const theme = useTheme()
    const { palette, typography, functions, boxShadows } = theme;
    const { white, dark, light, grey, gradients, info } = palette;
    const { linearGradient, pxToRem } = functions;

    return <MDBox py={1} mt={2} mb={2}
        mx={0}
        px={2}
        variant="gradient"
        // bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
        sx={_.assign({
            background: linearGradient(gradients["dark"].main, gradients["dark"].state),
        },sx)}
    >
        <MDTypography variant="h6" color="info">
            {title}
        </MDTypography>
    </MDBox>
}