import { createClient } from '@supabase/supabase-js'

// const supabaseUrl = process.env.REACT_APP_SUPABASE_URL
// const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY

const supabaseUrl="https://bnbuekvpsnigdityynrz.supabase.co"
const supabaseAnonKey="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJuYnVla3Zwc25pZ2RpdHl5bnJ6Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzQwNTM3MzcsImV4cCI6MTk4OTYyOTczN30.lfeYuoWJD3rG-lapnWAxnc8QnuPMjcs1-Dht0GKfG9E"

export const supabase = createClient(supabaseUrl, supabaseAnonKey)

export default supabase