import MDBox from "components/MDBox";
import NewsStory from "./news";
import VideoStory from "./youtube";
import FinChat from "wellisco/layouts/fin-chat/FinChat";
import { startChat } from "flows/FlowUtils";
import { useState } from "react";
import { ChatModel } from "flows/FlowUtils";
import { Typography } from "@mui/material";
import { useMaterialUIController } from "context";

export default function Story(props) {
    const { card } = props
    const [controller, dispatch] = useMaterialUIController();
    const model = new ChatModel(card, controller)
    const initialChatCtx = model.getInitialCtx()
    const [chatCtx, setChatCtx] = useState(initialChatCtx)
    model.setCtx(chatCtx, setChatCtx)
    // const model = new ChatModel(card,chatCtx,setChatCtx)
    const direction = card.language == "he" ? "rtl" : "ltr"

    return <>
        {/* <MDBox sx={{ backgroundColor: "red", flexGrow: 1 }}> */}
        {/* { card.insightType == "news" && <NewsStory {...card} /> }
        { card.insightType == "youtube" && <VideoStory {...card} /> } */}
        <MDBox m={0} id="story-root" sx={{
            flexGrow: 1, display: "flex", flexDirection: "column",
            direction,
        }}>

            <FinChat model={model} chatLog={[
                { type: "agent", text: "Hi, we found this item you may find interesting" },
                { type: "insight", story: card }
            ]} />
        </MDBox>
        {/* </MDBox> */}
    </>
}