import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useTheme } from "@emotion/react";

export function WLTitle({ title, onClick }) {
    const theme = useTheme()
    const { palette, boxShadows, functions, transitions, breakpoints, borders } = theme;
    const { gradients } = palette;
    const { linearGradient } = functions;

    return <MDBox py={1} mt={2} mb={2}
        onClick={() => onClick?.()}
        mx={0}
        px={2}
        variant="gradient"
        // bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
        sx={{
            background: linearGradient(gradients["dark"].main, gradients["dark"].state),
        }}
    >
        <MDTypography variant="h6" color="info">
            {title}
        </MDTypography>
    </MDBox>
}