/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import YTPlayer from "yt-player"
import { useRef, useEffect } from "react";
import _ from "lodash"

export default function QuizCard({ card }) {
    return (
      <Card classes={{
        root: "wellisco-card w-section"
    }}>
        <MDBox className="wellisco-card w-content">
            <MDTypography className="w-title">
                {card.question}
            </MDTypography>
            <MDTypography className="w-body">
                {card.body}
            </MDTypography>
            <MDButton className="w-action-button">
                {card.actionText}
            </MDButton>
        </MDBox>
    </Card>
    );
}