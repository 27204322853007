import { useEffect, useState } from "react";
import _ from "lodash"

const { PolarArea } = require("react-chartjs-2");

const defaultData = {
  labels: [
    'Red',
    'Green',
    'Yellow',
    'Grey',
    'Blue'
  ],
  datasets: [{
    label: 'My First Dataset',
    data: [11, 16, 7, 3, 14],
    backgroundColor: [
      'rgb(255, 99, 132)',
      'rgb(75, 192, 192)',
      'rgb(255, 205, 86)',
      'rgb(201, 203, 207)',
      'rgb(54, 162, 235)'
    ]
  }]
};

const options = {
  scales: {
    r: {
      ticks: {
        display: false,
        color: 'red'
      },
    }
  },
  maintainAspectRatio: false ,
  plugins: {
    legend: {
      display: false,
    },
    
    datalabels: {
      display: false
    },
    tooltips: {
      enabled: false,
    }
  },
  animation: {
    animateScale: true,
    animateRotate: true
  }
}

export default function BlockPolarAreatChart(props) {
  const [data,setData] = useState(defaultData)
  function randomValues() {
    alert("love")
    const newValues = _.map(_.first(data?.datasets)?.data,v => Math.random() * 25)
    const newData = _.set(data,"datasets[0].data",newValues)
    setData(newData)
  }
  // useEffect(() => {
  //   setTimeout(() => {
  //   },2000)
  // })
  return <PolarArea 
    data={data} options={options} 
    style={{height:"240px"}} 
    onClick={() => randomValues()}
    />
}