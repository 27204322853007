import React, { useState } from 'react';
import { TextField, Checkbox, FormControlLabel, Button, Box } from '@mui/material';

const WLDynamicForm = ({ data }) => {
  const [formState, setFormState] = useState(data);

  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;
    setFormState({
      ...formState,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic here
    console.log(formState);
  };

  const renderInputField = (key, value) => {
    const isBoolean = typeof value === 'boolean';
    const isNumber = typeof value === 'number';

    if (isBoolean) {
      return (
        <FormControlLabel
          control={
            <Checkbox
              name={key}
              checked={value}
              onChange={handleChange}
            />
          }
          label={key}
        />
      );
    }

    return (
      <TextField
        name={key}
        label={key}
        type={isNumber ? 'number' : 'text'}
        value={value}
        onChange={handleChange}
        margin="normal"
        fullWidth
      />
    );
  };

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate autoComplete="off">
      {Object.entries(formState).map(([key, value]) => (
        <div key={key}>
          {renderInputField(key, value)}
        </div>
      ))}
      <Button type="submit" variant="contained" color="primary">
        Submit
      </Button>
    </Box>
  );
};

export default WLDynamicForm;
