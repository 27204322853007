import { useState, useEffect } from "react";
import _ from "lodash"

import { getSectionComp } from "wellisco/workbooks";

// export const apiUrl = "ws://127.0.0.1:1120"
// const exposursUrl = `${apiUrl}/ws/exposures/123?refresh=True`
// const loveUrl = `${apiUrl}/api/tax/flow/ws/love`
// const actionUrl = `${apiUrl}/api/tax/flow/action`
// const actionUrl = `${apiUrl}/api/tax/flow/action`

export function useWorkbook({apiUrl,viewComps}) {
    // const [socket, setSocket] = useState()
    const [selection,setSelection] = useState({})

    const [selectedCustomerId, setSelectedCustomerId] = useState()
    const [selectedPath, setSelectedPath] = useState()
    const [selectedParams, setSelectedParams] = useState()
    const [selectedAction, setSelectedAction] = useState()
    const [selectedTab, setSelectedTab] = useState()

    const [toc, setToc] = useState([])
    // const [section, setSection] = useState()
    const [chat, setChat] = useState([])
    const [error, setError] = useState()
    const [processing, setProcessing] = useState()
    const [dataPatch, setDataPatch] = useState()

    const [sectionCache,setSectionCache] = useState({})
    const [isPersonaSelectorOpen, setIsPersonaSelectorOpen] = useState(false)

    const tocEntry = getTOCEntry(selectedPath)
    const crumbs = _.split(tocEntry?.path,"/")
    const tabs = tocEntry?.tabs
    const tab = selectedTab ? _.find(tabs,tab => tab.key == selectedTab) : _.first(tabs)
    const sectionKey = tab?.section || tocEntry?.section
    const viewKey = tab?.view || tocEntry?.view

    const section = getSection(sectionKey)
    const view = viewKey

    var websocket
    const actionUrl = _.replace(`${apiUrl}/action`, "http", "ws")

    // const throttledUpdate = _.throttle(setChat, 10)
    const actionFilter = action => action.enabled
    const enabledActions = _.filter(section?._actions, actionFilter) || []
    const activeActionId = selectedAction || _.first(enabledActions)?.action_id
    const activeAction = _.find(enabledActions, act => act.action_id == activeActionId)

    // function selectSection(path, params) {
    //     setSelected(path)
    //     setSelectedParams(params)
    //     // setAsyncValue(dispatch, "section", undefined )
    //     doAsync(dispatch, "section", fetchSection(customerId, path, params))
    // }

    // function onActionDone() {
    //     doAsync(dispatch, "section", fetchSection(customerId, selected, selectedParams))
    // }

    function getTOCEntry(path) {
        var entry = undefined   
        var children = toc
        _.each(_.split(path,"/"), part => {
            entry = _.find(children, entry => entry.key == part)
            children = entry?.children
        })
        return entry
    }

    function getSectionKey(path,tabKey) {
        const tocEntry = getTOCEntry(path)
        const tabs = tocEntry?.tabs
        const tab = tabKey ? _.find(tabs,tab => tab.key == tabKey) : _.first(tabs)
        const sectionKey = tab?.section || tocEntry?.section
        return sectionKey
    }

    useEffect(() => {
        // console.log("entring chat")
        return () => {
            // console.log("exiting chat")
            if (websocket)
                websocket.close()
            websocket = undefined
        }
    }, [])

    useEffect(() => {
        // if (!selectedAction) {
        const action = _.first(enabledActions)
        setSelectedAction(action?.action_id)
        // }
    }, [section?._actions])

    async function fetchTOC(customerId) {
        try {
            const response = await apiFetch(`${apiUrl}/toc`)
            const { toc } = response
            // console.log("toc", toc)
            setToc(toc)
        }
        catch (e) {
            console.error("toc", e)
        }
    }

    function selectSection(customerId, path, params, tab, refresh) {
        customerId ||= selectedCustomerId
        path ||= selectedPath
        params ||= selectedParams || {}
        tab ||= selectedTab
        params = { ...params, "persona_id": selection.persona }

        setSelectedCustomerId(customerId)
        setSelectedPath(path)
        setSelectedParams(params)
        setSelectedTab(tab)

        const sectionKey = getSectionKey(path,tab)

        fetchSelectedSection(customerId, sectionKey, params,refresh)
    }

    function selectTab(tabKey) {
        selectSection(null, null, null, tabKey, true)
    }

    function updateSelection(selectionUpdate,refresh) {
        // console.log("selectionUpdate",selectionUpdate)
        const {customerId, path, params, tabs } = selectionUpdate
        selectSection(customerId, path, params, undefined, refresh)
        setSelection(prevSelection => {
            const params = { ...prevSelection.params, ...selectionUpdate.params }
            return { ...prevSelection, ...selectionUpdate, params }
        })
    }

    async function fetchSelectedSection(customerId, sectionKey, params, refresh) {
        // const tocEntry = getTOCEntry(path)
        // const sectionKey = tocEntry.section
        const sec = await fetchSection(customerId, sectionKey, params, refresh)
        // setSection(sec)
        updateSectionCache(sectionKey,sec)
    }

    async function fetchSection(customerId, sectionKey, params, refresh) {
        try {
            params = params || {}
            if (refresh) params.refresh = true
            const params_str = _.join(_.map(params, (v, k) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`), "&")
            const result = await apiFetch(`${apiUrl}/sections/${sectionKey}/panel?${params_str}`)
            return result
        }
        catch (e) {
            console.error("section", e)
        }
        return undefined
    }

    function getSection(sectionKey) {
        if (!sectionKey)
            return undefined
        // console.log("getSection")
        
        let sec = sectionCache[sectionKey]
        if (sectionKey in sectionCache)
            return sectionCache[sectionKey]

        // updateSectionCache(sectionKey,undefined) 
        // fetchSection(selectedCustomerId,sectionKey).then(
        //     sec => {
        //         // console.log("got sec",path,sec)
        //         updateSectionCache(sectionKey,sec) 
        //     }
        // )
    }

    function startAction(actionId, user_prompt) {
        const action = _.find(enabledActions, act => act.action_id == actionId)
        if (action) {
            doWebSocketAction(actionUrl, { ...action, user_prompt })
        }
    }

    function updateChat(data) {
        setChat(oldChat => {
            const lastStreaming = _.last(oldChat)?.streaming
            if (data.streaming && lastStreaming == "update") {
                return [...oldChat.slice(0, -1), data]
            } else {
                return [...oldChat, data]
            }
        })
    }

    function updateSectionCache(key,section) {
        setSectionCache(oldCache => {
            return { ...oldCache, [key]:  section }
        })
    }

    function removeSectionCache(key) {
        setSectionCache(oldCache => {
            const newCache  = { ...oldCache }
            delete newCache[key]
            return newCache
        })
    }

    function onActionDone() {
        // console.log("onActionDone")
        // if (processing) {
        // fetchSelectedSection(selectedCustomerId, selectedPath, selectedParams)
        setProcessing(false)
        setDataPatch(undefined)
        // }
    }

    function doWebSocketAction(url, action) {
        setProcessing(true)
        var actionPath = section._path
        websocket = new WebSocket(url);
        // console.log(websocket)

        websocket.onopen = (event) => {
            // setSocket(websocket)
            if (action)
                websocket.send(JSON.stringify(action))
        }
        websocket.onerror = (event) => {
            // setSocket(null)
            setError(error)
            onActionDone(actionPath)
        }
        websocket.onclose = (event) => {
            // setSocket(null)
            // console.log("websocket closed")
            websocket = undefined
            onActionDone(actionPath)
        }
        websocket.onmessage = (event) => {
            // console.log(event)
            // onEvent({type: "love"})
            try {
                if (websocket) {
                    const data = JSON.parse(event.data)
                    updateChat(data)
                    const flat = flattenMessages([data])
                    const steables = _.filter(flat, msg => msg.type == "section-table")
                    const patch = _.last(steables)?.data
                    if (patch) {
                        setDataPatch(patch)
                    }
                }
            }
            catch { }
        }

        // return websocket
    }

    const sectionChat = _.filter(chat, msg => (msg?.section == section?._path))
    // if (!processing && activeAction?.invitation)
    //     sectionChat.push(activeAction?.invitation)

    const flattendChat = flattenMessages(sectionChat)
    const roleChat = groupMessagesByRole(sectionChat)

    // const dataPatch = processing && _.last(_.filter(flattendChat, msg => msg.type == "section-table"))?.data
    // console.log(processing, dataPatch)

    const patchedSection = _.cloneDeep(section)
    if (dataPatch) {
        patchedSection["data"] = dataPatch
        patchedSection["selected_data"] = dataPatch
    }

    function getSectionViewComp(section, view) {
        const comp = section && getSectionComp(section, view, viewComps)
        return comp
    }

    const workbook = {
        selection,
        selectedCustomerId,
        selectedPath,
        selectedParams,
        selectedAction: activeActionId,
        selectedTab: tab?.key,
        // selectedView: selectedTab,

        toc,
        crumbs,
        tabs,

        section: patchedSection,
        view,

        actions: enabledActions,
        chat: sectionChat,
        invitation: !websocket && activeAction?.invitation,
        // dataPatch,
        roleChat,
        error,

        isPersonaSelectorOpen, 
        setIsPersonaSelectorOpen,

        // socket,
        fetchTOC,
        // selectSection,
        updateSelection,
        selectTab,
        selectAction: setSelectedAction,
        startAction,

        getSection,
        getSectionViewComp,
    }

    // console.log("workbook",workbook)
    return workbook
}

async function apiFetch(url) {
    try {
        // const url = apiUrl + relativeUrl
        // const headers = await getAuthHeaders();

        const response = await fetch(url, {
            method: "GET",
            // headers
        })
        if (!response.ok) {
            const message = `An error has occured: ${response.status}`;
            throw new Error(message);
        }
        const json = await response.json()
        return json
    }
    catch (e) {
        throw new Error(e);
    }
}


function groupMessagesByRole(messages) {
    const rows = []
    var row = undefined
    _.each(messages, msg => {
        if (row?.role != msg.role) {
            row = { role: msg.role, messages: [] }
            rows.push(row)
        }
        row.messages = row.messages.concat(msg)
    })
    return rows
}

function flattenMessages(list) {
    let flatList = [];
    for (let message of list) {
        flatList.push(message);
        if (message.children) {
            flatList = flatList.concat(flattenMessages(message.children));
        }
    }
    return flatList;
}

