
import { useEffect, useRef, useState } from 'react';
import _ from "lodash"
import MDBox from 'components/MDBox';
import classNames from 'classnames';

export default function WlSideMenu({ toc, selected, onSelect }) {
    function walkSecsions(sec, parents = []) {
        const secsToShow = _.filter(sec, sub => sub.show != false)
        return _.map(secsToShow, (sub, key) => {
            const nodes = _.concat(parents, key)
            // const path = _.join(nodes, "/")
            // const color = sub.path == selected && "#817fff"
            return <MDBox key={sub.path} m={0} p={0} ml={parents.length} 

                // color="white"
                onClick={e => {
                    e.stopPropagation()
                    if (sub.clickable) {
                        onSelect?.({ path: sub.path, tab: undefined, })
                        // onSelect?.(sub)
                    }
                }}
            >
                {/* <li style={{ color, cursor: "pointer" }}>{sub.name}</li> */}
                <li
                    className={classNames({
                        "wl_side_menu": true,
                        "wl_side_menu_selected": sub.path == selected,
                        "wl_side_menu_clickable": sub.clickable,
                    })} 
                >{sub.name}</li>
                {(sub.show != false) &&
                    <ul key={sub.path + "/"} style={{ listStyleType: "circle", listStylePosition: "inside" }}>
                        {walkSecsions(sub?.children, nodes)}
                    </ul>}
            </MDBox>
        })
    }
    return <>
        <ul style={{ listStyleType: "none", listStylePosition: "inside" }}>
            {walkSecsions(toc)}
        </ul>
    </>
}
