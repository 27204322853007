
// import { apiUrl } from "actions/insureTaxActions";
import SectionFrame from "./SectionFrame";
import MDBox from "components/MDBox";
const apiUrl = ""
export default function PDFSection({section}) {
    const filename = section?._file_name
    const url = filename && `${apiUrl}/tax/flow/file/${filename}#toolbar=0&navpanes=0`
    const width = 1000
    const height = width * (297.0/210.0)
    return <SectionFrame section={section}>
        love
        {url && <iframe src={url} style={{ width, height, overflow: "hidden", margin: "auto" }} />}
        {/* <MDBox sx={{ height: 200,backgroundColor: "red" }} >love</MDBox> */}
    </SectionFrame>
}
