/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";

// react-router-dom components
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React context
import { useMaterialUIController, setLayout } from "context";

import bgImage from "assets/images/tunnel-background.png";

function WelliscoLayout({ children }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav } = controller;
  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, "dashboard");
  }, [pathname]);

  return (<>
    <MDBox
      position="fixed"
      width="100%"
      minHeight="100vh"
      maxHeight="100vh"
      overflow="hidden"
      // sx={{
      //   backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
      //   bgImage &&
      //     `${linearGradient(
      //       rgba(gradients.dark.main, 0.6),
      //       rgba(gradients.dark.state, 0.6)
      //     )}, url(${bgImage})`,
      //   backgroundSize: "cover",
      //   backgroundPosition: "center",
      //   backgroundRepeat: "no-repeat",
      // }}
      sx={{ 
        padding: 0,
        margin: 0,
        top: 0,
      }}
    />
    <MDBox
      sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        padding: "4px 16px 0px 8px", //"16px",
        position: "relative",
        minHeight: "100vh",
        maxHeight: "100vh",
        // backgroundColor: "yellow",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",

        [breakpoints.up("xl")]: {
          marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
          transition: transitions.create(["margin-left", "margin-right"], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },
      })}
      id="wellisco-layout-wrapper"
    >
      {children}
    </MDBox>
  </>
  );
}

// Typechecking props for the WelliscoLayout
WelliscoLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default WelliscoLayout;
