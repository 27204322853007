import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import _ from "lodash"
import Grow from '@mui/material/Grow';
import Slide from '@mui/material/Slide';
import Story from "wellisco/widget/story";
import { DataGrid } from '@mui/x-data-grid';
import MDBox from 'components/MDBox';
import FinChat from 'wellisco/layouts/fin-chat/FinChat';
import useClasses from 'wellisco/utils/useClasses';

const styles = theme => ({
    dialogRoot: {
        display: "flex",
    },
    dialogPaper: {
        minHeight: '85vh',
        maxHeight: '85vh',
        minWidth: '85vw',
        maxWidth: '85vw',
        // display: "flex",
        // alignItems: "strech",
        // alignContent: "strech",
        // justifyContent: "strech",
        // justifyItems: "strech",
    },
})

export default function StoryDialog(props) {
    const { story, handleClose } = props
    const [scroll, setScroll] = React.useState('paper');
    const classes = useClasses(styles);

    // const handleClickOpen = (scrollType) => () => {
    //     setOpen(true);
    //     setScroll(scrollType);
    // };

    // const handleClose = () => {
    //     // setOpen(false);
    // };

    const open = !_.isEmpty(story)
    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (story) {
            const { current: descriptionElement } = descriptionElementRef;
            // descriptionElement?.focus();
        }
    }, [story]);

    // const Transition = React.forwardRef(function Transition(props, ref) {
    //     return <Grow in={true} ref={ref} {...props} />;
    //   });

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll={scroll}
            keepMounted
            // fullScreen={true}
            fullWidth={true}
            maxWidth="xl"
            classes={{
                container: classes.dialogRoot,
                paper: classes.dialogPaper,
            }}
        >
            <DialogContent dividers={scroll === 'paper'}
                sx={{ display: "flex", flexDirection: "column" }}
            >

                {story && <Story card={story} />}
                {/* <DocData story={story} /> */}
            </DialogContent>
        </Dialog>
    );
}

export function DocData(props) {
    const { story } = props
    const topics = _.fromPairs(_.sortBy(_.toPairs(story?.topics), 1).reverse())
    return <>
        {
            _.isObject(story?.extract) && <>
                <MDBox mt={2} mb={1}><h3>Summary</h3></MDBox>
                <div>{story?.relevance?.["short summary"]}</div>

            </>
        }
        {
            _.isObject(story?.entities) && <>
                <MDBox mt={2} mb={1} ><h3>Entities Found in Document</h3></MDBox>
                <DataGrid
                    rows={_.map(story?.entities, (item, key) => ({
                        id: key,
                        text: key,
                        type: item.entity_type || item["entity type"],
                        direction: item.direction,
                    }))}
                    columns={[{ field: "text", headerName: "Entity", width: 600 }, { field: "type" }, { field: "direction", width: 150 }]}
                    pageSize={5}
                    rowsPerPageOptions={[10]}
                    checkboxSelection
                    style={{ height: 400 }}
                />
            </>
        }

        {
            _.isObject(story?.extract) && <>
                <MDBox mt={2} mb={1}><h3>Extracted Data</h3></MDBox>
                <DataGrid
                    rows={_.map(story?.extract, (item, key) => ({
                        id: key,
                        text: item.description,
                        value: item.value || item.exact_value || item["exact value"],
                        units: item.units,
                    }))}
                    columns={[{ field: "text", headerName: "Data in Document", width: 600 }, { field: "value" }, { field: "units", width: 150 }]}
                    pageSize={5}
                    rowsPerPageOptions={[10]}
                    checkboxSelection
                    style={{ height: 400 }}
                />
            </>
        }

        {
            _.isObject(topics) && <>
                <MDBox mt={2} mb={1}><h3>Topic Relevance</h3></MDBox>
                <DataGrid
                    rows={_.map(topics, (value, key) => ({
                        id: key,
                        text: key,
                        value: value
                    }))}
                    columns={[{ field: "text", headerName: "Data in Document", width: 600 }, { field: "value" }, { field: "units", width: 150 }]}
                    pageSize={5}
                    rowsPerPageOptions={[10]}
                    checkboxSelection
                    style={{ height: 400 }}
                />
            </>
        }

        <div>{JSON.stringify(story, null, "\t")}</div>


        {/* {[...new Array(50)]
        .map(
            () => `Cras mattis consectetur purus sit amet fermentum.
Cras justo odio, dapibus ac facilisis in, egestas eget quam.
Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
Praesent commodo cursus magna, vel scelerisque nisl consectetur et.`,
        )
        .join('\n')} */}
    </>
}