/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import YTPlayer from "yt-player"
import { useRef, useEffect } from "react";
import _ from "lodash"
import { Typography } from "@mui/material";

function VideoStory({ image, label, title, description, videoId, lang, message }) {
  const direction = (lang == "he") ? "rtl" : "ltr"

  const youtubeThumb = useRef(null);
  const youtubePlayerDiv = useRef(null);
  const youtubePlayer = useRef(null);
  videoId = _.replace(videoId, "https://youtu.be/", "")
  const videoThumbUrl = image // "//img.youtube.com/vi/" + videoId + "/hqdefault.jpg"

  const vidWitdh = 720
  const vidHeight = vidWitdh * (9. / 16.0)

  let ytPlayer
  useEffect(() => {

    if (youtubePlayerDiv.current && youtubePlayer.current && !ytPlayer) {
      ytPlayer = new YTPlayer(youtubePlayer.current, {
        autoplay: 0,
        showinfo: 0,
        modestBranding: 1,
        annotations: 0,
        captions: 0,
        controls: 0,
        // autohide: 1,
        // wmode: "opaque",
        // rel: 0
        // playsInline: true,
      })
      // ytPlayer.load(data.videoId,true)
      ytPlayer.load(videoId, false)
      // ytPlayer.stop()
      console.log("Loading video")
    }
  });

  // const VideoBlock = () =>

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        // backgroundColor: "transparent",
        boxShadow: "none",
        overflow: "visible",
        width: "100%",
        height: "100%",
        direction: direction,
        "&.MuiPaper-root":{
          backgroundColor: "black !important",
          // backgroundColor: "transparent !important"
        }
      }}
    >
      {/* <MDBox m={2} sx={{ textAlign: "center" }}>
        <Typography variant="h3">{message}</Typography>
      </MDBox> */}
      <MDBox position="relative" width="100.25%" borderRadius="xl">
        {/* <CardMedia
                    src={image}
                    component="img"
                    title={title}
                    sx={{
                        maxWidth: "100%",
                        width: "100%",
                        maxHeight: 180,
                        margin: 0,
                        boxShadow: ({ boxShadows: { md } }) => md,
                        objectFit: "cover",
                        objectPosition: "center",
                    }}
                /> */}
        <>
          <div style={{ left: 0, width: vidWitdh, height: vidHeight, margin: "auto", 
            marginTop: 50, marginBottom: 50 }}
            ref={youtubePlayerDiv}
          // id={"yt_" + data.id}
          >
            <div ref={youtubePlayer} id={"yt_player_" + videoId} style={{ width: "100%", height: "100%" }} />

          </div>
        </>

      </MDBox>
      {/* <MDBox mt={1} mx={0.5} textAlign="center" >
        <MDTypography variant="body" fontWeight="light" color="text">
          {description}
        </MDTypography>
      </MDBox> */}
      {/* <MDBox mt={1} mx={0.5} textAlign="center" >
      <MDButton variant="gradient" color="info" sx={{ minWidth: 200}}>
          Learn More</MDButton>
      </MDBox> */}
    </Card>
  );
}

// Setting default values for the props of DefaultProjectCard
VideoStory.defaultProps = {
  authors: [],
};

// Typechecking props for the DefaultProjectCard
VideoStory.propTypes = {
  image: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]),
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
      "white",
    ]).isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  authors: PropTypes.arrayOf(PropTypes.object),
};

export default VideoStory;
