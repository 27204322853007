// import { choosePrompt } from "./FlowUtils"

export default {
    "flow_id": "default",
    "topics": [
        {
            "topic": "intro",
            "steps": [
                { type: "agent", text: ctx => ctx.card.text },
                { type: "fin-tool", "tool-type": "gauge", data: ctx => ctx.card },
                { type: "agent-sale", callToAction: ctx => ctx.card?.callToAction },
                // { type: "agent", text: ctx => ctx.card?.disclaimer },
                // { type: "agent", text: ctx => ctx.card.info },
            ],
            "next": ctx => ctx.card.next
        },
        {
            "topic": "info",
            "steps": [
                { type: "agent", text: ctx => ctx.card.info },
                // { type: "fin-tool", "tool-type": "gauge", data: ctx => ctx.card },
                // { type: "agent-sale", callToAction: ctx => ctx.card?.callToAction },
                // { type: "agent", text: ctx => ctx.card?.disclaimer },
                // { type: "agent", text: ctx => ctx.card.info },
            ],
            "next": ctx => ctx.card.next
        },
    ]
}
