
import { ColorPickerOutlineDimensions } from "@emotion-icons/evaicons-outline/ColorPickerOutline"
import MDBox from "components/MDBox"
import { Card, CardMedia, CardContent, Typography, Grid } from "@mui/material";
import _ from "lodash"
import "./ProductsView.css"
import SectionFrame from "wellisco/workbooks/sections/SectionFrame"
import { split_to_records } from "wellisco/workbooks/utils/tableUtils";
import { PersonaCard, ProductInsights, GoalInsights, ChallangesInsights } from "wellisco/layouts/insights/Insights";
import { WLTitle } from "wellisco/components/WLComps";
import { WLTable } from "wellisco/components/Table/WLTable";
import WlDataTable from "wellisco/workbooks/components/WlDataTable";

export default function RecommendationsView({ workbook, section, path, goTo }) {

    const table = section?.selected_data || section?.data
    const personas = split_to_records(section?.personas?.data)
    const products = split_to_records(section?.products?.data)
    const goals = split_to_records(section?.goals?.data)
    const challanges = split_to_records(section?.challanges?.data)
    const recommandations = split_to_records(section?.recommandations)

    const { selection } = workbook
    const persona = (selection && _.find(personas, persona => persona.id == selection.persona)) || _.first(personas)

    const onProductSelect = prod => {
        // alert(prod.id)
        workbook.updateSelection({ params: { product_id: prod.id } })
    }
    return <SectionFrame className="wl_white_section">
        <Grid container lg={12}>
            <Grid item lg="3" >
                <PersonaCard persona={persona} workbook={workbook} />
            </Grid>
            <Grid item lg={5} display={"flex"} flexDirection={"column"} pr={1}>
                {/* <Tabs value={1} onChange={() => {}} aria-label="basic tabs example">
  <Tab label="Profile" sx={{ backgroundColor: "#333", color: "rgb(248, 177, 48)" }} />
  <Tab label="Insights"/>
  <Tab label="Item Three" />
</Tabs> */}
                <WLTitle title="Products"
                // onClick={() => navigate("/insights/products")}
                />
                <ProductInsights products={recommandations} onProductSelect={onProductSelect} />
            </Grid>


            {/* <WlDataTable table={recommandations} /> */}
            <Grid item lg={4} display={"flex"} flexDirection={"column"} pr={1}>
                <WLTitle title="Description"
                // onClick={() => navigate("/insights/challanges")}
                />
                {section?.name && <h3>{section?.name}</h3>}
                <div>
                    {section?.description}
                </div>
                {/* <ChallangesInsights challanges={challanges} />
                <WLTitle title="Goals"
                // onClick={() => navigate("/insights/goals")}
                />
                <GoalInsights goals={goals} />
                <WLTitle title="Why?" /> */}

            </Grid>
            <Grid item lg={12} display={"flex"} flexDirection={"column"} pr={1}>
                <WLTitle title="Why?" />
                <WlDataTable className="wl_light" table={section?.product_rules} />
            </Grid>
        </Grid>
        <Grid container lg={12}>
        </Grid>
    </SectionFrame>
}
