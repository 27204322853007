import events from "./flow_events"
import tips from "./flow_tips"
import indicator from "./flow_indicator"
import defaultFlow from "./flow_default"

const flows = {
    events,
    tips,
    indicator,
}

export function getFlow(type) {
    let flow = flows[type] || defaultFlow
    return flow
}