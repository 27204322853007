/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect, useMemo } from 'react';

// @mui material components

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import _ from "lodash"

// Material Dashboard 2 React examples

// Billing page components
import { useTranslation } from "react-i18next";
import { useMaterialUIController } from "context";

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Chip, TextField } from '@mui/material';

import { Avatar } from "@mui/material";
import { deepOrange, grey } from "@mui/material/colors";
import useClasses from "wellisco/utils/useClasses";

import ConstructionIcon from '@mui/icons-material/Construction';
import PsychologyIcon from '@mui/icons-material/Psychology';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import CircleIcon from '@mui/icons-material/Circle';
import { getToolComp, toolComps, getInsightComp } from './chat_comp_factory';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ChatModel } from 'flows/FlowUtils';

const defaultChatMessage = ""

export function TaxChatInput(props) {
  const { onSend } = props
  const [message, setMessage] = useState(defaultChatMessage)
  const [showTools, setShoeTools] = useState(props.showTools == true)

  function doSend(message) {
    onSend(message)
    setMessage(undefined)
  }

  return <MDBox m={1} sx={{ display: "flex", flexDirection: "row", justifyContent: "strech" }}>
    <TextField id="tax-chat" placeholder='Type your message' variant="outlined"
      value={message}
      onChange={e => setMessage(e.target.value)}
      onKeyDown={e => { console.log("code", e.code); if (e.code == "Enter") doSend(message) }}
      color="white"
      sx={{
        flexGrow: 1, marginRight: 0,
        "& input": { fontSize: 18, color: "#FFFF", borderColor: "red" },
      }}
      size="medium"
    />
    <MDBox sx={{ display: "flex", alignItems: "center", marginRight: 0 }}>
      <MoreVertIcon
        onClick={e => setShoeTools(!showTools)}
      />
    </MDBox>
    <MDButton variant="gradient" color="dark"
      onClick={e => {
        doSend(message)
      }}
    >Send</MDButton>
  </MDBox>
}

export function MsgBot({ text, icon, comp }) {
  return text ? <MessageLeft
    photoURL="https://lh3.googleusercontent.com/a-/AOh14Gi4vkKYlfrbJ0QLJTg_DLjcYyyK7fYoWRpz2r4s=s96-c"
    displayName=""
    avatarIcon={icon}
    avatarDisp={_.isEmpty(icon) == false}
  >
    {text}
  </MessageLeft>
    : <div></div>
}

export function MsgUser({ text, icon, comp }) {
  return text ? <MessageRight
    message="messageRあめんぼあかいなあいうえおあめんぼあかいなあいうえおあめんぼあかいなあいうえお"
    photoURL="https://lh3.googleusercontent.com/a-/AOh14Gi4vkKYlfrbJ0QLJTg_DLjcYyyK7fYoWRpz2r4s=s96-c"
    avatarDisp={false}
  >{text}
  </MessageRight>
    : <div></div>
}

const styles = theme => ({
  messageRow: {
    display: "flex",
    width: "100%",
  },
  messageRowRight: {
    display: "flex",
    justifyContent: "flex-end"
  },
  messageLeft: {
    position: "relative",
    marginLeft: "20px",
    marginRight: "20px",
    marginBottom: "10px",
    padding: "10px",
    backgroundColor: "#333",
    maxWidth: "100%",
    // width: "100%",
    flexGrow: 1,
    //height: "50px",
    textAlign: "left",
    font: "400 .9em 'Open Sans', sans-serif",
    border: "0px solid #97C6E3",
    borderRadius: "10px",
    color: "rgb(129, 127, 255)",
    "&:after": {
      content: "''",
      position: "absolute",
      width: "0",
      height: "0",
      borderTop: "15px solid #333",
      borderLeft: "15px solid transparent",
      borderRight: "15px solid transparent",
      top: "0",
      left: "-15px"
    },
    "&:before": {
      content: "''",
      position: "absolute",
      width: "0",
      height: "0",
      borderTop: "17px solid transparent",
      borderLeft: "16px solid transparent",
      borderRight: "16px solid transparent",

      top: "-1px",
      left: "-17px"
    }
  },
  messageRight: {
    position: "relative",
    marginRight: "20px",
    marginBottom: "10px",
    padding: "10px",
    backgroundColor: "#333",
    width: "80%",
    //height: "50px",
    textAlign: "left",
    font: "400 .9em 'Open Sans', sans-serif",
    border: "0px solid #dfd087",
    borderRadius: "10px",
    color: "#FFF",
    "&:after": {
      content: "''",
      position: "absolute",
      width: "0",
      height: "0",
      borderTop: "15px solid #333",
      borderLeft: "15px solid transparent",
      borderRight: "15px solid transparent",
      top: "0",
      right: "-15px"
    },
    "&:before": {
      content: "''",
      position: "absolute",
      width: "0",
      height: "0",
      borderTop: "17px solid transparent",
      borderLeft: "16px solid transparent",
      borderRight: "16px solid transparent",
      top: "-1px",
      right: "-17px",
    }
  },

  messageContent: {
    padding: 0,
    margin: 0,
    paddingRight: 5,
    backgroundColor: "red",
  },
  messageTimeStampRight: {
    position: "absolute",
    fontSize: ".85em",
    fontWeight: "300",
    marginTop: "10px",
    bottom: "-3px",
    right: "5px"
  },

  orange: {
    color: theme.palette.getContrastText(grey[500]),
    backgroundColor: grey[500],
    width: theme.spacing(4),
    height: theme.spacing(4)
  },
  avatarNothing: {
    color: "transparent",
    backgroundColor: "transparent",
    width: theme.spacing(4),
    height: theme.spacing(4)
  },
  displayName: {
    marginLeft: "20px"
  }
})


export const MessageLeft = (props) => {
  const message = props.message ? props.message : "no message";
  // const timestamp = props.timestamp ? props.timestamp : "";
  const photoURL = props.photoURL ? props.photoURL : "dummy.js";
  const displayName = props.displayName ? props.displayName : "";
  const { avatarDisp } = props
  const { timestamp, children, avatarIcon: AvatarIcon } = props
  const classes = useClasses(styles);

  return (
    <>
      <div className={classes.messageRow}>
        {/* { avatarDisp && AvatarIcon && <Avatar
          alt={displayName}
          className={classes.orange}
          // src={photoURL}
        ><AvatarIcon /></Avatar>} */}
        {AvatarIcon && <AvatarIcon fontSize="large" />}
        <div style={{ flexGrow: 1 }}>
          {avatarDisp && <div className={classes.displayName}>{displayName}</div>}
          <div className={classes.messageLeft}>
            <div >
              {children ? children : <p className={classes.messageContent}>{message}</p>}
            </div>
            {timestamp && <div className={classes.messageTimeStampRight}>{timestamp}</div>}
          </div>
        </div>
      </div>
    </>
  );
};

export const MessageRight = (props) => {
  const classes = useClasses(styles);
  const message = props.message ? props.message : "no message";
  // const timestamp = props.timestamp ? props.timestamp : "";
  const { timestamp, children } = props
  return (
    <div className={classes.messageRowRight}>
      <div className={classes.messageRight}>
        {children ? children : <p className={classes.messageContent}>{message}</p>}
        {timestamp && <div className={classes.messageTimeStampRight}>{timestamp}</div>}
      </div>
    </div>
  );
};
