import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';

import testData from './BollingerTestData'
import chroma from 'chroma-js';

const BollingerBandsChart = ({ records, showDetails }) => {
    showDetails = showDetails == true
    records ||= testData
    const recordsWithBands = _.filter(records,r => r.upper_band || r.lower_band)
    const columns = ["date","open","close","low","high","upper_band","lower_band","crossover","crossover_short"]
    const stockData = _.reduce(columns,(obj, key) => {
        obj[key] = _.map(recordsWithBands,r => r[key])
        return obj
    },{})
    const crossOverShort = _.filter(recordsWithBands, r => r.crossover_short)
    const crossOverLong  = _.filter(recordsWithBands, r => r.crossover_long)

    const [data, setData] = useState([]);
    const [layout, setLayout] = useState({});

    const dimColor = color => showDetails ? color : chroma(color).set('hsl.s',0).hex()

    useEffect(() => {
        const { data, layout } = getData();
        setData(data);
        setLayout(layout);
    }, [records]);

    const getData = () => {
        const trace1 = {
            x: stockData.date,
            close: stockData.close,
            decreasing: { line: { color: dimColor('#AA4444') } },
            high: stockData.high,
            increasing: { line: { color: dimColor('#44AA44') } },
            line: { color: dimColor('rgba(31,119,180,1)') },
            low: stockData.low,
            open: stockData.open,
            type: 'candlestick',
            xaxis: 'x',
            yaxis: 'y',
            hoverinfo: 'skip',
        };

        const trace2 = {
            x: stockData.date,
            y: stockData.upper_band,
            line: { color: dimColor('#FFA07A') },
            name: 'Upper Band',
            type: 'scatter',
            xaxis: 'x',
            yaxis: 'y',
            hoverinfo: 'skip',

        };

        const trace3 = {
            x: stockData.date,
            y: stockData.lower_band,
            fill: 'tonexty',
            fillcolor: dimColor('rgba(255,160,122,0.1)'),
            line: { color: dimColor('#FFA07A') },
            name: 'Lower Band',
            type: 'scatter',
            xaxis: 'x',
            yaxis: 'y',
            hoverinfo: 'skip',
        };

        const trace4 = {
            x: _.map(crossOverShort,r => r.date),
            y: _.map(crossOverShort,r => r.upper_band),
            mode: 'markers',
            marker: {
                color: '#f99d1c',
                size: 30,
                symbol: 'x',
                line: {
                    color: 'red',
                    width: 0.1,
                },
            },
            name: 'Crossover',
            type: 'scatter',
            xaxis: 'x',
            yaxis: 'y',
        };

        const trace5 = {
            x: _.map(crossOverLong,r => r.date),
            y: _.map(crossOverLong,r => r.lower_band),
            mode: 'markers',
            marker: {
                color: 'green',
                size: 30,
                symbol: 'x',
                line: {
                    color: '#FFA07A',
                    width: 1,
                },
            },
            name: 'Crossover',
            type: 'scatter',
            xaxis: 'x',
            yaxis: 'y',
        };

        const data = [trace1, trace2, trace3, trace4, trace5];
        // const data = [trace1, trace2, trace3 ];

        const layout = {
            dragmode: 'zoom',
            // height: '100%',
            // width: '100%',
            // margin: {
            //     r: 0,
            //     t: 25,
            //     b: 40,
            //     l: ,
            // },
            paper_bgcolor: '#FFF0',
            plot_bgcolor: '#FFF0',
            margin: {
                    r: 0,
                    t: 0,
                    b: 0,
                    l: 0,
                },
            showlegend: false,
            xaxis: {
                autorange: true,
                domain: [0, 1],
                range: [stockData.date[0], stockData.date[stockData.date.length - 1]],
                rangeslider: { visible: false, range: [stockData.date[stockData.date.length - 30], stockData.date[stockData.date.length - 1]] },
                title: 'Date',
                type: 'date',
                visible: false,
            },
            yaxis: {
                autorange: true,
                domain: [0, 1],
                range: [Math.min(...stockData.low) * 0.9, Math.max(...stockData.high) * 1.1],
                type: 'linear',
                visible: showDetails,
                showticklabels: showDetails,
                // automargin: true,
                margin: 0,
            },
            animate: true,
            animation_duration: 1000,        
        };

        return { data, layout };
    };

    return (
        <Plot
            data={data}
            layout={layout}
            config={{ responsive: true, displayModeBar: showDetails,  }}
            useResizeHandler={true}
            style={{ width: '100%', height: '100%' }}
        />
    );
};

export default BollingerBandsChart