const {apiFetch,asyncWait} = require("actions/actionUtils")

export async function loadUserConfig() {
    await asyncWait(3000)
    const config = await apiFetch("/user/config")
    console.log("userConfig",config)
    return config
}

export async function loadPulseInsights() {
    const result = await apiFetch("/pulse/insights")
    console.log("insights",result)
    return result
}

export async function loadNews(limit=50) {
    const result = await apiFetch(`/news?limit=${limit}`)
    console.log("insights",result)
    return result
}

export async function loadTopSignals(limit=50) {
    const result = await apiFetch(`/fin/signals/top`)
    console.log("insights",result)
    return result
}

export async function loadPulseTrends() {
    const result = await apiFetch("/pulse/trends")
    console.log("trends",result)
    return result
}

export async function loadCalendar() {
    const result = await apiFetch("/calendar/events")
    console.log("calendar",result)
    return result
}

export async function getLinkedInConnections() {
    const result = await apiFetch("/linkedin/connections")
    console.log("linkedin",result)
    return result
}

export async function loadTopics() {
    const result = await apiFetch("/pulse/topics")
    console.log("topics",result)
    return result
}

export async function loadPersonas(limit=50) {
    const result = await apiFetch(`/fin/personas/`)
    console.log("personas",result)
    return result
}

export async function getPersona(personaId) {
    const result = await apiFetch(`/fin/logic/rules/product_match/${personaId}`)
    console.log("personas",result)
    return result
}

export async function getPersonaProductMatchGPT(personaId) {
    const result = await apiFetch(`/fin/logic/gpt/product_match/${personaId}`)
    console.log("personas",result)
    return result
}

export async function getPersonaWellnessMatchGPT(personaId) {
    const result = await apiFetch(`/fin/logic/gpt/wellness/${personaId}`)
    console.log("wellness",result)
    return result
}

export async function getPersonaFactorsGPT(personaId) {
    const result = await apiFetch(`/fin/logic/gpt/persona_factors/${personaId}`)
    console.log("personas",result)
    return result
}

export async function getProducts() {
    const result = await apiFetch(`/fin/products/`)
    console.log("products",result)
    return result
}

export async function getGoals() {
    const result = await apiFetch(`/fin/graph/goals`)
    console.log("goals",result)
    return result
}

export async function getChallanges() {
    const result = await apiFetch(`/fin/graph/challanges`)
    console.log("chllanges",result)
    return result
}

export async function getTopPersonas() {
    const result = await apiFetch(`/fin/personas/top`)
    console.log("personas/top",result)
    return result
}

export function getEntities(entityType) {
    return async () => {
        const result = await apiFetch(`/fin/graph/${entityType}`)
        console.log(entityType,result)
        return result
    }
}