import _ from "lodash"
import { getFlow } from "./flow_factory";

const events = ["onClick","onSelect"]

export class ChatModel {

    constructor(card,controller) {
        this.card = card
        this.controller = controller
        this.flow  = getFlow(card?.flow)
    }

    applyCtx(obj,ctx) {
        const retObj = _.mapValues(obj,(val,key) => {
            let ret = val
            if (_.isFunction(val) && !_.includes(events,key))
                try {
                    ret = val(ctx)
                }
                catch (e) {
                    console.error(e)
                }
            return ret
        })
        return retObj
    }

    getInitialCtx() {
        const ctx = { card: this.card, controller: this.controller }
        const flowTopic = _.first(this.flow?.topics)
        const messages = _.map(flowTopic?.steps,step => this.applyCtx(step,ctx))
        const next = _.map(this.card?.next,step => this.applyCtx(step,ctx))
        // const cardTopicSalesMessages = getMessagesForTopics(ctx,this.card?.topics)
        // if (cardTopicSalesMessages)
        //     messages.concat(_.map(cardTopicSalesMessages,msg => ({ type: "agent", "text": msg.text })))

        return {
            card: this.card,
            messages,
            next,
            // messages: [
            //     { type: "agent", text: "love is king 👑" }
            // ]
        }
    }

    setCtx(ctx, setCtx) {
        this.ctx = ctx
        this.setCtx = setCtx
    }

    push(messages) {
        if (!_.isArray(messages))
            messages = [messages]
        this.setCtx(orgCtx => {
            return {
                ...orgCtx,
                messages: [
                    ...orgCtx?.messages,
                    ...messages,
                ]
            }
        })
    }

    setTopic(topic) {
        const topics = _.keyBy(this.flow?.topics,"topic")
        const flowTopic = _.get(topics,topic)
        if (! flowTopic)
            return

        const messages = _.map(flowTopic?.steps,step => this.applyCtx(step,this.ctx))

        this.setCtx(orgCtx => {
            return {
                ...orgCtx,
                messages: [
                    ...orgCtx?.messages,
                    ...messages,
                ]
            }
        })
    }

    getMessages() {
        return this.ctx.messages
    }

    getNext() {
        return this.ctx.next
    }

    resetChat() {
        this.setCtx(orgCtx => {
            return {
                ...orgCtx,
                messages: []
            }
        })
    }
}

export function startChat(card) {
    const flow = getFlow(card.flow)

    var ctx = {
        card,
        flow,
        meaasges: []
    }
    return ctx
}

function getRandomInt(max) {
    return Math.floor(Math.random() * max);
}

export function choosePrompt(ctx, prompts) {
    if (_.isString(prompts))
        return prompts

    if (_.isArray(prompts)) {
        const max = _.size(prompts)
        const i = getRandomInt(max)
        return prompts[i]
    }

    return "Please select from the below:"
}

export function getMessagesForTopics(ctx, cardTopics) {
    const orgTopics = ctx?.controller?.topics?.value
    console.log("orgTopics",orgTopics)
    const relevantTopics = orgTopics && _.map(cardTopics,t => orgTopics[t])

    const messages = _.map(relevantTopics,topic => _.first(topic?.messages) )
    // return Math.floor(Math.random() * max);
    return messages
}
