
import { ColorPickerOutlineDimensions } from "@emotion-icons/evaicons-outline/ColorPickerOutline"
import MDBox from "components/MDBox"
import { Card, CardMedia, CardContent, Typography } from "@mui/material";
import _ from "lodash"
import "./ProductsView.css"
import SectionFrame from "wellisco/workbooks/sections/SectionFrame"
import { split_to_records } from "wellisco/workbooks/utils/tableUtils";

export default function ProductsView({ section, path, goTo }) {

    const table = section?.selected_data || section?.data
    const personas = split_to_records(table)
    // const personas = _.map(records, row => ({
    //     id: row["id"],
    //     name: row["name"],
    //     summary: row["summary"],
    // }))

    function onProductSelect(product) {
        console.log("product clicked", product)
        path = section._entity
        goTo({ path, row: product.id })
    }

    return <SectionFrame className="wl_personas_view">
        <table>
            {_.map(personas, persona => <tr
                // onClick={e => onProductSelect(prod)}
            >
                {/* <td>{prod.id}</td> */}
                <td>
                    <img src={`https://app.wellisco.com/images/${persona.image}`} 
                        className="wl_persona_img"
                    ></img>
                </td>
                <td style={{paddingLeft: 10}}>{persona.name}
                    <div style={{ fontSize: "small" }}>{persona.summary}</div>
                </td>
            </tr>)}
        </table>

        {/* <table>
            {_.map(personas, persona => <Card
                sx={{ width: 260, margin: "5px" }}
            // onClick={e => onProductSelect(persona)}
            >
                <CardMedia
                    media="picture"
                    // alt="Contemplative Reptile"
                    image={`https://app.wellisco.com/images/${persona.image}`}
                    // title="Contemplative Reptile"
                    sx={{
                        // position: "absolute",
                        // top: 0,
                        // right: 0,
                        margin: 0,
                        marginLeft: "auto",
                        marginRight: "auto",
                        height: 200,
                        width: 200,
                        // margin: 0,
                        // filter: "grayscale(80%) blur(5px)",
                        // filter: "sepia(100%)",
                    }}
                />
                <CardContent sx={{ textAlign: "center" }}>
                    {persona.name}
                    <Typography fontSize="small">
                        {persona.summary}
                    </Typography>
                </CardContent>
            </Card>)}
        </table> */}
    </SectionFrame>
}
