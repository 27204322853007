import { Avatar } from "@mui/material";
import { deepOrange, grey } from "@mui/material/colors";
import useClasses from "wellisco/utils/useClasses";

const styles = theme => ({
  messageRow: {
    display: "flex",
    width: "100%",
  },
  messageRowRight: {
    display: "flex",
    justifyContent: "flex-end"
  },
  messageLeft: {
    position: "relative",
    marginLeft: "20px",
    marginRight: "20px",
    marginBottom: "10px",
    padding: "10px",
    backgroundColor: "#DDDB",
    maxWidth: "100%",
    // width: "100%",
    flexGrow: 1,
    //height: "50px",
    textAlign: "left",
    font: "400 .9em 'Open Sans', sans-serif",
    border: "0px solid #97C6E3",
    borderRadius: "10px",
    "&:after": {
      content: "''",
      position: "absolute",
      width: "0",
      height: "0",
      borderTop: "15px solid #DDDB",
      borderLeft: "15px solid transparent",
      borderRight: "15px solid transparent",
      top: "0",
      left: "-15px"
    },
    "&:before": {
      content: "''",
      position: "absolute",
      width: "0",
      height: "0",
      borderTop: "17px solid transparent",
      borderLeft: "16px solid transparent",
      borderRight: "16px solid transparent",

      top: "-1px",
      left: "-17px"
    }
  },
  messageRight: {
    position: "relative",
    marginRight: "20px",
    marginBottom: "10px",
    padding: "10px",
    backgroundColor: "#f8e896",
    width: "60%",
    //height: "50px",
    textAlign: "left",
    font: "400 .9em 'Open Sans', sans-serif",
    border: "0px solid #dfd087",
    borderRadius: "10px",
    "&:after": {
      content: "''",
      position: "absolute",
      width: "0",
      height: "0",
      borderTop: "15px solid #f8e896",
      borderLeft: "15px solid transparent",
      borderRight: "15px solid transparent",
      top: "0",
      right: "-15px"
    },
    "&:before": {
      content: "''",
      position: "absolute",
      width: "0",
      height: "0",
      borderTop: "17px solid transparent",
      borderLeft: "16px solid transparent",
      borderRight: "16px solid transparent",
      top: "-1px",
      right: "-17px",
    }
  },

  messageContent: {
    padding: 0,
    margin: 0,
    paddingRight: 5,
    backgroundColor: "red",
  },
  messageTimeStampRight: {
    position: "absolute",
    fontSize: ".85em",
    fontWeight: "300",
    marginTop: "10px",
    bottom: "-3px",
    right: "5px"
  },

  orange: {
    color: theme.palette.getContrastText(grey[500]),
    backgroundColor: grey[500],
    width: theme.spacing(4),
    height: theme.spacing(4)
  },
  avatarNothing: {
    color: "transparent",
    backgroundColor: "transparent",
    width: theme.spacing(4),
    height: theme.spacing(4)
  },
  displayName: {
    marginLeft: "20px"
  }
})


export const MessageLeft = (props) => {
  const message = props.message ? props.message : "no message";
  // const timestamp = props.timestamp ? props.timestamp : "";
  const photoURL = props.photoURL ? props.photoURL : "dummy.js";
  const displayName = props.displayName ? props.displayName : "";
  const { avatarDisp } = props
  const {timestamp, children,avatarIcon: AvatarIcon } = props
  const classes = useClasses(styles);

  return (
    <>
      <div className={classes.messageRow}>
       {/* { avatarDisp && AvatarIcon && <Avatar
          alt={displayName}
          className={classes.orange}
          // src={photoURL}
        ><AvatarIcon /></Avatar>} */}
        {AvatarIcon && <AvatarIcon fontSize="large" />}
        <div style={ {flexGrow:1} }>
          {avatarDisp && <div className={classes.displayName}>{displayName}</div>}
          <div className={classes.messageLeft}>
            <div >
              {children ? children : <p className={classes.messageContent}>{message}</p> }
            </div>
            {timestamp && <div className={classes.messageTimeStampRight}>{timestamp}</div>}
          </div>
        </div>
      </div>
    </>
  );
};

export const MessageRight = (props) => {
  const classes = useClasses(styles);
  const message = props.message ? props.message : "no message";
  // const timestamp = props.timestamp ? props.timestamp : "";
  const {timestamp, children} = props
  return (
    <div className={classes.messageRowRight}>
      <div className={classes.messageRight}>
        { children ? children : <p className={classes.messageContent}>{message}</p> }
        { timestamp && <div className={classes.messageTimeStampRight}>{timestamp}</div>}
      </div>
    </div>
  );
};
