import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import Plot from 'react-plotly.js';

import testData from './BollingerTestData'
import chroma from 'chroma-js';

const StockAnalysisChart = ({ records, showDetails, indicator, lines, height, width, style }) => {
    showDetails = showDetails == true
    let columns = ["date", "open", "close", "low", "high"]
    if (indicator) {
        records = _.filter(records, r => r[indicator.field])
        columns.push(indicator.field)
    }
    const stockData = _.reduce(columns, (obj, key) => {
        obj[key] = _.map(records, r => r[key])
        return obj
    }, {})

    const [data, setData] = useState([]);
    const [layout, setLayout] = useState({});

    const dimColor = color => showDetails ? color : chroma(color).set('hsl.s', 0).hex()

    useEffect(() => {
        const { data, layout } = getData();
        setData(data);
        setLayout(layout);
    }, []);

    const getData = () => {
        const trace1 = {
            x: stockData.date,
            close: stockData.close,
            decreasing: { fillcolor: dimColor('#CC6666'), line: { color: dimColor('#CC6666') } },
            high: stockData.high,
            increasing: { fillcolor: dimColor('#66CC66'), line: { color: dimColor('#66CC66') } },
            line: { color: dimColor('#0000FF') },
            low: stockData.low,
            open: stockData.open,
            type: 'candlestick',
            xaxis: 'x',
            yaxis: 'y',
            hoverinfo: showDetails || 'skip',
        };
        let data = [trace1]

        let layout = {
            dragmode: false,
            // dragmode: 'zoom',
            height: height || "100%",
            width: width || "100%",
            // margin: {
            //     r: 0,
            //     t: 25,
            //     b: 40,
            //     l: ,
            // },
            margin: {
                r: 0,
                t: 0,
                b: 0,
                l: 0,
            },
            showlegend: false,
            xaxis: {
                autorange: true,
                domain: [0, 1],
                range: [stockData.date[0], stockData.date[stockData.date.length - 1]],
                rangeslider: { visible: false, range: [stockData.date[stockData.date.length - 30], stockData.date[stockData.date.length - 1]] },
                title: 'Date',
                type: 'date',
                visible: false,
            },
            yaxis: {
                autorange: true,
                domain: [0, 1],
                range: [Math.min(...stockData.low) * 0.9, Math.max(...stockData.high) * 1.1],
                type: 'linear',
                visible: showDetails,
                showticklabels: showDetails,
                // automargin: true,
                margin: 0,
                showgrid: false,
            },
            animate: true,
            animation_duration: 1000,
        };


        if (indicator) {
            const markerSizes = new Array(stockData.date.length).fill(8)
            markerSizes[stockData.date.length-1] = 20
            const trace2 = {
                x: stockData.date,
                y: stockData[indicator.field],
                line: { color: '#FFA07A' },
                name: indicator.name,
                type: 'scatter',
                xaxis: 'x',
                yaxis: 'y2',
                hoverinfo: showDetails || 'skip',
                marker: {
                    size: markerSizes
                }
            };
            data = [...data,trace2]

            const indicatorRange = _.max(_.map(stockData[indicator.field], v => Math.abs(stockData[indicator.field])))
            layout = {
                ...layout,
                yaxis2: {
                    // autorange: true,
                    domain: [0, 1],
                    range: [indicatorRange * -1.1, indicatorRange * 1.1],
                    type: 'linear',
                    visible: showDetails,
                    showticklabels: showDetails,
                    // automargin: true,
                    overlaying: 'y',
                    margin: 0,
                    showgrid: false,
                },
                xaxis2: { visible: false },
            }
        }

        _.forEach(lines,line => {
            console.log(line)
            const trace = {
                type: 'scatter',
                mode: 'lines',
                connectgaps: true,
                line: { color: chroma('#FF0000').alpha(0.25 + (line.alpha / 2)).hex()},
                x: stockData.date,
                y: _.map(stockData.date, (d,i) => {
                    if (i == 0 || i == stockData.date.length -1 )
                        return line.intercept + i * line.slope
                    return null
                })
             }
            data = [...data,trace]
        })

        return { data, layout };
    };

    // const chartComp = useMemo(() => <Plot
    const chartComp =  <Plot

        data={data}
        layout={layout}
        config={{ responsive: true, displayModeBar: false, }}
        useResizeHandler={true}
        // sx={{ width: width || '100%', height: height || "100%" }}
        // sx={{ width: 360, height: 180 }}
        style={style}
    />
    // ,[records])

    return chartComp
};

export default StockAnalysisChart