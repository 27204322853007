
import { useEffect, useRef } from 'react';
import _ from "lodash"

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import "./TableSection.css"
import SectionFrame from "./SectionFrame"
import MDBox from 'components/MDBox';
import WlDataTable from '../components/WlDataTable'

export default function TableSection({ section, progress, goTo }) {
    const gridRef = useRef(null);
    const name = section?._name
    const data = section?.selected_data || section?.data
    // const columns = _.map(data?.columns, col => col.toString())
    const columns = _.map(data?.columns, col => col.toString())
    const columnDefs = (_.map(columns, col => {
        const info = section._cols[col] || {}
        return {
            field: col, editable: true, cellDataType: "text",
            autoHeight: true,
            cellStyle: { 'white-space': 'normal', lineHeight: 1.5 },
            felx: 1,
            ...info
        }
    }))
    // const valuesToDict = (values) => _.map(data?.columns,(col,i) => ({ [col]: values[i] }))
    const rowData = (_.map(data?.data, values => _.zipObject(columns, values)))

    // Get the result from the chain

    const getRowHeight = (params) => {
        const maxLength = _.max(_.map(params.data, value => String(value).length))
        return Math.max(25, (maxLength / 5) * 10);
    };

    function onCellClick(path,row,col) {
        const ctx = {row,col}
        // const ctx = { id: row[0], col, val: row[col], row }
        // alert(JSON.stringify(ctx))
        path = section._entity
        goTo({ row, col })
    }
    // useEffect(() => {
    //     gridRef.current?.api?.sizeColumnsToFit?.()
    // }, [progress])
    // return <div style={{color:"white"}}>Love</div>
    return (<SectionFrame section={section}>
        <MDBox sx={{ width: "100%", overflow: "auto" }}>
        {/* <MDBox>{JSON.stringify(section?.data)}</MDBox> */}
        {/* <MDBox>{JSON.stringify(section?.selected_data)}</MDBox> */}
            <WlDataTable path={section._path} table={data} onTableClick={onCellClick} />
        </MDBox>
        {/* <div className="ag-theme-alpine-dark" style={{ height: 500 }}>
            <AgGridReact
                style={{ height: "500px" }}
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                // domLayout='autoHeight'
                // getRowHeight={getRowHeight}
                onGridReady={() => gridRef.current.api.sizeColumnsToFit()}
            >
            </AgGridReact>
        </div> */}
        {/* <MDBox mt={1}>{_.map(section?._actions, action => {

            return <MDButton color="dark"
                onClick={() => runAction(action)}
            >{action.button_text}</MDButton>
        })}</MDBox> */}
        {/* <MDBox>{JSON.stringify(section.data)}</MDBox> */}
    </SectionFrame>
    );
}
