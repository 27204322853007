
import { ColorPickerOutlineDimensions } from "@emotion-icons/evaicons-outline/ColorPickerOutline"
import MDBox from "components/MDBox"
import { Card, CardMedia, CardContent, Typography, Grid } from "@mui/material";
import _ from "lodash"
import "./ProductsView.css"
import SectionFrame from "wellisco/workbooks/sections/SectionFrame"
import { split_to_records } from "wellisco/workbooks/utils/tableUtils";
import { PersonaCard, ProductInsights, GoalInsights, ChallangesInsights } from "wellisco/layouts/insights/Insights";
import { WLTitle } from "wellisco/components/WLComps";
import WLDynamicForm from "wellisco/components/WLDynamicForm";

export default function WellnessProfileView({ workbook, section, path, goTo }) {

    const table = section?.selected_data || section?.data
    const personas = split_to_records(section?.personas?.data)
    const profiles = split_to_records(section?.profiles?.data)
    // const products = split_to_records(section?.products?.data)
    // const goals = split_to_records(section?.goals?.data)
    // const challanges = split_to_records(section?.challanges?.data)
    const { selection } = workbook
    const persona = selection?.persona && (_.find(personas, persona => persona.id == selection.persona)) || _.first(personas)
    const profile = persona && _.find(profiles, profile => profile.persona_id == persona.id)

    return <SectionFrame className="wl_white_section">
        <Grid container lg={12}>
            <Grid item lg="3" >
                <PersonaCard persona={persona} workbook={workbook}/>
            </Grid>
            <Grid item lg={5} display={"flex"} flexDirection={"column"} pr={1}>
                {/* {JSON.stringify(profile)} */}
                <h2>{selection.persona}</h2>
                <WLDynamicForm data={persona} />
                <WLDynamicForm data={profile} />
            </Grid>
            {/* <Grid item lg={5} display={"flex"} flexDirection={"column"} pr={1}>

                <WLTitle title="Products"
                // onClick={() => navigate("/insights/products")}
                />
                <ProductInsights products={products} />
            </Grid>
            <Grid item lg={4} display={"flex"} flexDirection={"column"} pr={1}>
                <WLTitle title="Challanges"
                // onClick={() => navigate("/insights/challanges")}
                />
                <ChallangesInsights challanges={challanges} />
                <WLTitle title="Goals"
                // onClick={() => navigate("/insights/goals")}
                />
                <GoalInsights goals={goals} />
            </Grid> */}
        </Grid>
    </SectionFrame>
}
