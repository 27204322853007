// import NewsCard from "wellisco/cards/NewsCard"
// import VideoCard from "wellisco/cards/VideoCard"
import BlockPolarAreatChart from "wellisco/blocks/BlockPolarAreatChart"
import BlockPlotly from "wellisco/blocks/BlockPlotly"
import MDBox from "components/MDBox"
import MDTypography from "components/MDTypography"
import MDButton from "components/MDButton"
import { Card } from "@mui/material"
import QuizCard from "../QuizCard"
import SkeletonCard from "../SkeletonCard"
import BollingerBandsChart from "wellisco/blocks/Bollinger"
import { CoverCard, NewsCard, RotationCard, IndicatorCard, VideoCard } from "../FullImageCard";
import GaugeChart from "wellisco/blocks/GaugeChart"

export default function DynamicDashboardCard(props) {
    const { data } = props
    if (!data) return <></>

    return <div {...props} style={{ width: 360 }}>
        {(data?.insightType == "news") &&
            <NewsCard insight={data} />

            // <NewsCard
            //     image={data.image}
            //     label="project #2"
            //     title="modern"
            //     description={data.title}
            //     lang={data.lang}
            //     action={{
            //         type: "internal",
            //         route: "/pages/profile/profile-overview",
            //         color: "info",
            //         label: "view project",
            //     }}
            //     authors={[
            //     ]}
            // />
        }
        {(data?.insightType == "youtube") &&
            <VideoCard insight={data} />

            // <VideoCard
            //     image={data.image}
            //     label="project #2"
            //     title="modern"
            //     description={data.title}
            //     lang={data.lang}
            //     videoId={data.videoId}
            //     action={{
            //         type: "internal",
            //         route: "/pages/profile/profile-overview",
            //         color: "info",
            //         label: "view project",
            //     }}
            //     authors={[
            //     ]}
            // />
        }
        {(data?.insightType == "polar") &&
            // <BlockPolarAreatChart />
            <BlockPlotly />
        }
        {(data?.insightType == "section") &&
            // <BlockPolarAreatChart />
            <Card classes={{
                root: "wellisco-card w-section"
            }} dir={data?.dir || "ltr"}>
                <MDBox className="w-content">
                    <MDTypography className="w-title">
                        {data.title}
                    </MDTypography>
                    <MDTypography className="w-body">
                        {data.body}
                    </MDTypography>
                    <MDButton className="w-action-button">
                        {data.actionText}
                    </MDButton>
                </MDBox>
            </Card>
        }
        {(data?.insightType == "skeleton") &&
            <SkeletonCard />
        }
        {(data?.insightType == "quiz") &&
            <QuizCard card={data} />
        }
        {(data?.insightType == "indicator") &&
            // <Card classes={{
            //     root: "wellisco-card w-indicator"
            // }} dir={data?.dir || "ltr"}>

            //     <MDTypography className="w-title">
            //         {data.title}
            //     </MDTypography>
            //     <div className="w-indicator">
            //         <BollingerBandsChart />
            //     </div>
            // </Card>
            <IndicatorCard insight={data}>
                <BollingerBandsChart />
            </IndicatorCard>
        }
        {(data?.insightType == "gauge") &&
            <IndicatorCard insight={data}>
                <GaugeChart {...data} width={250} height={170} />
            </IndicatorCard>
        }
        {(data?.insightType == "education") &&
            <CoverCard insight={data} />
        }
        {(data?.insightType == "rotation") &&
            <RotationCard insight={data} />
        }
    </div>
}
