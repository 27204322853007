
import { ColorPickerOutlineDimensions } from "@emotion-icons/evaicons-outline/ColorPickerOutline"
import MDBox from "components/MDBox"
import _ from "lodash"
import "./ProductsView.css"
import SectionFrame from "wellisco/workbooks/sections/SectionFrame"

export default function ProductsView({ section, path, goTo }) {

  const table = section?.selected_data || section?.data
  const products = _.map(table?.data, row => ({
    id: row[0],
    name: row[1],
    description: row[2],
  }))

  function onProductSelect(product) {
    console.log("product clicked", product)
    path = section._entity
    goTo({ path, params: { row: product.id } })
  }

  return <SectionFrame className="wl_products_view">
    <table>
      {_.map(products, prod => <tr
        onClick={e => onProductSelect(prod)}
      >
        {/* <td>{prod.id}</td> */}
        <td>
          <img src={`/images/products1/${prod.id}_1.png`} style={{ width: 120 }}></img>
        </td>
        <td>{prod.name}
          <div style={{ fontSize: "small" }}>{prod.description}</div>
        </td>
      </tr>)}
    </table>
  </SectionFrame>
}