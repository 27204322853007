// --- Material Ui Imports --- //
import { alpha } from "@mui/material";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import rgba from "assets/theme/functions/rgba";
import { Child } from "emotion-icons/fa-solid";
import useClasses from "wellisco/utils/useClasses";

const styles = theme => ({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
    },
    /**
     * Max Card with for demo
     * same values used in Material-Ui Card Demos
     */
    card: {
        maxWidth: 360,
        height: 240,
    },

    /**
     * Applied to Orginal Card demo
     * Same vale used in Material-ui Card Demos
     */
    media: {
        height: 240
    },

    /**
     * Demo stlying to inclrease text visibility
     * May verry on implementation
     */
    fiCardContent: {
        color: "#ffffff",
        backgroundColor: "rgba(0,0,0,.24)"
    },
    fiCardContentTextSecondary: {
        color: "rgba(255,255,255,0.78)"
    }
});

export function CoverCard({ insight }) {
    const classes = useClasses(styles)
    const direction = (insight.language == "he") ? "rtl" : "ltr"
    return <Card sx={{
        maxWidth: 360,
        height: 240,
        direction
    }}>
        <CardActionArea sx={{
            position: "relative",
            padding: 0,
            margin: 0,
            height: "100%",
        }}>
            <CardMedia
                media="picture"
                // alt="Contemplative Reptile"
                image={insight.image}
                // title="Contemplative Reptile"
                sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    height: 240,
                    width: 360,
                    margin: 0,
                    // filter: "grayscale(80%) blur(5px)",
                    // filter: "sepia(100%)",
                }}
            />
            <CardContent sx={{
                position: "absolute",
                // top: 0,
                bottom: 15,
                left: 15,
                right: 15,
                // height: 60,
                backgroundColor: "#000000AA",
                borderRadius: 4,
                padding: "10px",
                margin: 0,
                textAlign: "center",
            }}>
                <Typography gutterBottom variant="h5" component="h2" sx={{
                    color: "#FFFFFF"
                }}>
                    {insight.title}
                </Typography>
                <Typography
                    variant="body2"
                    component="p"
                    sx={{ color: "#FFF" }}
                >
                    {insight.subTitle}
                </Typography>
            </CardContent>
        </CardActionArea>
    </Card>
}

export function NewsCard({ insight }) {
    const classes = useClasses(styles)
    const direction = (insight.language == "he") ? "rtl" : "ltr"
    return <Card sx={{
        maxWidth: 360,
        height: 240,
        direction,
    }}>
        <CardActionArea sx={{
            position: "relative",
            padding: 0,
            margin: 0,
            height: "100%",
        }}>
            <CardMedia
                media="picture"
                // alt="Contemplative Reptile"
                image={insight.image}
                // title="Contemplative Reptile"
                sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    height: 240,
                    width: 360,
                    margin: 0,
                    // filter: "grayscale(80%) blur(5px)",
                    // filter: "sepia(100%)",
                }}
            />

            <CardContent sx={{
                position: "absolute",
                // top: 0,
                bottom: 15,
                left: 15,
                right: 15,
                // height: 60,
                backgroundColor: "#000000D0",
                borderRadius: 4,
                padding: "10px",
                margin: 0,
                // textAlign: "center",
            }}>
                <Typography gutterBottom variant="body" sx={{
                    color: "#FFFFFF",
                    fontSize: 18,
                    display: "-webkit-box",
                    'WebkitBoxOrient': "vertical",
                    "WebkitLineClamp": "2", /* start showing ellipsis when 3rd line is reached */
                    "whiteSpace": "pre-wrap", /* let the text wrap preserving spaces */
                    overflow: "hidden",
                    margin: 0
                }}>
                    {insight.title}
                </Typography>
                {/* <Typography
                    variant="body2"
                    component="p"
                    sx={{ color: "#FFF" }}
                >
                    {insight.subTitle}
                </Typography> */}
            </CardContent>
        </CardActionArea>
    </Card>
}

export function VideoCard({ insight }) {
    const classes = useClasses(styles)
    const direction = (insight.language == "he") ? "rtl" : "ltr"
    return <Card sx={{
        maxWidth: 360,
        height: 240,
        direction,
    }}>
        <CardActionArea sx={{
            position: "relative",
            padding: 0,
            margin: 0,
            height: "100%",
        }}>
            <CardMedia
                media="picture"
                // alt="Contemplative Reptile"
                image={insight.image}
                // title="Contemplative Reptile"
                sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    height: 240,
                    width: 360,
                    margin: 0,
                    // filter: "grayscale(80%) blur(5px)",
                    // filter: "sepia(100%)",
                }}
            />

            <img src="/images/youtube-play-button.svg"
                // className={classes.videoPlayButton}
                style={{
                    width: 64,
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                    cursor: "pointer",
                }}
            />

            <CardContent sx={{
                position: "absolute",
                // top: 0,
                bottom: 15,
                left: 15,
                right: 15,
                // height: 60,
                backgroundColor: "#000000D0",
                borderRadius: 4,
                padding: "10px",
                margin: 0,
                // textAlign: "center",
            }}>
                <Typography gutterBottom variant="body" sx={{
                    color: "#FFFFFF",
                    fontSize: 18,
                    display: "-webkit-box",
                    'WebkitBoxOrient': "vertical",
                    "WebkitLineClamp": "2", /* start showing ellipsis when 3rd line is reached */
                    "whiteSpace": "pre-wrap", /* let the text wrap preserving spaces */
                    overflow: "hidden",
                    margin: 0
                }}>
                    {insight.title}
                </Typography>
                {/* <Typography
                    variant="body2"
                    component="p"
                    sx={{ color: "#FFF" }}
                >
                    {insight.subTitle}
                </Typography> */}
            </CardContent>
        </CardActionArea>
    </Card>
}

export function RotationCard({ insight }) {
    const classes = useClasses(styles)
    return <Card sx={{
        maxWidth: 360,
        height: 240,
    }}>
        <CardActionArea sx={{
            position: "relative",
            padding: 0,
            margin: 0,
            height: "100%",
        }}>
            <CardMedia
                media="picture"
                // alt="Contemplative Reptile"
                image={insight.image}
                // title="Contemplative Reptile"
                sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    height: 240,
                    width: 360,
                    margin: 0,
                    // filter: "grayscale(80%) blur(5px)",
                    // filter: "sepia(100%)",
                }}
            />
            <CardContent sx={{
                position: "absolute",
                // top: 0,
                bottom: "5%",
                left: "5%",
                right: "55%",
                // height: 60,
                backgroundColor: "#000000AA",
                borderRadius: 4,
                padding: "10px",
                margin: 0,
                textAlign: "center",
            }}>
                <Typography gutterBottom variant="h6" component="h2" sx={{
                    color: "#FFFFFF"
                }}>
                    {insight.title}
                </Typography>
                <Typography
                    variant="body2"
                    component="p"
                    sx={{ color: "#FFF" }}
                >
                    {insight.upText}
                </Typography>
            </CardContent>
            <CardContent sx={{
                position: "absolute",
                top: "5%",
                bottom: "75%",
                left: "55%",
                right: "5%",
                // height: 60,
                backgroundColor: "#000000AA",
                borderRadius: 4,
                padding: "10px",
                margin: 0,
                textAlign: "center",
            }}>
                <Typography gutterBottom variant="h6" component="h2" sx={{
                    color: "#FFFFFF"
                }}>
                    {insight.downText}
                </Typography>
                <Typography
                    variant="body2"
                    component="p"
                    sx={{ color: "#FFF" }}
                >
                    {insight.subTitle}
                </Typography>
            </CardContent>

        </CardActionArea>
    </Card>
}

export function IndicatorCard({ children, insight }) {
    const classes = useClasses(styles)
    return <Card sx={{
        maxWidth: 360,
        height: 240,
    }}>
        <CardActionArea sx={{
            position: "relative",
            padding: 0,
            margin: 0,
            height: "100%",
        }}>
            <CardMedia
                media="picture"
                // alt="Contemplative Reptile"
                image={insight.image}
                // title="Contemplative Reptile"
                sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    height: 240,
                    width: 360,
                    margin: 0,
                    bottom: 0,
                    filter: "blur(1px)",
                    overflow: "hidden",
                    // filter: "sepia(100%)",
                }}
            />

            <CardContent sx={{
                position: "absolute",
                top: 0, bottom: 0, right: 0, left: 0,
                backgroundColor: "#FFF",
                backdropFilter: "blur(10px)",
                opacity: 0.5,
            }}
            />

            <CardContent sx={{
                position: "absolute",
                top: 10,
                // bottom: 15,
                left: 15,
                right: 15,
                // height: 60,
                backgroundColor: "#000000D0",
                borderRadius: 4,
                padding: "10px",
                margin: 0,
                textAlign: "center",
                margin: 0,
            }}>
                <Typography gutterBottom variant="h5" component="h2" sx={{
                    color: "#FFFFFF",
                    fontSize: 18,
                    margin: 0,
                }}>
                    {insight.title}
                </Typography>
                {/* <Typography
                    variant="body2"
                    component="p"
                    sx={{ color: "#FFF" }}
                >
                    {insight.subTitle}
                </Typography> */}
            </CardContent>

            <CardContent sx={{
                position: "absolute",
                top: 65,
                bottom: 0,
                left: 15,
                right: 15,
                // height: 60,
                // backgroundColor: "#000000AA",
                borderRadius: 4,
                padding: "10px",
                margin: 0,
                textAlign: "center",
            }}>
                {children}
            </CardContent>

        </CardActionArea>
    </Card>
}


export const FiCard = withStyles({
    root: {
        position: "relative"
    }
})(Card);

export const FiCardActionArea = withStyles({
    root: {
        position: "relative"
    }
})(CardActionArea);

export const FiCardActions = withStyles({
    root: {
        position: "relative"
    }
})(CardActions);

export const FiCardContent = withStyles({
    root: {
        position: "relative",
        backgroundColor: "transparent"
    }
})(CardContent);

export const FiCardMedia = withStyles({
    root: {
        position: "absolute",
        top: 0,
        right: 0,
        height: "100%",
        width: "100%"
    }
})(CardMedia);

// --- Exports --- //
export default {
    FiCard,
    FiCardActionArea,
    FiCardActions,
    FiCardContent,
    FiCardMedia
};
