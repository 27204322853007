/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";


import { useTranslation } from "react-i18next";
import MDTypography from "components/MDTypography";

import { useLocation, useSearchParams } from "react-router-dom";
import _ from "lodash"
import { useMaterialUIController, doAsync } from "context";

import NewsCard from "wellisco/cards/NewsCard";
import VideoCrad from "wellisco/cards/VideoCard";

import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";
import { useEffect, useState } from "react";
import { loadPulseInsights } from "actions/welliscoActions";

import Swiper from 'react-id-swiper';
import SwiperCore, { Keyboard, Mousewheel } from "swiper/core";

import 'swiper/swiper.css';
import { useTheme } from "@emotion/react";
import { height } from "@mui/system";
import BlockPolarAreatChart from "wellisco/blocks/BlockPolarAreatChart";
import DynamicDashboardCard from "wellisco/cards/DynamicDashboardCard";
import StoryDialog from "./StoryDialog";
import { GlobalStyles } from "@mui/material";
import { cards as sampleCards } from "data/sampleCards"

SwiperCore.use([Keyboard, Mousewheel]);

const swiperParams = {
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
    },
    spaceBetween: 30,
    slidesPerView: 'auto',
    direction: "horizontal",
    mousewheel: {
        eventsTarget: "container",
        forceToAxis: true,
        thresholdDelta: 5,
    },
    // pagination: {
    //   el: '.swiper-pagination',
    //   clickable: true,
    // },
    // centeredSlides: true,
    // getSwiper: s => {
    //   console.log("get swiper", s)
    //   updateSwiper(s)
    // }
}

function WidgetPanel(props) {
    const { t } = useTranslation()
    const theme = useTheme()
    const { palette, boxShadows, functions, transitions, breakpoints, borders } = theme;
    const { gradients } = palette;
    const { linearGradient } = functions;
    const route = useLocation().pathname.split("/").slice(1);
    let [searchParams] = useSearchParams();
    const topic = searchParams.get("topic")
    const [controller, dispatch] = useMaterialUIController();
    const [story, setStory] = useState()
    const handleDialogClose = () => {
        setStory(null);
    };

    const { userConfig, pulseInsights } = controller
    useEffect(() => {
        if (!pulseInsights?.value) {
            doAsync(dispatch, "pulseInsights", loadPulseInsights)
        }
    }, [])

    // const graphData = _.first(pulseInsights?.value)?.graphData
    // console.log("graphData", graphData)

    const topicInsights = _.find(pulseInsights.value,item => item.topic == topic) || _.first(pulseInsights.value)

    const topicSamples = _.filter(sampleCards,card => _.includes(card.topics,topic))
    



    var { cards } = props
    if (topicInsights?.insights)
        cards = _.compact(_.flatten(_.zip(cards, topicSamples, topicInsights?.insights)))
        // cards = _.concat(cards, topicSamples, topicInsights?.insights)
    else
        cards = [{ insightType: "skeleton" },{ insightType: "skeleton" },{ insightType: "skeleton" },{ insightType: "skeleton" },{ insightType: "skeleton" },{ insightType: "skeleton" },]

    // cards = topicInsights?.insights
    function openStory(card) {
        setStory(card)
    }

    const sendOpenStoryMessage = (e, card) => {
        e.preventDefault();
        var queryParams = new URLSearchParams(window.location.search);
        var frameId = queryParams.get('frameId');
        const message = { type: "openStory", frameId, x: e.clientX, y: e.clientY, story: card, }
        if (window && window.parent) {
            window.parent.postMessage({ message, hide: 'dbhchat', show: 'dbhchat' }, '*');
        }
    }

    return (
        <>
            {/* <GlobalStyles styles={{ div: { color: 'red' } }} /> */}
            <StoryDialog story={story} handleClose={handleDialogClose} />
            <MDBox py={0} mb={1}
                sx={{
                    maxWidth: "100%", overflow: "hidden",
                    // transform: "scale(0.8)",
                    width: "100%", height: 240,
                    // margin: 8,
                    backgroundColor: "white",
                }}
            >
                <Swiper {...swiperParams} style={{ width: "100%" }}>
                    {_.map(cards, (card, key) =>
                        // <Grid item xs={12} md={6} xl={4}>
                        // <MDBox p={1} m={0} sx={{ 
                        //     "--r": "25px",
                        //     width:360, height: 240, 
                        //     transform: "scale(0.9)",
                        //     backgroundColor: "#EEE",
                        //     borderRadius: "var(--r)",
                        // }}>
                        <DynamicDashboardCard data={card} onClick={e => sendOpenStoryMessage(e, card)} key={key} />
                        // </MDBox>
                        // </Grid>
                    )}
                </Swiper>
            </MDBox>
        </>
    )
}

export default WidgetPanel;
