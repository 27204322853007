
import _ from "lodash"
import MDBox from 'components/MDBox';

export default function SectionFrame({ section, children, className }) {
    const name = section?._title || section?._name
    className = (className || "") + " wl_section_frame"
    return (<MDBox
        className={className}>
        {children}
    </MDBox>
    );
}
