/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useMemo, Suspense } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation, useNavigate, useSearchParams } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
import CircularProgress from '@mui/material/CircularProgress';

// Material Dashboard 2 React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";
import themeWidget from "assets/theme-widget";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Material Dashboard 2 React routes
import routes from "routes";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator, doAsync } from "context";

import ApiProxy from "auth/ApiProxy";
import supabase from "components/Supabase/supabaseClient";

// Images
import brandWhite from "assets/images/wellisco-logo.png";
import brandDark from "assets/images/wellisco-logo.png";
import waitingLogo from "assets/images/wellisco-logo.png"


import "./App.css"
import MDTypography from "components/MDTypography";
import { useTranslation, Trans } from "react-i18next";
import { loadUserConfig, loadTopics } from "actions/welliscoActions";
import WidgetPanel from "wellisco/widget/panel";

import _ from "lodash"
import Story from "wellisco/widget/story";

import InsureTaxPanel from "insuretax/panel";
import FinPanel from "fin/FinPanelV3"

import bgImage from "assets/images/tunnel-background.jpg";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [userOK, setUserOK] = useState(undefined)
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();

  const taxPath = "/tax"
  const finPath = "/fin"
  const authPath = "/auth"
  const widgetPath = "/widget"
  const storyPath = "/story"
  const publicPathes = [authPath, widgetPath, storyPath]

  const isAuthPage = (pathname == authPath)

  const urlParams = new URLSearchParams(window.location.search);
  const urlCardStr = urlParams.get('card')
  var urlCardJson
  try {
    urlCardJson = urlCardStr && JSON.parse(urlCardStr)
  }
  catch (e) { }

  useEffect(async () => {
    if (_.includes(publicPathes, pathname) == false) {
      const { data: { user } } = await supabase.auth.getUser()

      // const config = await loadUserConfig()
      // const [{ data: { user } }, config] = await Promise.all([
      //   supabase.auth.getUser(),
      //   loadUserConfig()
      // ])
      // console.log("config", config)
      if (user)
        setUserOK(true)
      else {
        navigate('/auth');
      }
    }
  });

  useEffect(async () => {
    doAsync(dispatch, "userConfig", loadUserConfig)
    doAsync(dispatch, "topics", loadTopics)
  }, []);

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={`${route.route}/*`} element={
          <Suspense
            fallback={<div></div>}
          >
            {route.component}
          </Suspense>} key={route.key} />;
      }

      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return pathname == taxPath ?
    <ThemeProvider theme={themeWidget}>
      <CssBaseline />
      <InsureTaxPanel />
    </ThemeProvider>
    :
    pathname == finPath ?
      <ThemeProvider theme={themeWidget}>
        <CssBaseline />
        <MDBox sx={{ height: "100%", display: "flex", flexDirection: "column", padding: "8px" }}>
          <FinPanel />
        </MDBox>
      </ThemeProvider>
      :
      pathname == widgetPath ?
        <ThemeProvider theme={themeWidget}>
          <CssBaseline />
          <WidgetPanel />
          {/* <MDBox sx={{ textAlign: "center" }} m={1}>{searchParams.get("msg") || "Widget LOVE"}</MDBox> */}
        </ThemeProvider>
        :
        pathname == storyPath ?
          <ThemeProvider theme={themeWidget}>
            <CssBaseline />
            {/* <div>{urlCard}</div> */}
            <div style={{ display: "flex", height: "100%" }} >
              <Story card={urlCardJson} />
            </div>
            {/* <MDBox sx={{ textAlign: "center" }} m={1}>{searchParams.get("msg") || "Widget LOVE"}</MDBox> */}
          </ThemeProvider>
          :
          // direction === "rtl" ? (
          //   <CacheProvider value={rtlCache}>
          //     <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
          //       <CssBaseline />
          //       {layout === "dashboard" && (
          //         <>
          //           <Sidenav
          //             color={sidenavColor}
          //             brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
          //             brandName="Wellisco AI"
          //             routes={routes}
          //             onMouseEnter={handleOnMouseEnter}
          //             onMouseLeave={handleOnMouseLeave}
          //           />
          //           <Configurator />
          //           {configsButton}
          //         </>
          //       )}
          //       {layout === "vr" && <Configurator />}
          //       <Routes>
          //         {getRoutes(routes)}
          //         <Route path="*" element={<Navigate to="/dashboard" />} />
          //       </Routes>
          //     </ThemeProvider>
          //   </CacheProvider>
          // ) 
          // : 
          (userOK || isAuthPage) ? (
            <ThemeProvider theme={darkMode ? themeDark : theme}>
              <CssBaseline />
              {isAuthPage || userOK ?
                <>
                  {layout === "dashboard" && userOK && (
                    <>
                      <Sidenav
                        color={sidenavColor}
                        brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                        brandName="Wellisco AI"
                        routes={routes}
                        onMouseEnter={handleOnMouseEnter}
                        onMouseLeave={handleOnMouseLeave}
                      />
                      <Configurator />
                      {/* {configsButton} */}
                    </>
                  )}
                  {layout === "vr" && <Configurator />}
                  <MDBox
                    position="fixed"
                    width="100%"
                    minHeight="100vh"
                    sx={{
                      backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                        bgImage &&
                        `${linearGradient(
                          rgba(gradients.dark.main, 0.6),
                          rgba(gradients.dark.state, 0.6)
                        )}, url(${bgImage})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                  />
                  <Routes>
                    {getRoutes(routes)}
                    <Route path="/my-api/*" element={<ApiProxy />} />
                    <Route path="*" element={<Navigate to="/home" />} />
                  </Routes>
                </>
                :
                <MDBox sx={{
                  display: 'flex', height: "100vh",
                  alignItems: "center", justifyContent: "center",
                  flexDirection: "column",
                }}>
                  <img src={waitingLogo} className="App-logo" alt="logo" />
                  <MDTypography variant="h4" fontWeight="medium" color="dark" mt={1}>
                    {t("global.loading")}
                  </MDTypography>
                </MDBox>
              }
            </ThemeProvider >
          )
          : 
          <></>
}
