import PDFSection from "./PDFSection"
import TableSection from "./TableSection"
import CodeSection from "./CodeSection"
import _ from "lodash"

const comps = {
    "table": TableSection,
    "pdf": PDFSection,
}

function EmptySection(props) {
    return <div>
        <h3>Empty Section</h3>
        {/* <div>{JSON.stringify(props)}</div> */}
    </div>
}

export function getSectionComp(sec,viewKey,viewComps) {
    // const view = sec?._view
    let viewComp = viewKey && viewComps[viewKey]
    viewComp ||= comps[viewKey]
    viewComp ||= comps[sec?._type]
    viewComp ||= EmptySection
    return viewComp
}

// export function getSectionComp(sec,view,viewComps) {
//     // const view = sec?._view
//     if (sec?._views) {
//         let viewComp = view?.comp && viewComps[view?.comp]
//         if (viewComp) return viewComp

//         viewComp = sec?._type && viewComps[sec?._type]
//         if (viewComp) return viewComp

//         viewComp = sec?._type && comps[sec?._type]
//         if (viewComp) return viewComp

//         return viewComps[_.first(sec?._views)?.comp]
//     }
//     else {
//         const type = sec?._type
//         const typeComp = comps[type]
//         if (typeComp) return typeComp
//     }
//     return EmptySection
// }

// export function getSectionComp(sec,view,viewComps) {
//     // const view = sec?._view
//     if (sec?._views) {
//         let viewComp = view?.comp && viewComps[view?.comp]
//         if (viewComp) return viewComp

//         viewComp = sec?._type && viewComps[sec?._type]
//         if (viewComp) return viewComp

//         viewComp = sec?._type && comps[sec?._type]
//         if (viewComp) return viewComp

//         return viewComps[_.first(sec?._views)?.comp]
//     }
//     else {
//         const type = sec?._type
//         const typeComp = comps[type]
//         if (typeComp) return typeComp
//     }
//     return EmptySection
// }