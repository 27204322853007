/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

import { useTranslation } from "react-i18next";
import MDTypography from "components/MDTypography";

import { useLocation, useSearchParams } from "react-router-dom";
import _ from "lodash"
import { useMaterialUIController, doAsync, setAsyncValue } from "context";

import NewsCard from "wellisco/cards/NewsCard";
import VideoCrad from "wellisco/cards/VideoCard";

import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";
import { useEffect, useState } from "react";
import { getConfig } from "actions/insureTaxActions";

import Swiper from 'react-id-swiper';
import SwiperCore, { Keyboard, Mousewheel } from "swiper/core";

import 'swiper/swiper.css';
import { useTheme } from "@emotion/react";
import { height } from "@mui/system";
import BlockPolarAreatChart from "wellisco/blocks/BlockPolarAreatChart";
import DynamicDashboardCard from "wellisco/cards/DynamicDashboardCard";
import { Breadcrumbs, IconButton, Link, Typography } from "@mui/material";
import { cards as sampleCards } from "data/sampleCards"
import CachedIcon from '@mui/icons-material/Cached';
import { MsgBot, MsgUser, TaxChatInput } from "./TaxChat"

SwiperCore.use([Keyboard, Mousewheel]);

const swiperParams = {
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
    },
    spaceBetween: 30,
    slidesPerView: 'auto',
    direction: "horizontal",
    mousewheel: {
        eventsTarget: "container",
        forceToAxis: true,
        thresholdDelta: 5,
    },
    // pagination: {
    //   el: '.swiper-pagination',
    //   clickable: true,
    // },
    // centeredSlides: true,
    // getSwiper: s => {
    //   console.log("get swiper", s)
    //   updateSwiper(s)
    // }
}

function InsureTaxPanel(props) {
    const { t } = useTranslation()
    const theme = useTheme()
    const { palette, boxShadows, functions, transitions, breakpoints, borders } = theme;
    const { gradients } = palette;
    const { linearGradient } = functions;
    const route = useLocation().pathname.split("/").slice(1);
    let [searchParams] = useSearchParams();
    const [controller, dispatch] = useMaterialUIController();
    const { instureTaxConfig, exposures } = controller
    const [selected, setSelected] = useState()
    const [websocket, setWebSocket] = useState()

    useEffect(() => {
        if (!instureTaxConfig?.value) {
            doAsync(dispatch, "instureTaxConfig", getConfig)
        }
        document.title = 'InsureTax';

    }, [])

    const exposureChatUrl = `ws://127.0.0.1:1120/ws/exposure/chat/123?refresh=True`
    const exposureChatVarId = "exposureChat"
    const refreshExposuresUrl = `ws://127.0.0.1:1120/ws/exposures/123?refresh=True`
    const refreshExposuresVarId = "exposures"

    const wsConnect = (chatUrl, varId, data) => {

        const websocket = new WebSocket(chatUrl);
        websocket.onopen = (event) => {
            setWebSocket(websocket)
            if (data)
                websocket.send(JSON.stringify(data))
        }
        websocket.onerror = (event) => {
            setWebSocket(null)
        }
        websocket.onclose = (event) => {
            setWebSocket(null)
        }
        websocket.onmessage = (event) => {
            try {
                const data = JSON.parse(event.data)?.json
                if (data) {
                    setAsyncValue(dispatch, varId, data)
                    console.log(event.data)
                }
            }
            catch { }
        };
    }

    const exposureChat = controller[exposureChatVarId]?.value
    const reason = exposureChat?.Reason

    const onRowClick = (row) => {
        if (_.size(row) == 0 || !_.isObject(selected))
            return

        setSelected({ ...selected, row: row[0] })
        console.log(row)
    }

    const selection = selected
    let table = selection && instureTaxConfig?.value?.[selection.sec]?.[selection.item]
    const refreshConfs = {
        "Exposures": {
            var: "exposures"
        }
    }
    const refreshConf = selection?.item && refreshConfs[selection.item]
    const refreshVal = refreshConf && controller[refreshConf.var]?.value
    if (refreshVal) {
        table = {
            columns: _.keys(_.first(refreshVal)),
            index: [],
            data: _.map(refreshVal, row => _.values(row)),
        }
    }

    const updatedExposure = exposureChat?.["Updated Exposure"]
    if (updatedExposure) {
        const selectedRow = selection?.row && _.filter(table["data"], row => row[0] == selection?.row)[0]
        if (selectedRow) {
            _.each(updatedExposure, (v, k) => {
                const i = _.indexOf(table?.columns, k)
                if (i > 0) {
                    selectedRow[i] = v
                }
            })
        }
    }

    const gravetyIndex = _.indexOf(table?.columns, "Gravity")
    const questionIndex = _.indexOf(table?.columns, "Followup")
    if (table?.data)
        table["data"] = _.sortBy(table?.data, row => gravetyIndex && -row[gravetyIndex])
    const selectedRow = selection?.row && _.filter(table["data"], row => row[0] == selection?.row)[0]
    if (selectedRow) table = { ...table, data: [selectedRow] };

    const onRefresh = () => {
        setSelected({ ...selected, row: undefined })
        wsConnect(refreshExposuresUrl, refreshExposuresVarId)
    }
    const onExposureChatSend = (message) => {
        if (!selectedRow) return
        console.log(message)
        console.log(table)
        const data = {
            exposure: table,
            chat: [
                { actor: "virtual accountant", message: selectedRow[questionIndex] },
                { actor: "user", message },
            ]
        }
        console.log(data)
        wsConnect(exposureChatUrl, exposureChatVarId, data)
    }

    return (
        <>
            <MDBox py={0} mb={0}
                sx={{
                    maxWidth: "100%", overflow: "hidden",
                    // transform: "scale(0.8)",
                    width: "100%", height: "100%",
                    // margin: 8,
                    backgroundColor: "#222",
                    color: "white",
                }}
                color="white"
            >
                <Grid container spacing={2} pr={6} pl={6} alignContent="space-between" sx={{ height: "100%" }}>
                    <Grid item lg={12}>
                        <MDTypography variant="h2" fontWeight="medium" color="white" mt={1}>
                            InsureTax
                        </MDTypography>
                    </Grid>
                    <Grid item lg={12} sx={{ height: "100%" }}>
                        <Grid container lg={12} spacing={4} m={0} p={0} sx={{ height: "100%" }}>
                            <Grid item lg={2}>
                                {_.map(instureTaxConfig?.value, (items, sec) => <div
                                // style={{ color: (k == selected) ? "#817fff" : "white" }}
                                // onClick={e => setSelected(k)}
                                >
                                    <div>{sec}</div>
                                    <ul style={{ listStyleType: "circle", listStylePosition: "inside" }}>
                                        {_.map(items, (v, k) => {
                                            const isSelected = sec == selected?.sec && k == selected?.item
                                            return <li
                                                style={{ color: isSelected ? "#817fff" : "white" }}
                                                onClick={e => {
                                                    setSelected({ sec, item: k })
                                                    setAsyncValue(dispatch,exposureChatVarId, undefined)
                                                }}
                                            >
                                                {k}
                                                {isSelected && refreshConfs[k] && <IconButton size="small" >
                                                    <CachedIcon sx={{ color: "#999" }} onClick={() => onRefresh()} />
                                                </IconButton>}
                                            </li>
                                        })}
                                    </ul>
                                </div>)}
                            </Grid>
                            <Grid item lg={9} pb={10} sx={{ overflow: "auto", height: "100%" }}>
                                {/* {exposures?.value && JSON.stringify(exposures.value)} */}
                                {/* {JSON.stringify(selected)} */}
                                {/* {JSON.stringify(exposureChat)} */}
                                {/* {selectedRow ? */}
                                    <>
                                        {false && <MDBox mb={1}>
                                            <Breadcrumbs aria-label="breadcrumb">
                                                {selection?.item && <Link underline="hover"
                                                    style={{ color: "white" }}
                                                    onClick={e => setSelected({ ...selected, row: undefined })}
                                                >
                                                    {selection.item}
                                                </Link>}
                                                {selection?.row && <Link
                                                    underline="hover"
                                                    style={{ color: "white" }}
                                                >
                                                    {_.nth(selectedRow, 0)}:
                                                    {_.nth(selectedRow, 1)}
                                                </Link>}
                                            </Breadcrumbs>
                                        </MDBox>}
                                        {false && <table cellPadding={5} cellSpacing={0}>
                                            {_.map(table?.columns, (col, i) =>
                                                <tr>
                                                    <td
                                                        style={{ border: "1px solid white", verticalAlign: "top", padding: 5, color: "#817fff" }}
                                                    >{col}
                                                    </td>
                                                    <td
                                                        style={{ border: "1px solid white", verticalAlign: "top", padding: 5, color: "#FFF" }}
                                                    >{selectedRow[i]}
                                                    </td>
                                                </tr>)
                                            }
                                        </table>}
                                        {selectedRow && <>
                                            <MDBox mt={1} mb={4}>
                                                <MsgBot text={selectedRow[questionIndex]} />
                                                <MsgUser text={reason} />
                                                <TaxChatInput onSend={onExposureChatSend} />
                                            </MDBox>
                                        </>}
                                        {/* {instureTaxConfig?.value && JSON.stringify(table)} */}
                                    </>
                                    
                                    <>
                                        <MDBox mb={1} sx={{ color: "white" }}>
                                            <table cellPadding={5} cellSpacing={0}>
                                                <tr>
                                                    {_.map(table?.columns, col => <th
                                                        style={{ border: "1px solid white", verticalAlign: "top", padding: 5, color: "#817fff" }}
                                                    >{col}</th>)}
                                                </tr>
                                                {_.map(table?.data, row => <tr
                                                    onClick={e => onRowClick(row)}
                                                >
                                                    {_.map(row, col => <td
                                                        style={{ border: "1px solid white", verticalAlign: "top", padding: 5, color: "white" }}
                                                    >
                                                        {col}
                                                    </td>)}
                                                </tr>)}
                                            </table>
                                        </MDBox>
                                    </>
                                    {/* } */}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </MDBox >
        </>
    )
}

export default InsureTaxPanel;
