import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import Plot from 'react-plotly.js';

import testData from './BollingerTestData'
import chroma from 'chroma-js';

const GaugeChart = ({ min, max, value, indicatorName, style,width, height }) => {
    // showDetails = showDetails == true
    // let columns = ["date", "open", "close", "low", "high"]
    // if (indicator) {
    //     records = _.filter(records, r => r[indicator.field])
    //     columns.push(indicator.field)
    // }
    // const stockData = _.reduce(columns, (obj, key) => {
    //     obj[key] = _.map(records, r => r[key])
    //     return obj
    // }, {})

    const [data, setData] = useState([]);
    const [layout, setLayout] = useState({});

    // const dimColor = color => showDetails ? color : chroma(color).set('hsl.s', 0).hex()

    useEffect(() => {
        const { data, layout } = getData();
        setData(data);
        setLayout(layout);
    }, []);

    const getData = () => {
        var data = [
            {
                type: "indicator",
                value: value,
                delta: { reference: 50 },
                gauge: { axis: { visible: false, range: [min, max] } },
                domain: { row: 0, column: 0 }
            },
            // {
            //     type: "indicator",
            //     value: 120,
            //     gauge: {
            //         shape: "bullet",
            //         axis: {
            //             visible: false,
            //             range: [-200, 200]
            //         }
            //     },
            //     domain: { x: [0.1, 0.5], y: [0.15, 0.35] }
            // },
            // {
            //     type: "indicator",
            //     mode: "number+delta",
            //     value: 300,
            //     domain: { row: 0, column: 1 }
            // },
            // { type: "indicator", mode: "delta", value: 40, domain: { row: 1, column: 1 } }
        ];

        var layout = {
            width: width || 300,
            height: height || 200,
            margin: { t: 5, b: 5, l: 5, r: 5 },
            paper_bgcolor: '#FFF0',
            plot_bgcolor: '#FFF0',
            grid: { rows: 1, columns: 1, pattern: "independent" },
            template: {
                data: {
                    indicator: [
                        {
                            title: { text: indicatorName },
                            mode: "number+delta+gauge",
                            delta: { reference: 50 }
                        }
                    ]
                }
            }
        };

        return { data, layout };
    };

    // const chartComp = useMemo(() => <Plot
    const chartComp = <Plot

        data={data}
        layout={layout}
        config={{ responsive: true, displayModeBar: false, }}
        useResizeHandler={true}
        // sx={{ width: width || '100%', height: height || "100%" }}
        // sx={{ width: 360, height: 180 }}
        style={style}
    />
    // ,[records])

    return chartComp
};

export default GaugeChart