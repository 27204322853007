export default [
    // {
    //     "date": 1672704000000,
    //     "open": 130.28,
    //     "high": 130.9,
    //     "low": 124.17,
    //     "close": 125.07,
    //     "upper_band": null,
    //     "lower_band": null,
    //     "crossover_long": null,
    //     "crossover_short": null,
    //     "crossover": false
    // },
    // {
    //     "date": 1672790400000,
    //     "open": 126.89,
    //     "high": 128.6557,
    //     "low": 125.08,
    //     "close": 126.36,
    //     "upper_band": null,
    //     "lower_band": null,
    //     "crossover_long": 0.0,
    //     "crossover_short": 0.0,
    //     "crossover": false
    // },
    // {
    //     "date": 1672876800000,
    //     "open": 127.13,
    //     "high": 127.77,
    //     "low": 124.76,
    //     "close": 125.02,
    //     "upper_band": null,
    //     "lower_band": null,
    //     "crossover_long": 0.0,
    //     "crossover_short": 0.0,
    //     "crossover": false
    // },
    // {
    //     "date": 1672963200000,
    //     "open": 126.01,
    //     "high": 130.29,
    //     "low": 124.89,
    //     "close": 129.62,
    //     "upper_band": null,
    //     "lower_band": null,
    //     "crossover_long": 0.0,
    //     "crossover_short": 0.0,
    //     "crossover": false
    // },
    // {
    //     "date": 1673222400000,
    //     "open": 130.465,
    //     "high": 133.41,
    //     "low": 129.89,
    //     "close": 130.15,
    //     "upper_band": null,
    //     "lower_band": null,
    //     "crossover_long": 0.0,
    //     "crossover_short": 0.0,
    //     "crossover": false
    // },
    // {
    //     "date": 1673308800000,
    //     "open": 130.26,
    //     "high": 131.2636,
    //     "low": 128.12,
    //     "close": 130.73,
    //     "upper_band": null,
    //     "lower_band": null,
    //     "crossover_long": 0.0,
    //     "crossover_short": 0.0,
    //     "crossover": false
    // },
    // {
    //     "date": 1673395200000,
    //     "open": 131.25,
    //     "high": 133.51,
    //     "low": 130.46,
    //     "close": 133.49,
    //     "upper_band": null,
    //     "lower_band": null,
    //     "crossover_long": 0.0,
    //     "crossover_short": 0.0,
    //     "crossover": false
    // },
    // {
    //     "date": 1673481600000,
    //     "open": 133.88,
    //     "high": 134.26,
    //     "low": 131.44,
    //     "close": 133.41,
    //     "upper_band": null,
    //     "lower_band": null,
    //     "crossover_long": 0.0,
    //     "crossover_short": 0.0,
    //     "crossover": false
    // },
    // {
    //     "date": 1673568000000,
    //     "open": 132.03,
    //     "high": 134.92,
    //     "low": 131.66,
    //     "close": 134.76,
    //     "upper_band": null,
    //     "lower_band": null,
    //     "crossover_long": 0.0,
    //     "crossover_short": 0.0,
    //     "crossover": false
    // },
    // {
    //     "date": 1673913600000,
    //     "open": 134.83,
    //     "high": 137.29,
    //     "low": 134.13,
    //     "close": 135.94,
    //     "upper_band": null,
    //     "lower_band": null,
    //     "crossover_long": 0.0,
    //     "crossover_short": 0.0,
    //     "crossover": false
    // },
    // {
    //     "date": 1674000000000,
    //     "open": 136.815,
    //     "high": 138.61,
    //     "low": 135.03,
    //     "close": 135.21,
    //     "upper_band": null,
    //     "lower_band": null,
    //     "crossover_long": 0.0,
    //     "crossover_short": 0.0,
    //     "crossover": false
    // },
    // {
    //     "date": 1674086400000,
    //     "open": 134.08,
    //     "high": 136.25,
    //     "low": 133.77,
    //     "close": 135.27,
    //     "upper_band": null,
    //     "lower_band": null,
    //     "crossover_long": 0.0,
    //     "crossover_short": 0.0,
    //     "crossover": false
    // },
    // {
    //     "date": 1674172800000,
    //     "open": 135.28,
    //     "high": 138.02,
    //     "low": 134.22,
    //     "close": 137.87,
    //     "upper_band": null,
    //     "lower_band": null,
    //     "crossover_long": 0.0,
    //     "crossover_short": 0.0,
    //     "crossover": false
    // },
    // {
    //     "date": 1674432000000,
    //     "open": 138.12,
    //     "high": 143.315,
    //     "low": 137.9,
    //     "close": 141.11,
    //     "upper_band": null,
    //     "lower_band": null,
    //     "crossover_long": 0.0,
    //     "crossover_short": 0.0,
    //     "crossover": false
    // },
    // {
    //     "date": 1674518400000,
    //     "open": 140.305,
    //     "high": 143.16,
    //     "low": 140.3,
    //     "close": 142.53,
    //     "upper_band": null,
    //     "lower_band": null,
    //     "crossover_long": 0.0,
    //     "crossover_short": 0.0,
    //     "crossover": false
    // },
    // {
    //     "date": 1674604800000,
    //     "open": 140.89,
    //     "high": 142.43,
    //     "low": 138.81,
    //     "close": 141.86,
    //     "upper_band": null,
    //     "lower_band": null,
    //     "crossover_long": 0.0,
    //     "crossover_short": 0.0,
    //     "crossover": false
    // },
    // {
    //     "date": 1674691200000,
    //     "open": 143.17,
    //     "high": 144.25,
    //     "low": 141.9,
    //     "close": 143.96,
    //     "upper_band": null,
    //     "lower_band": null,
    //     "crossover_long": 0.0,
    //     "crossover_short": 0.0,
    //     "crossover": false
    // },
    // {
    //     "date": 1674777600000,
    //     "open": 143.155,
    //     "high": 147.23,
    //     "low": 143.08,
    //     "close": 145.93,
    //     "upper_band": null,
    //     "lower_band": null,
    //     "crossover_long": 0.0,
    //     "crossover_short": 0.0,
    //     "crossover": false
    // },
    // {
    //     "date": 1675036800000,
    //     "open": 144.955,
    //     "high": 145.55,
    //     "low": 142.85,
    //     "close": 143.0,
    //     "upper_band": null,
    //     "lower_band": null,
    //     "crossover_long": 0.0,
    //     "crossover_short": 0.0,
    //     "crossover": false
    // },
    // {
    //     "date": 1675123200000,
    //     "open": 142.7,
    //     "high": 144.34,
    //     "low": 142.28,
    //     "close": 144.29,
    //     "upper_band": 148.7147209308,
    //     "lower_band": 122.8432790692,
    //     "crossover_long": 1.0,
    //     "crossover_short": 1.0,
    //     "crossover": true
    // },
    // {
    //     "date": 1675209600000,
    //     "open": 143.97,
    //     "high": 146.61,
    //     "low": 141.32,
    //     "close": 145.43,
    //     "upper_band": 149.4017373634,
    //     "lower_band": 124.1922626366,
    //     "crossover_long": 0.0,
    //     "crossover_short": 0.0,
    //     "crossover": false
    // },
    // {
    //     "date": 1675296000000,
    //     "open": 148.9,
    //     "high": 151.18,
    //     "low": 148.17,
    //     "close": 150.82,
    //     "upper_band": 151.0752364973,
    //     "lower_band": 124.9647635027,
    //     "crossover_long": 0.0,
    //     "crossover_short": 0.0,
    //     "crossover": false
    // },
    // {
    //     "date": 1675382400000,
    //     "open": 148.03,
    //     "high": 157.38,
    //     "low": 147.83,
    //     "close": 154.5,
    //     "upper_band": 152.994613912,
    //     "lower_band": 125.993386088,
    //     "crossover_long": 0.0,
    //     "crossover_short": 0.0,
    //     "crossover": false
    // },
    // {
    //     "date": 1675641600000,
    //     "open": 152.575,
    //     "high": 153.1,
    //     "low": 150.78,
    //     "close": 151.73,
    //     "upper_band": 154.3043465515,
    //     "lower_band": 126.8946534485,
    //     "crossover_long": 0.0,
    //     "crossover_short": 1.0,
    //     "crossover": true
    // },
    // {
    //     "date": 1675728000000,
    //     "open": 150.64,
    //     "high": 155.23,
    //     "low": 150.64,
    //     "close": 154.65,
    //     "upper_band": 155.9477156041,
    //     "lower_band": 127.7012843959,
    //     "crossover_long": 0.0,
    //     "crossover_short": 0.0,
    //     "crossover": false
    // },
    // {
    //     "date": 1675814400000,
    //     "open": 153.88,
    //     "high": 154.58,
    //     "low": 151.168,
    //     "close": 151.92,
    //     "upper_band": 156.6949122074,
    //     "lower_band": 129.0730877926,
    //     "crossover_long": 0.0,
    //     "crossover_short": 0.0,
    //     "crossover": false
    // },
    // {
    //     "date": 1675900800000,
    //     "open": 153.775,
    //     "high": 154.33,
    //     "low": 150.42,
    //     "close": 150.87,
    //     "upper_band": 157.2743329225,
    //     "lower_band": 130.2316670775,
    //     "crossover_long": 0.0,
    //     "crossover_short": 0.0,
    //     "crossover": false
    // },
    // {
    //     "date": 1675987200000,
    //     "open": 149.46,
    //     "high": 151.3401,
    //     "low": 149.22,
    //     "close": 151.01,
    //     "upper_band": 157.6278499029,
    //     "lower_band": 131.6381500971,
    //     "crossover_long": 0.0,
    //     "crossover_short": 0.0,
    //     "crossover": false
    // },
    // {
    //     "date": 1676246400000,
    //     "open": 150.952,
    //     "high": 154.26,
    //     "low": 150.92,
    //     "close": 153.85,
    //     "upper_band": 158.3435665959,
    //     "lower_band": 132.8314334041,
    //     "crossover_long": 0.0,
    //     "crossover_short": 0.0,
    //     "crossover": false
    // },
    // {
    //     "date": 1676332800000,
    //     "open": 152.12,
    //     "high": 153.77,
    //     "low": 150.86,
    //     "close": 153.2,
    //     "upper_band": 158.80822386,
    //     "lower_band": 134.09277614,
    //     "crossover_long": 0.0,
    //     "crossover_short": 0.0,
    //     "crossover": false
    // },
    // {
    //     "date": 1676419200000,
    //     "open": 153.11,
    //     "high": 155.5,
    //     "low": 152.88,
    //     "close": 155.33,
    //     "upper_band": 159.2533017276,
    //     "lower_band": 135.6596982724,
    //     "crossover_long": 0.0,
    //     "crossover_short": 0.0,
    //     "crossover": false
    // },
    // {
    //     "date": 1676505600000,
    //     "open": 153.51,
    //     "high": 156.33,
    //     "low": 153.3475,
    //     "close": 153.71,
    //     "upper_band": 159.0501114528,
    //     "lower_band": 137.7068885472,
    //     "crossover_long": 0.0,
    //     "crossover_short": 0.0,
    //     "crossover": false
    // },
    // {
    //     "date": 1676592000000,
    //     "open": 152.35,
    //     "high": 153.0,
    //     "low": 150.85,
    //     "close": 152.55,
    //     "upper_band": 158.7625173575,
    //     "lower_band": 139.4624826425,
    //     "crossover_long": 0.0,
    //     "crossover_short": 0.0,
    //     "crossover": false
    // },
    // {
    //     "date": 1676937600000,
    //     "open": 150.2,
    //     "high": 151.3,
    //     "low": 148.405,
    //     "close": 148.48,
    //     "upper_band": 158.4169764995,
    //     "lower_band": 140.5450235005,
    //     "crossover_long": 0.0,
    //     "crossover_short": 0.0,
    //     "crossover": false
    // },
    // {
    //     "date": 1677024000000,
    //     "open": 148.87,
    //     "high": 149.95,
    //     "low": 147.16,
    //     "close": 148.91,
    //     "upper_band": 158.1574254409,
    //     "lower_band": 141.4425745591,
    //     "crossover_long": 0.0,
    //     "crossover_short": 0.0,
    //     "crossover": false
    // },
    // {
    //     "date": 1677110400000,
    //     "open": 150.09,
    //     "high": 150.34,
    //     "low": 147.24,
    //     "close": 149.4,
    //     "upper_band": 157.707028154,
    //     "lower_band": 142.646971846,
    //     "crossover_long": 0.0,
    //     "crossover_short": 0.0,
    //     "crossover": false
    // },
    // {
    //     "date": 1677196800000,
    //     "open": 147.11,
    //     "high": 147.19,
    //     "low": 145.7202,
    //     "close": 146.71,
    //     "upper_band": 157.4768668574,
    //     "lower_band": 143.1521331426,
    //     "crossover_long": 0.0,
    //     "crossover_short": 0.0,
    //     "crossover": false
    // },
    // {
    //     "date": 1677456000000,
    //     "open": 147.71,
    //     "high": 149.17,
    //     "low": 147.45,
    //     "close": 147.92,
    //     "upper_band": 157.3826337255,
    //     "lower_band": 143.4453662745,
    //     "crossover_long": 0.0,
    //     "crossover_short": 0.0,
    //     "crossover": false
    // },
    // {
    //     "date": 1677542400000,
    //     "open": 147.05,
    //     "high": 149.08,
    //     "low": 146.83,
    //     "close": 147.41,
    //     "upper_band": 156.8937890171,
    //     "lower_band": 144.3752109829,
    //     "crossover_long": 0.0,
    //     "crossover_short": 0.0,
    //     "crossover": false
    // },
    // {
    //     "date": 1677628800000,
    //     "open": 146.83,
    //     "high": 147.2285,
    //     "low": 145.01,
    //     "close": 145.31,
    //     "upper_band": 156.750796283,
    //     "lower_band": 144.620203717,
    //     "crossover_long": 0.0,
    //     "crossover_short": 0.0,
    //     "crossover": false
    // },
    // {
    //     "date": 1677715200000,
    //     "open": 144.38,
    //     "high": 146.71,
    //     "low": 143.9,
    //     "close": 145.91,
    //     "upper_band": 156.6946933135,
    //     "lower_band": 144.7243066865,
    //     "crossover_long": 0.0,
    //     "crossover_short": 0.0,
    //     "crossover": false
    // },
    // {
    //     "date": 1677801600000,
    //     "open": 148.045,
    //     "high": 151.11,
    //     "low": 147.33,
    //     "close": 151.03,
    //     "upper_band": 156.7066685226,
    //     "lower_band": 144.7333314774,
    //     "crossover_long": 0.0,
    //     "crossover_short": 0.0,
    //     "crossover": false
    // },
    // {
    //     "date": 1678060800000,
    //     "open": 153.785,
    //     "high": 156.3,
    //     "low": 153.46,
    //     "close": 153.83,
    //     "upper_band": 156.5951759092,
    //     "lower_band": 144.7778240908,
    //     "crossover_long": 0.0,
    //     "crossover_short": 0.0,
    //     "crossover": false
    // },
    // {
    //     "date": 1678147200000,
    //     "open": 153.7,
    //     "high": 154.0299,
    //     "low": 151.13,
    //     "close": 151.6,
    //     "upper_band": 156.5843543254,
    //     "lower_band": 144.7756456746,
    //     "crossover_long": 0.0,
    //     "crossover_short": 0.0,
    //     "crossover": false
    // },
    // {
    //     "date": 1678233600000,
    //     "open": 152.81,
    //     "high": 153.47,
    //     "low": 151.83,
    //     "close": 152.87,
    //     "upper_band": 156.303858829,
    //     "lower_band": 144.878141171,
    //     "crossover_long": 0.0,
    //     "crossover_short": 0.0,
    //     "crossover": false
    // },
    // {
    //     "date": 1678320000000,
    //     "open": 153.559,
    //     "high": 154.535,
    //     "low": 150.225,
    //     "close": 150.59,
    //     "upper_band": 156.2048009603,
    //     "lower_band": 144.8441990397,
    //     "crossover_long": 0.0,
    //     "crossover_short": 0.0,
    //     "crossover": false
    // },
    // {
    //     "date": 1678406400000,
    //     "open": 150.21,
    //     "high": 150.94,
    //     "low": 147.6096,
    //     "close": 148.5,
    //     "upper_band": 156.151040992,
    //     "lower_band": 144.660959008,
    //     "crossover_long": 0.0,
    //     "crossover_short": 0.0,
    //     "crossover": false
    // },
    // {
    //     "date": 1678665600000,
    //     "open": 147.805,
    //     "high": 153.14,
    //     "low": 147.7,
    //     "close": 150.47,
    //     "upper_band": 156.1175046833,
    //     "lower_band": 144.6404953167,
    //     "crossover_long": 0.0,
    //     "crossover_short": 0.0,
    //     "crossover": false
    // },
    // {
    //     "date": 1678752000000,
    //     "open": 151.28,
    //     "high": 153.4,
    //     "low": 150.1,
    //     "close": 152.59,
    //     "upper_band": 155.9269443056,
    //     "lower_band": 144.7050556944,
    //     "crossover_long": 0.0,
    //     "crossover_short": 0.0,
    //     "crossover": false
    // },
    // {
    //     "date": 1678838400000,
    //     "open": 151.19,
    //     "high": 153.245,
    //     "low": 149.92,
    //     "close": 152.99,
    //     "upper_band": 155.8955643109,
    //     "lower_band": 144.7154356891,
    //     "crossover_long": 0.0,
    //     "crossover_short": 0.0,
    //     "crossover": false
    // },
    {
        "date": 1678924800000,
        "open": 152.16,
        "high": 156.46,
        "low": 151.64,
        "close": 155.85,
        "upper_band": 156.0187920621,
        "lower_band": 144.6442079379,
        "crossover_long": 0.0,
        "crossover_short": 0.0,
        "crossover": false
    },
    {
        "date": 1679011200000,
        "open": 156.08,
        "high": 156.74,
        "low": 154.28,
        "close": 155.0,
        "upper_band": 156.2615584559,
        "lower_band": 144.5304415441,
        "crossover_long": 0.0,
        "crossover_short": 0.0,
        "crossover": false
    },
    {
        "date": 1679270400000,
        "open": 155.07,
        "high": 157.82,
        "low": 154.15,
        "close": 157.4,
        "upper_band": 157.1999640897,
        "lower_band": 144.0770359103,
        "crossover_long": 0.0,
        "crossover_short": 0.0,
        "crossover": false
    },
    {
        "date": 1679356800000,
        "open": 157.32,
        "high": 159.4,
        "low": 156.54,
        "close": 159.28,
        "upper_band": 158.6544408104,
        "lower_band": 143.7025591896,
        "crossover_long": 0.0,
        "crossover_short": 0.0,
        "crossover": false
    },
    {
        "date": 1679443200000,
        "open": 159.3,
        "high": 162.14,
        "low": 157.81,
        "close": 157.83,
        "upper_band": 159.5562904032,
        "lower_band": 143.6927095968,
        "crossover_long": 0.0,
        "crossover_short": 1.0,
        "crossover": true
    },
    {
        "date": 1679529600000,
        "open": 158.83,
        "high": 161.5501,
        "low": 157.68,
        "close": 158.93,
        "upper_band": 160.5679638006,
        "lower_band": 143.6340361994,
        "crossover_long": 0.0,
        "crossover_short": 0.0,
        "crossover": false
    },
    {
        "date": 1679616000000,
        "open": 158.86,
        "high": 160.34,
        "low": 157.85,
        "close": 160.25,
        "upper_band": 161.571453474,
        "lower_band": 143.984546526,
        "crossover_long": 0.0,
        "crossover_short": 0.0,
        "crossover": false
    },
    {
        "date": 1679875200000,
        "open": 159.94,
        "high": 160.77,
        "low": 157.87,
        "close": 158.28,
        "upper_band": 162.1042856448,
        "lower_band": 144.4877143552,
        "crossover_long": 0.0,
        "crossover_short": 0.0,
        "crossover": false
    }
]