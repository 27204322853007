/**
Wellisco Fin Panel V3
*/
import { useWorkbook } from "wellisco/workbooks";
import { useLocation } from "react-router-dom";

import WlWorkbookPanel from "wellisco/workbooks/components/WlWorkbookPanel";

import "wellisco/workbooks/workbooks.css"
import "./fin_workbooks.css"
import WelliscoLayoutFixed from "wellisco/components/LayoutContainers/WelliscoLayout/WelliscoLayoutFixed";
import DashboardNavbar from "wellisco/components/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";

import ProductsView from "./ProductsView";
import PersonasView from "./PersonasView";
import PersonaSelectorView from "./PersonaSelectorView";
import RecommendationsView from "./RecommandationsView";
import WellnessProfileView from "./WellnessProfileView";
import CodeView from "./CodeView";

import logo from "assets/images/wellisco-logo.png";

export default function FinPanelV3(props) {
    // const apiUrl = "http://ec2-51-17-113-144.il-central-1.compute.amazonaws.com:8000/api/finwb"
    const apiUrl = "/api/finwb"
    // const apiUrl = "http://localhost:8000/api/finwb"
    const { search } = useLocation();
    const searchParmas = new URLSearchParams(search)
    const customerId = searchParmas.get("cid") || "123"
    // console.log("serarch params", searchParmas)


    const viewComps = {
        // "tax_PDFs_section": TaxPDFsSection,
        // "tax_year_section": TaxYearSection,
        "products": ProductsView,
        "personas": PersonasView,
        "persona_selector": PersonaSelectorView,
        "recommendations": RecommendationsView,
        "table_code": CodeView,
        "wellness_profile": WellnessProfileView,
    }

    const workbook = useWorkbook({apiUrl,viewComps})

    const brand = <MDBox className="wl_side_brand">
        <img src={logo} alt="logo" />
    </MDBox>

    return <MDBox sx={{
        display: 'flex',
        alignItems: "center", justifyContent: "center",
        flexDirection: "column",
        flexGrow: 1,
        borderRadius: 4,
        overflow: "hidden",
        paddingRight: "16px",
        paddingLeft: "16px",
        backgroundColor: "#222",
    }}
    // bgColor="white"
    >
        <WlWorkbookPanel workbook={workbook} brand={brand} title="" viewComps={viewComps} />
    </MDBox>
}

