/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import { Skeleton } from "@mui/material";

export default function SkeletonCard({ image, label, title, description, action, authors, lang }) {
  const direction = (lang == "he") ? "rtl" : "ltr"
  return (
    <Card
      height={240}
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "transparent",
        boxShadow: "none",
        overflow: "visible",
        maxWidth: 360,
        width: "100%",
        height: "240px",
        direction: direction,
      }}
    >
      <MDBox position="relative" width="100.25%">
        <Skeleton variant="rounded"
        animation="wave" 
        height={120}
        width="100%"
        radius={20}
        // borderRadius={20}
        sx={{ borderRadius: 4 }}
        />
      </MDBox>
      <MDBox mt={1} mx={0.5}>
        
        <Skeleton variant="text" 
            height={40}
            width="100%"
            animation="wave" 
            sx={{ fontSize: '1rem' }}  />
          <Skeleton variant="text" 
            height={40}
            width="100%"
            animation="wave" 
            sx={{ fontSize: '1rem' }}  />
      </MDBox>
    </Card>
  );
}
