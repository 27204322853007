import { useState } from 'react';
import Plot from 'react-plotly.js';
// import Plotly from "plotly.js"

const data = [{
  type: "sunburst",
  labels: ["Eve", "Cain", "Seth", "Enos", "Noam", "Abel", "Awan", "Enoch", "Azura"],
  labels: ["Eve", "Cain", "Seth", "Enos", "Noam", "Abel", "Awan", "Enoch", "Azura"],
  parents: ["", "Eve", "Eve", "Seth", "Seth", "Eve", "Eve", "Awan", "Eve" ],
  values:  [10, 14, 12, 10, 2, 6, 6, 4, 4],
  // values:  [0, 0, 0, 0, 0, 0, 0, 0, 0],
  // outsidetextfont: {size: 20, color: "#377eb8"},
  // leaf: {opacity: 0.4},
  // marker: {line: {width: 2}},
  // rotation: 180
}]

const f1 = {
  name: "f1",
  data: [{
    values:  [10, 14, 12, 10, 2, 6, 6, 4, 4],
  }]
}

// function zoom(figure) {
//   var min = 0.45 * Math.random();
//   var max = 0.55 + 0.45 * Math.random();
//   figure.animate({
//     layout: {
//       xaxis: {range: [min, max]},
//       yaxis: {range: [min, max]}
//     }
//   }, {
//     transition: {
//       duration: 500,
//       easing: 'cubic-in-out'
//     }
//   })
// }

export default function BlockPlotly(props) {
  const {handleClick} = props
  const [figure,setFigure] = useState()
  return (
    <Plot
      data={data}
      layout={{ width: 320, height: 240, margin: { t:0,b:0,r:0,l:0 }  }}
      frames={[f1]}
      // onClick={() => zoom(figure)}
      onClick={() => handleClick?.()}
      onInitialized={fig => setFigure(fig)}
      // onUpdate={fig => setFigure(fig)}
    />
  );
}

