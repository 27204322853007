import { useEffect, useState } from 'react';
import { useResolvedPath } from 'react-router-dom';
import { supabase } from 'components/Supabase/supabaseClient';

const apiUrl = 'http://localhost:8000/'; // change this to your backend's url

const ApiProxy = function (props) {
    const path = useResolvedPath()
    const apiPath = path?.pathname.replace("my-api", "api"); // remove '/my-api' from the path
    const [response, setResponse] = useState([]);

    useEffect(async () => {
        const { data: { user } } = await supabase.auth.getUser()
        console.log(user)
        const { data: { session }, error } = await supabase.auth.getSession()
        const accessToken = session?.access_token
        console.log(accessToken)
        const res = await fetch(apiPath, {
            headers: {
                Authorization: accessToken,
            },
        })
        const txt = await res.text()
        setResponse(txt)
    }, [])

    return `Yo yo love ${apiPath} ${response}`
}

export default ApiProxy;