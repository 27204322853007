/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import YTPlayer from "yt-player"
import { useRef, useEffect } from "react";
import _ from "lodash"

function VideoCard({ image, label, title, description, videoId, lang }) {
    const direction = (lang == "he") ? "rtl" : "ltr"

    const youtubeThumb = useRef(null);
    const youtubePlayerDiv = useRef(null);
    const youtubePlayer = useRef(null);
    videoId = _.replace(videoId, "https://youtu.be/", "")
    const videoThumbUrl = image // "//img.youtube.com/vi/" + videoId + "/hqdefault.jpg"
    let ytPlayer
    // useEffect(() => {

    //     if (youtubePlayerDiv.current && youtubePlayer.current && !ytPlayer) {
    //         ytPlayer = new YTPlayer(youtubePlayer.current, {
    //             autoplay: false,
    //             showinfo: false,
    //             modestBranding: true,
    //             annotations: false,
    //             captions: false,
    //             controls: false,
    //             // playsInline: true,
    //         })
    //         // ytPlayer.load(data.videoId,true)
    //         ytPlayer.load(videoId, false)
    //         // ytPlayer.stop()
    //         console.log("Loading video")
    //     }
    // });

    // const VideoBlock = () =>

    return (
        <Card
            sx={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "transparent",
                boxShadow: "none",
                overflow: "visible",
                maxWidth: 360,
                width: "100%",
                direction: direction,
            }}
        >
            <MDBox position="relative" width="100.25%" shadow="xl" borderRadius="xl">
                {/* <CardMedia
                    src={image}
                    component="img"
                    title={title}
                    sx={{
                        maxWidth: "100%",
                        width: "100%",
                        maxHeight: 180,
                        margin: 0,
                        boxShadow: ({ boxShadows: { md } }) => md,
                        objectFit: "cover",
                        objectPosition: "center",
                    }}
                /> */}
                <>
                    <img src="/images/youtube-play-button.svg"
                        // className={classes.videoPlayButton}
                        style={{
                            width: 64,
                            position: "absolute",
                            left: 20,
                            bottom: 20,
                            cursor: "pointer",
                        }}
                        // onClick={e => {
                        //     e.stopPropagation()
                        //     e.preventDefault()
                        //     youtubePlayerDiv.current.style.display = "block"
                        //     youtubeThumb.current.style.display = "none"
                        //     // ytPlayer.seek(1,true)
                        //     ytPlayer.play()
                        //     // openFullscreen(ytPlayer)
                        // }} 
                        />

                    <CardMedia
                        sx={{
                            maxWidth: "100%",
                            width: "100%",
                            maxHeight: 180,
                            margin: 0,
                            boxShadow: ({ boxShadows: { md } }) => md,
                            objectFit: "cover",
                            objectPosition: "center",
                        }}
                        component="img"

                        // className={classes.videoThumb}
                        image={videoThumbUrl}
                        // src={"//www.youtube.com/embed/" + data.videoId + "/"}
                        ref={youtubeThumb}
                    >
                        {/* {helper.getActionButton("playVideo", handler =>
                    <img src="/images/youtube-play-button.svg" class={classes.videoPlayButton} onClick={handler} />
                )} */}

                    </CardMedia>
                    <div style={{ display: "none", left: 0, width: "100%" }}
                        ref={youtubePlayerDiv}
                    // id={"yt_" + data.id}
                    >
                        <div ref={youtubePlayer} id={"yt_player_" + videoId} style={{ width: "100%" }} />
                        {/* <iframe src={`https://www.youtube.com/embed/${data.videoId}?rel=0`}
                    ref={youtubePlayer}
                    style={{ border: 0, top: 0, left: 0, width: "100%", height: "100%", position: "absolute" }}
                    allowFullScreen scrolling="no" allow="encrypted-media, accelerometer, gyroscope, picture-in-picture">
                </iframe> */}
                        {/* <div>
                    <Button variant="contained" size="small" color="primary" className={classes.button}
                    >
                        סגור סרט
                        </Button>
                </div> */}
                    </div>
                </>

            </MDBox>
            <MDBox mt={1} mx={0.5}>
                {/* <MDTypography variant="button" fontWeight="regular" color="text" textTransform="capitalize">
          {label}
        </MDTypography> */}
                {/* <MDBox mb={1}>
          {action.type === "internal" ? (
            <MDTypography
              component={Link}
              to={action.route}
              variant="h5"
              textTransform="capitalize"
            >
              {title}
            </MDTypography>
          ) : (
            <MDTypography
              component="a"
              href={action.route}
              target="_blank"
              rel="noreferrer"
              variant="h5"
              textTransform="capitalize"
            >
              {title}
            </MDTypography>
          )}
        </MDBox> */}
                <MDBox m={1} lineHeight={0} sx={{
                    height: "3em",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    display: "-webkit-box",
                    'webkitBoxOrient': "vertical",
                    "webkitLineClamp": "3", /* start showing ellipsis when 3rd line is reached */
                    "whiteSpace": "pre-wrap", /* let the text wrap preserving spaces */
                    overflow: "hidden",
                }}>
                    <MDTypography variant="button" fontWeight="light" color="text">
                        {description}
                    </MDTypography>
                </MDBox>
                {/* <MDBox display="flex" justifyContent="space-between" alignItems="center">
          {action.type === "internal" ? (
            <MDButton
              component={Link}
              to={action.route}
              variant="outlined"
              size="small"
              color={action.color}
            >
              {action.label}
            </MDButton>
          ) : (
            <MDButton
              component="a"
              href={action.route}
              target="_blank"
              rel="noreferrer"
              variant="outlined"
              size="small"
              color={action.color}
            >
              {action.label}
            </MDButton>
          )}
          <MDBox display="flex">{renderAuthors}</MDBox>
        </MDBox> */}
            </MDBox>
        </Card>
    );
}

// Setting default values for the props of DefaultProjectCard
VideoCard.defaultProps = {
    authors: [],
};

// Typechecking props for the DefaultProjectCard
VideoCard.propTypes = {
    image: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    action: PropTypes.shape({
        type: PropTypes.oneOf(["external", "internal"]),
        route: PropTypes.string.isRequired,
        color: PropTypes.oneOf([
            "primary",
            "secondary",
            "info",
            "success",
            "warning",
            "error",
            "light",
            "dark",
            "white",
        ]).isRequired,
        label: PropTypes.string.isRequired,
    }).isRequired,
    authors: PropTypes.arrayOf(PropTypes.object),
};

export default VideoCard;
