
import { ColorPickerOutlineDimensions } from "@emotion-icons/evaicons-outline/ColorPickerOutline"
import MDBox from "components/MDBox"
import { Card, CardMedia, CardContent, Typography } from "@mui/material";
import _ from "lodash"
import "./ProductsView.css"
import SectionFrame from "wellisco/workbooks/sections/SectionFrame"
import { split_to_records } from "wellisco/workbooks/utils/tableUtils";

export default function PersonaSelectorView({ workbook, section, path, goTo }) {

    const table = section?.selected_data || section?.data
    const personas = split_to_records(table)
    // const personas = _.map(records, row => ({
    //     id: row["id"],
    //     name: row["name"],
    //     summary: row["summary"],
    // }))

    function onPersonaSelection(persona) {
        // console.log("product clicked", product)
        // path = section._entity
        // goTo(path, { row: product.id })
        console.log("persona", persona.id)
        workbook.updateSelection({ persona: persona.id },true)
        workbook.setIsPersonaSelectorOpen(false)
    }

    return <SectionFrame className="wl_white_section wl_personas_view">
        {/* <table>
            {_.map(personas, persona => <tr
                // onClick={e => onProductSelect(prod)}
            >
                <td>
                    <img src={`https://app.wellisco.com/images/${persona.image}`} ></img>
                </td>
                <td style={{paddingLeft: 10}}>{persona.name}
                    <div style={{ fontSize: "small" }}>{persona.summary}</div>
                </td>
            </tr>)}
        </table> */}

        <MDBox className="wl_persona_selector">
            {/* <MDBox className="wl_selection_title">
                Discover Your Wellness Profile
            </MDBox>
            <MDBox className="wl_selection_prompt">
                Which profile best describes your wellness aspirations?
            </MDBox>
            <MDBox className="wl_selection_explain">
                Select a profile that aligns with your goals and feel free to adjust as your journey evolves.
            </MDBox> */}
            <MDBox className="wl_persona_cards">
                {_.map(personas, persona =>
                    <MDBox className="wl_card_wrapper">
                        <Card
                            className="wl_card"
                            // sx={{ width: 260, margin: "5px" }}
                            onClick={e => onPersonaSelection(persona)}
                        >
                            <CardMedia
                                media="picture"
                                // alt="Contemplative Reptile"
                                image={`https://app.wellisco.com/images/${persona.image}`}
                                // title="Contemplative Reptile"
                                sx={{
                                    // position: "absolute",
                                    // top: 0,
                                    // right: 0,
                                    margin: 0,
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    height: 180,
                                    width: 180,
                                    // margin: 0,
                                    // filter: "grayscale(80%) blur(5px)",
                                    // filter: "sepia(100%)",
                                }}
                            />
                            <CardContent sx={{ textAlign: "center" }}>
                                <Typography className="wl_title">
                                    {persona.name}
                                </Typography>
                                <Typography fontSize="small">
                                    {persona.summary}
                                </Typography>
                            </CardContent>
                        </Card>
                    </MDBox>
                )}
            </MDBox>
        </MDBox>
    </SectionFrame>
}
